import { useCallback, useContext } from "react";
import {
  TextInput,
  Button,
  Select,
  Group,
  NumberInput,
  Text,
  Checkbox,
  MultiSelect,
} from "@mantine/core";
import { useForm } from "@mantine/hooks";
import UserContext from "../../utils/userContext";
import { RichTextEditor } from "@mantine/rte";
import withItemLoader from "../withItemLoader";
import { roleOptions } from "../../utils/userTools";

interface FormProps {
  item: any;
  onSave: (item: any) => void;
  onDelete: (item: any) => void;
  onClose: (item: any) => void;
}

const rewards = [
  "Xp",
  "Money",
  "Item",
  "Stat",
  "Other",
  "Nothing",
  "Unknown",
];

function Form({ item, onSave, onDelete, onClose }: FormProps) {
  const userState = useContext(UserContext);
  const role = userState.user?.role || 0;

  const form = useForm({
    initialValues: {
      name: item.name || "",
      solution: item.solution || "",
      level: item.level,
      location: item.location || "",
      role: item.role?.toString() || Math.min(role || 2, 2).toString(),
      active: item.active === undefined ? true : item.active,
      reward: item.reward || [],
    },
  });

  const onDeleteClick = useCallback(() => {
    if (window.confirm("Are you sure you want to delete this item?")) {
      onDelete(item);
    }
  }, [item, onDelete]);

  return (
    <>
      <div>
        <TextInput
          required
          label="Name"
          placeholder="Quest name"
          {...form.getInputProps("name")}
        />

        <TextInput
          mt="lg"
          required
          label="Location"
          placeholder="Location of the quest, eg Madagor"
          {...form.getInputProps("location")}
        />

        <Group grow mt="lg">
          <Checkbox
            mt="lg"
            label="Active"
            {...form.getInputProps("active", { type: "checkbox" })}
          />
          <NumberInput label="Minimum Level" {...form.getInputProps("level")} />
          <Select
            required
            label="Permission"
            placeholder="Select a role"
            {...form.getInputProps("role")}
            data={roleOptions.slice(0, role + 1)}
          />
        </Group>

        <MultiSelect
          mt="lg"
          data={rewards}
          label="Reward(s)"
          placeholder="Pick one or more rewards"
          {...form.getInputProps("reward")}
        />

        <Text mt="lg" size="sm" mb="xs">
          Solution
        </Text>
        <RichTextEditor
          styles={{
            root: {
              p: {
                marginBottom: 4,
              },
            },
          }}
          controls={[
            ["bold", "italic", "underline"],
            ["unorderedList", "orderedList"],
          ]}
          value={form.values["solution"]}
          onChange={(e) => form.setFieldValue("solution", e)}
        />

        <Group mt="xl">
          <Button onClick={form.onSubmit(onSave)}>Save</Button>
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          {item._id && (
            <Button
              variant="outline"
              ml="xl"
              color="red"
              onClick={onDeleteClick}
            >
              Delete
            </Button>
          )}
        </Group>
      </div>
    </>
  );
}

export default withItemLoader(Form);
