import { TextInput, Button, Group, Select, PasswordInput, Checkbox } from "@mantine/core";
import { useForm } from "@mantine/hooks";
import { useContext } from "react";
import UserContext from "../../utils/userContext";

import { roleOptions } from "../../utils/userTools";

interface FormProps {
  item: any;
  onSave: (item: any) => void;
  onDelete: (item: any) => void;
}

function Form({ item, onSave, onDelete }: FormProps) {
  const userState = useContext(UserContext);

  const form = useForm({
    initialValues: {
      firstName: item.firstName || "",
      lastName: item.lastName || "",
      playerName: item.playerName || "",
      password: item.password || "",
      role: item.role?.toString() || "",
      active: item.active === undefined ? true : item.active,
    },
  });

  return (
    <form onSubmit={form.onSubmit(onSave)}>

      <Group grow>
        <Checkbox
          mt="xl"
          label="Active"
          {...form.getInputProps("active", { type: "checkbox" })}
        />
      </Group>
      
      <Group grow mt="lg">
        <TextInput
          required
          label="Player Name"
          {...form.getInputProps("playerName")}
        />

        <PasswordInput
          required={!item._id}
          label="Password"
          {...form.getInputProps("password")}
        />
      </Group>

      <Group grow mt="lg">
        <TextInput
          required
          label="First Name"
          {...form.getInputProps("firstName")}
        />
        <TextInput
          required
          label="Last Name"
          {...form.getInputProps("lastName")}
        />
      </Group>

      <Select
        required
        mt="lg"
        label="Role"
        placeholder="Select a role"
        {...form.getInputProps("role")}
        data={roleOptions.slice(0, userState.user.role + 1)}
      />

      <Group mt="xl">
        <Button type="submit">{item._id ? "Update" : "Save"}</Button>
        {item._id && (
          <Button variant="outline" color="red" onClick={onDelete}>
            Delete
          </Button>
        )}
      </Group>
    </form>
  );
}

export default Form;
