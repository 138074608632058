import { useCallback, useContext } from "react";
import {
  TextInput,
  Button,
  Select,
  Group,
  Grid,
  Text,
  Code,
  Textarea,
} from "@mantine/core";
import { useForm } from "@mantine/form";

import UserContext from "../../utils/userContext";
import { getRoleOptions } from "../../utils/userTools";

interface FormProps {
  item: any;
  steps: string[];
  onSave: (item: any) => void;
  onDelete: (item: any) => void;
  onClose: (item: any) => void;
}

function Form({ item, steps, onSave, onDelete, onClose }: FormProps) {
  const userState = useContext(UserContext);
  const role = userState.user?.role || 0;

  const form = useForm({
    initialValues: {
      name: item?.name || "",
      steps: steps || "",
      description: item?.description || "",
      role: item?.role?.toString() || "10",
    },
    validate: (values) => ({
      name: !values.name ? "Please enter a name" : null,
      steps: !values.steps ? "Please enter steps" : null,
      role: !values.role && values.role !== 0 ? "Please select a role" : null,
    }),
  });

  const onSubmit = useCallback(
    (values: typeof form["values"]) => {
      onSave(values);
    },
    [onSave]
  );

  const onDeleteClick = useCallback(() => {
    if (window.confirm("Are you sure you want to delete this item?")) {
      onDelete(item);
    }
  }, [item, onDelete]);

  return (
    <>
      <form onSubmit={form.onSubmit(onSubmit)}>
        <Grid mt="lg">
          <Grid.Col span={12} xs={8}>
            <TextInput
              required
              label="Name"
              placeholder="Saved plan name"
              {...form.getInputProps("name")}
            />
          </Grid.Col>
          <Grid.Col span={6} xs={4}>
            <Select
              required
              label="Permission"
              placeholder="Select a role"
              {...form.getInputProps("role")}
              data={getRoleOptions(role, true)}
            />
          </Grid.Col>
        </Grid>

        <Textarea
          mt="lg"
          label="Description"
          maxLength={100}
          placeholder="Short information about the guide"
          {...form.getInputProps("description")}
        />

        <Text mt="xl" size="sm">
          Steps:
        </Text>
        {steps.map((s: string, i: number) => (
          <div key={i}>
            <Code>{s}</Code>
          </div>
        ))}

        <Group mt="xl">
          <Button type="submit">Save</Button>
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          {item?._id && (
            <Button
              variant="outline"
              ml="xl"
              color="red"
              onClick={onDeleteClick}
            >
              Delete
            </Button>
          )}
        </Group>
      </form>
    </>
  );
}

export default Form;
