import { useCallback, useEffect, useState } from "react";
import {
  Title,
  Card,
  Group,
  Center,
  Avatar,
  Alert,
  Loader,
  Box,
  Text,
  Button,
  TextInput,
} from "@mantine/core";

import useAxios from "axios-hooks";
import { ExclamationTriangleIcon } from "@modulz/radix-icons";
import { ReactComponent as BotLogo } from "../assets/Bot.svg";
import { useForm } from "@mantine/hooks";



const mapGrid = {
  position: "relative" as "relative",
};

const mapCell = {
  backgroundColor: "#1971c2",
  width: 10,
  height: 10,
  position: "absolute" as "absolute",
};


function Mapper() {
  const [data, setData] = useState<any>({});
  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  const rows = [];
  for (let y = 0; y < 50; y++) {
    const cols = [];
    for (let x = 0; x < 100; x++) {
      cols.push(<div key={x} style={{...mapCell, top: y * 11, left: x * 11}} />);
    }
    rows.push(cols);
  }

  return (
    <>
      <Group position="apart">
        <Group>
          <Avatar color="blue" size="lg" radius="xl">
            <BotLogo width={32} height={32} />
          </Avatar>
          <Title order={1}>Mapper</Title>
        </Group>
        <Group>
          <Button variant="outline" color="red">
            Reset
          </Button>
          <Button>Reload</Button>
        </Group>
      </Group>

      {error && (
        <Alert
          mt="lg"
          icon={<ExclamationTriangleIcon />}
          title="Bummer!"
          color="red"
        >
          {error}
        </Alert>
      )}

      {loading && (
        <Card withBorder mt="lg">
          <Center>
            <Loader />
          </Center>
        </Card>
      )}
      {data && (
        <div style={mapGrid}>
          {rows.map((rows, i) => (
            <div key={i}>{rows}</div>
          ))}
        </div>
      )}
    </>
  );
}

export default Mapper;
