import {
  Title,
  Group,
  Avatar,
  Card,
  Alert,
  TextInput,
  Center,
  Loader,
  Modal,
  Button,
  Text,
  Code,
  SimpleGrid,
  Box,
  Anchor,
} from "@mantine/core";
import { useForm } from "@mantine/hooks";
import { useNotifications } from "@mantine/notifications";

import { cloneDeep } from "lodash";

import { ExclamationTriangleIcon } from "@modulz/radix-icons";
import useAxios from "axios-hooks";
import { useCallback, useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ReactComponent as Logo } from "../assets/Planner.svg";
import Form from "../components/Planner/Form";
import GuideForm from "../components/Guides/Form";
import UserContext from "../utils/userContext";

function parseSkills(
  result: any[],
  skill: any,
  level: number,
  onButtonCommand: any
) {
  let row = `${"| ".repeat(level)}${skill.name}`;
  row = `${row}${".".repeat(21 - row.length)}`;
  const value = `${skill.value}/${skill.max}`;
  const fillers = " ".repeat(10 - value.length);

  result.push(
    <div key={skill.key}>
      <span>{row}</span>
      &nbsp;&nbsp;
      <span>{value}</span>
      {skill.value < skill.max && (
        <>
          {fillers}
          <span
            className="link-btn"
            onClick={() => onButtonCommand(`adv ${skill.key} by 5`)}
          >
            +5
          </span>
          &nbsp;&nbsp;
          <span
            className="link-btn"
            onClick={() => onButtonCommand(`adv ${skill.key} to ${skill.max}`)}
          >
            max
          </span>
        </>
      )}
    </div>
  );

  if (skill.subskills) {
    for (const subskill of skill.subskills) {
      parseSkills(result, subskill, level + 1, onButtonCommand);
    }
  }
}

function renderSkills(skills: any, onButtonCommand: any) {
  try {
    let level = 0;
    const result: any[] = [];
    for (const skill of skills) {
      parseSkills(result, skill, level, onButtonCommand);
    }
    return result;
  } catch (er) {
    return (
      <Alert
        mt="lg"
        icon={<ExclamationTriangleIcon />}
        title="Bummer!"
        color="red"
      >
        Failed to render skills. Please check the format of the skill tree.
      </Alert>
    );
  }
}

function findSkillByKeys(iskills: any[], keys: string[]): any {
  // get first key
  const key = keys.shift();
  const skill = iskills.find((s) => s.name.indexOf(key) === 0);
  if (keys.length === 0 && skill) {
    // bottom level
    return skill;
  } else if (skill && keys.length > 0 && skill.subskills?.length > 0) {
    return findSkillByKeys(skill.subskills, keys);
  }
  return null;
}

function findSkill(skills: any[], skillKey: string): any {
  const skillKeys = skillKey.split(".");
  return findSkillByKeys(skills, skillKeys);
}

function initSkill(skill: any) {
  if (skill.parent) {
    if (!skill.max) skill.max = skill.parent.max;
    skill.key = `${skill.parent.key}.${skill.name.substring(0, 2)}`;
  } else {
    skill.key = skill.name.substring(0, 2);
  }
  if (skill.subskills) {
    skill.subskills = skill.subskills.map((subskill: any) => {
      const newSubskill = {
        ...subskill,
      };
      newSubskill.parent = skill;
      initSkill(newSubskill);
      return newSubskill;
    });
  }
}

function initSkills(skills: any) {
  const result = [];
  for (const skill of skills) {
    const newSkill = {
      ...skill,
    };
    newSkill.parent = null;
    initSkill(newSkill);
    result.push(newSkill);
  }
  return result;
}

function increaseSubSkill(skill: any, amount: number) {
  skill.value += amount;
  if (skill.value < 0) {
    skill.value = 0;
  }
  if (skill.subskills) {
    for (const subskill of skill.subskills) {
      increaseSubSkill(subskill, amount);
    }
  }
}

function increaseParentSkill(skill: any) {
  let childTotal = 0;
  for (const subskill of skill.subskills) {
    childTotal += subskill.value;
  }
  skill.value = Math.ceil(childTotal / skill.subskills.length);
  if (skill.parent) {
    increaseParentSkill(skill.parent);
  }
}

function convertCommandIntoAdvance(cmds: string[], skills: any): string {
  const skillKey = (cmds[1] || "").replace(/\.$/, '');
  const operator = cmds[2];
  const value = parseInt(cmds[3]);

  if (operator !== "to" && operator !== "by")
    throw new Error("usage: adv <skill> by/to <amount>");

  const skill = findSkill(skills, skillKey);
  if (skill === null) throw new Error("Skill not found");

  const before = skill.value;
  let after = operator === "to" ? value : before + value;
  if (after > skill.max) {
    after = skill.max;
  } else if (after < 0) {
    after = 0;
  }
  const diff = after - before;
  if (diff >= 0) return `adv ${skill.key} to ${after}`;
  else return `adv ${skill.key} by ${diff}`;
}

// skills: must be copy already
function applyAdvance(skills: any, advance: string) {
  const cmds = advance.split(" ");
  const skillKey = cmds[1];
  const operator = cmds[2];
  const value = parseInt(cmds[3]);

  if (operator !== "to" && operator !== "by")
    throw new Error("usage: adv <skill> by/to <amount>");

  const skill = findSkill(skills, skillKey);
  if (skill === null) throw new Error("Skill not found");

  const before = skill.value;

  let after = operator === "to" ? value : before + value;
  if (after > skill.max) {
    after = skill.max;
  } else if (after < 0) {
    after = 0;
  }
  skill.value = after;

  const diff = after - before;

  if (skill.subskills) {
    for (const subskill of skill.subskills) {
      increaseSubSkill(subskill, diff);
    }
  }
  if (skill.parent) {
    increaseParentSkill(skill.parent);
  }
}

const monoStyle = {
  root: {
    fontFamily: "monospace",
  },
};

function AdvPlannerPage() {
  const params = useParams();
  const navigate = useNavigate();
  const userState = useContext(UserContext);
  const [error, setError] = useState(null);
  const [last, setLast] = useState(null);
  const [item, setItem] = useState<any>(null);
  const [guide, setGuide] = useState<any>(null);
  const [guides, setGuides] = useState<any[]>([]);
  const [initialSkills, setInitialSkills] = useState<any>(null);
  const [skills, setSkills] = useState<any>(null);
  const [editMode, setEditMode] = useState<number>(0);
  const [loading, setLoading] = useState(true);
  const [steps, setSteps] = useState<string[]>([]);
  const role = userState.user?.role || 0;
  const id = (params.id as string) || "none";
  const guideId = (params.guideId as string) || "";
  const notifications = useNotifications();

  const form = useForm({
    initialValues: {
      cmd: "",
    },
  });

  //
  // Axios

  const [, searchGuides] = useAxios(
    {
      url: "/api/public/guides/search",
      method: "POST",
    },
    {
      manual: true,
    }
  );

  const [, getItem] = useAxios(
    {
      method: "GET",
    },
    {
      manual: true,
    }
  );

  const [, postItem] = useAxios(
    {
      url: "/api/guest/plans",
      method: "POST",
    },
    {
      manual: true,
    }
  );

  const [, postGuide] = useAxios(
    {
      url: "/api/guest/guides",
      method: "POST",
    },
    {
      manual: true,
    }
  );

  const [, deleteItem] = useAxios(
    {
      method: "DELETE",
    },
    {
      manual: true,
    }
  );

  const onButtonCommand = useCallback(
    (cmd) => {
      setError(null);
      const cmds = cmd.split(" ");
      const advance = convertCommandIntoAdvance(cmds, skills);
      // Add to steps
      const newSteps = [...steps, advance];
      setSteps(newSteps);
    },
    [steps, skills]
  );

  const onUndoClick = useCallback(() => {
    const newSteps = [...steps];
    newSteps.splice(-1);
    setSteps(newSteps);
  }, [steps]);

  const onRefreshClick = useCallback(() => {
    setSkills(initialSkills);
    if (guide) {
      setSteps([...guide.steps]);
      notifications.showNotification({
        title: "Reset",
        message: "Skills have been reset to the saved plan.",
      });
    } else {
      setSteps([]);
    }
  }, [initialSkills, guide, notifications]);

  const parseCommand = useCallback(
    (values) => {
      try {
        setError(null);
        setLast(values.cmd);
        const cmds = values.cmd.split(" ");
        const cmd = (cmds[0] || "").toLowerCase();

        if (cmd === "refresh") {
          form.setFieldValue("cmd", "");
          onRefreshClick();
        } else if (cmd === "adv") {
          // Validate and format command
          const advance = convertCommandIntoAdvance(cmds, skills);

          // Command is ok if we get here
          form.setFieldValue("cmd", "");

          // Add to steps
          const newSteps = [...steps, advance];
          setSteps(newSteps);
        } else if (cmd === "undo") {
          form.setFieldValue("cmd", "");
          onUndoClick();
        } else {
          throw new Error("Invalid command: " + cmd);
        }
      } catch (err: any) {
        setError(err.message);
      }
    },
    [form, skills, steps, onUndoClick, onRefreshClick]
  );

  const onKeyDown = useCallback(
    (e) => {
      if (e.code === "ArrowUp") {
        if (last) {
          form.setFieldValue("cmd", last);
        }
      }
    },
    [last, form]
  );

  const doGetItem = useCallback(
    async (id: string, guideId: string) => {
      try {
        setLoading(true);
        const { data } = await getItem({
          url: `/api/public/plans/${id}`,
        });
        setItem(data);

        // get saved guides

        const { data: guides } = await searchGuides({
          data: {
            filters: {
              _planId: id,
            },
            page: 0,
            pageSize: 100,
          },
        });
        setGuides(guides.items);

        // set current guide

        if (guideId) {
          const { data: guide } = await getItem({
            url: `/api/public/guides/${guideId}`,
          });
          setGuide(guide);
          setSteps([...guide.steps]);
        }
      } catch (error: any) {
        setError(error.response?.data?.error || error.message);
      }
      setLoading(false);
    },
    [getItem, searchGuides]
  );

  const closeEditModal = useCallback(() => {
    setEditMode(0);
  }, []);

  const deletePlan = useCallback(
    async (item) => {
      setError(null);
      setLoading(true);
      try {
        await deleteItem({ url: `/api/guest/plans/${item._id}` });
        navigate("/planner");
      } catch (error: any) {
        setError(error.response?.data?.error || error.message);
        setLoading(false);
        setEditMode(0);
      }
    },
    [deleteItem, navigate]
  );

  const deleteGuide = useCallback(
    async (item) => {
      setError(null);
      setLoading(true);
      try {
        await deleteItem({ url: `/api/guest/guides/${item._id}` });
        setEditMode(0);
        setGuide(null);
        navigate(`/planner/${id}`);
      } catch (error: any) {
        setError(error.response?.data?.error || error.message);
        setLoading(false);
        setEditMode(0);
      }
    },
    [deleteItem, navigate, id]
  );

  const savePlan = useCallback(
    async (item) => {
      setError(null);
      setLoading(true);
      try {
        // Need to add id
        item._id = id;
        await postItem({ data: item });
        doGetItem(id, guideId);
      } catch (error: any) {
        setError(error.response?.data?.error || error.message);
      }
      setEditMode(0);
      setLoading(false);
    },
    [id, guideId, postItem, doGetItem]
  );

  const saveGuide = useCallback(
    async (g) => {
      setError(null);
      setLoading(true);
      try {
        // Need to add id
        g._id = guideId;
        g._planId = id;
        const { data } = await postGuide({ data: g });
        if (guideId) {
          doGetItem(id, guideId);
        } else {
          navigate(`/planner/${id}/${data._id}`);
        }
      } catch (error: any) {
        setError(error.response?.data?.error || error.message);
      }
      setEditMode(0);
      setLoading(false);
    },
    [postGuide, id, navigate, guideId, doGetItem]
  );

  const showEditModal = useCallback(
    (mode) => {
      setError(null);
      if (role === 0) {
        notifications.showNotification({
          title: "Permission Denied",
          message: "You need to be logged in to save or edit.",
        });
      } else {
        setEditMode(mode);
      }
    },
    [role, notifications]
  );

  //
  // Effects

  useEffect(() => {
    // Set initial skills
    if (item) {
      try {
        const tree = item.tree ? JSON.parse(item.tree) : [];
        const skills = initSkills(tree);
        setInitialSkills(skills);
        setSkills(skills);  
      } catch (error: any) {
        setError(error.message);
        setInitialSkills([]);
        setSkills([]);  
      }
    }
  }, [item]);

  useEffect(() => {
    const skills = cloneDeep(initialSkills);
    for (const adv of steps) {
      applyAdvance(skills, adv);
    }
    setSkills(skills);
  }, [steps, initialSkills]);

  useEffect(() => {
    doGetItem(id, guideId);
  }, [id, guideId, doGetItem]);

  if (loading || !skills) {
    return (
      <div>
        <Card>
          <Center>
            <Loader />
          </Center>
        </Card>
      </div>
    );
  }

  return (
    <div>
      <Group position="apart">
        <Group>
          <Avatar color="blue" size="lg" radius="xl">
            <Logo width={32} height={32} />
          </Avatar>
          <div>
            <Title order={1}>{item.name}</Title>
            {guide?._id && (
              <Group>
                <Title order={3}>{guide.name}</Title>
                <Text color="gray">by {guide.creator.playerName}</Text>
              </Group>
            )}
          </div>
        </Group>
        <Group>
          <Button component={Link} variant="outline" to="/planner">
            Back
          </Button>
          {role >= 1 && (
            <Button ml="lg" variant="outline" onClick={() => showEditModal(1)}>
              Edit Skill Tree
            </Button>
          )}
          <Button disabled={loading} onClick={() => showEditModal(2)}>
            Save Plan
          </Button>
        </Group>
      </Group>

      {error && (
        <Alert
          mt="lg"
          icon={<ExclamationTriangleIcon />}
          title="Bummer!"
          color="red"
        >
          {error}
        </Alert>
      )}

      <SimpleGrid
        cols={2}
        spacing="lg"
        breakpoints={[{ maxWidth: "md", cols: 1, spacing: "lg" }]}
      >
        <Card
          withBorder
          mt="xl"
          styles={{
            root: {
              fontFamily: "monospace",
              whiteSpace: "pre",
              overflowX: "auto",
            },
          }}
        >
          {renderSkills(skills, onButtonCommand)}

          <div>
            <form onSubmit={form.onSubmit(parseCommand)}>
              <TextInput
                mt="xl"
                label="Command"
                placeholder="Commands: adv, refresh, undo"
                onKeyDown={onKeyDown}
                {...form.getInputProps("cmd")}
              />
            </form>
          </div>
        </Card>

        <Box>
          <Card withBorder mt="xl">
            {guide?.description && (
              <>
                <Text weight="bold" size="sm">
                  Description:
                </Text>
                <Text size="sm" mb="lg">
                  {guide.description}
                </Text>
              </>
            )}
            <Text weight="bold" size="sm">
              Steps:
            </Text>
            <Box mt="sm" styles={monoStyle}>
              {steps.map((s: string, i: number) => (
                <div key={i}>
                  <Code>{s}</Code>
                </div>
              ))}
            </Box>
            {steps.length === 0 ? <Code>None</Code> : ""}
            {steps.length > 0 && (
              <Box mt="lg">
                <Button size="xs" variant="outline" onClick={onUndoClick}>
                  Undo
                </Button>
                <Button
                  size="xs"
                  variant="outline"
                  color="red"
                  ml="md"
                  onClick={onRefreshClick}
                >
                  Refresh
                </Button>
              </Box>
            )}
          </Card>
          {guides?.length > 0 && (
            <Card withBorder mt="xl">
              <Text weight="bold" mb="xs" size="sm">
                Saved Plans:
              </Text>
              {guides?.map((i: any) => (
                <Group key={i._id} spacing="xs">
                  <Anchor
                    styles={monoStyle}
                    component={Link}
                    weight={i._id === guideId ? "bold" : undefined}
                    to={`/planner/${i._planId}/${i._id}`}
                  >
                    {i.name}
                  </Anchor>
                  <Text color="gray" size="sm">
                    by {i.creator?.playerName}
                  </Text>
                </Group>
              ))}
            </Card>
          )}

          <Card withBorder mt="xl">
            <Text weight="bold" size="sm">
              Examples:
            </Text>
            <Text mt="xs">Advance fighting by 5:</Text>
            <Code>adv f by 5</Code>
            <Text mt="md">Advance blunt to 200:</Text>
            <Code>adv f.o.m.b to 200</Code>
            <Text mt="md">Die away 15 levels:</Text>
            <Code>adv f by -15</Code>
            <Text mt="md">Revert the last advance:</Text>
            <Code>undo</Code>
          </Card>
        </Box>
      </SimpleGrid>

      <Modal
        size="lg"
        padding="xl"
        opened={editMode > 0}
        onClose={closeEditModal}
        title={
          editMode === 2
            ? guide?._id
              ? "Update Plan"
              : "Save Plan"
            : "Edit Skill Tree"
        }
      >
        {editMode === 1 && (
          <Form
            url="plans"
            item={item}
            onSave={savePlan}
            onDelete={deletePlan}
            onClose={closeEditModal}
          />
        )}
        {editMode === 2 && (
          <GuideForm
            item={guide}
            steps={steps}
            onSave={saveGuide}
            onDelete={deleteGuide}
            onClose={closeEditModal}
          />
        )}
      </Modal>
    </div>
  );
}

export default AdvPlannerPage;
