import { Text, Button, Group, Grid, Anchor } from "@mantine/core";
import { Link } from "react-router-dom";
import { RichTextEditor } from "@mantine/rte";
import { renderBadge } from "../../utils/userTools";
import moment from "moment";
import withItemLoader from "../withItemLoader";

interface FormProps {
  item: any;
  onClose?: (item: any) => void;
  onEdit?: (item: any) => void;
  hideButtons?: boolean;
}

function DetailsForm({ item, onClose, onEdit, hideButtons }: FormProps) {
  return (
    <div>
      <Grid mt="lg">
        <Grid.Col span={6} xs={3}>
          <Group direction="column" spacing="xs">
            <Text size="xs">URL</Text>
            <Anchor component={Link} to={`/news/${item._id}`}>
              Link
            </Anchor>
          </Group>
        </Grid.Col>
        <Grid.Col span={6} xs={3}>
          <Group direction="column" spacing="xs">
            <Text size="xs">Role</Text>
            {renderBadge(item.role)}
          </Group>
        </Grid.Col>
        <Grid.Col span={6} xs={3}>
          <Group direction="column" spacing="xs">
            <Text size="xs">Created by</Text>
            <Text>{item.creator?.playerName}</Text>
          </Group>
        </Grid.Col>
        <Grid.Col span={6} xs={3}>
          <Group direction="column" spacing="xs">
            <Text size="xs">Created</Text>
            <Text>{moment(item.created).format("D MMM YYYY")}</Text>
          </Group>
        </Grid.Col>
      </Grid>

      <RichTextEditor
        mt="xl"
        controls={[
          ["bold", "italic", "underline"],
          ["unorderedList", "orderedList"],
        ]}
        value={item.text}
        readOnly
        onChange={(e) => null}
      />

      {!hideButtons && (
        <Group mt="xl">
          <Button onClick={onClose}>Close</Button>
          {onEdit && (
            <Button variant="outline" onClick={() => onEdit(item)}>
              Edit
            </Button>
          )}
        </Group>
      )}
    </div>
  );
}

export default withItemLoader(DetailsForm);
