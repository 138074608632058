import {
  Navbar,
  NavbarProps,
  Group,
  Text,
  Box,
  MantineTheme,
  CSSObject,
  Avatar,
  ScrollArea,
} from "@mantine/core";
import { Link } from "react-router-dom";

import { ReactComponent as QuestsLogo } from "../../assets/Quests.svg";
import { ReactComponent as ArmoursLogo } from "../../assets/Armours.svg";
import { ReactComponent as ArmourSetsLogo } from "../../assets/ArmourSets.svg";
import { ReactComponent as UsersLogo } from "../../assets/Users.svg";
import { ReactComponent as TemplarLogo } from "../../assets/TemplarLogo.svg";
import { ReactComponent as LoreLogo } from "../../assets/Lore.svg";
import { ReactComponent as BotLogo } from "../../assets/Bot.svg";
import { ReactComponent as LogsLogo } from "../../assets/Logs.svg";
import { ReactComponent as Planner } from "../../assets/Planner.svg";
import { ReactComponent as WeaponsLogo } from "../../assets/Weapons.svg";
import { ReactComponent as InvitesLogo } from "../../assets/Invites.svg";
import { ReactComponent as LockLogo } from "../../assets/Lock.svg";
import { ReactComponent as NewsLogo } from "../../assets/News.svg";

import UserProfile from "./UserProfile";
import UserContext from "../../utils/userContext";
import { useCallback, useContext } from "react";
import { Action } from "../../types";

interface CustomNavbarProps extends Omit<NavbarProps, "children"> {
  opened: boolean;
  setOpened: Action<boolean>;
}

const navbarStyles = (theme: MantineTheme): CSSObject => ({
  padding: 10,
  borderRight: `1px solid ${
    theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.gray[5]
  }`,
  backgroundColor:
    theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.white,
});

const groupStyles = (theme: MantineTheme): CSSObject => ({
  textAlign: "left",
  borderRadius: 10,
  padding: 10,
  width: "100%",
  "&:hover": {
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[6]
        : theme.colors.gray[2],
  },
});

function CustomNavbar(props: CustomNavbarProps) {
  const { opened, setOpened, ...rest } = props;
  const { user } = useContext(UserContext);

  const onClick = useCallback(() => {
    setOpened(false);
  }, [setOpened]);

  return (
    <Navbar
      {...rest}
      p="md"
      hiddenBreakpoint="sm"
      hidden={!opened}
      sx={navbarStyles}
    >
      <Navbar.Section grow component={ScrollArea} mx="-xs" px="xs">
        <div>
          <Box
            sx={() => ({
              a: {
                textDecoration: "none",
              },
            })}
          >
            {!user && (
              <Link to="/login" onClick={onClick}>
                <Group sx={groupStyles}>
                  <Avatar color="blue" size="sm" radius="xl">
                    <LockLogo width={20} height={20} />
                  </Avatar>
                  <Text color="gray" weight="bold">
                    Login
                  </Text>
                </Group>
              </Link>
            )}

            <Link to="/" onClick={onClick}>
              <Group sx={groupStyles}>
                <Avatar color="blue" size="sm" radius="xl">
                  <TemplarLogo width={20} height={20} />
                </Avatar>
                <Text color="gray" weight="normal">
                  Bulletin Board
                </Text>
              </Group>
            </Link>

            <Text mt="sm" size="xs" color="gray">
              Tools &amp; Fun
            </Text>
            <Link to="/planner" onClick={onClick}>
              <Group sx={groupStyles}>
                <Avatar color="blue" size="sm" radius="xl">
                  <Planner width={20} height={20} />
                </Avatar>
                <Text color="gray" weight="normal">
                  Skill Planner
                </Text>
              </Group>
            </Link>
            <Link to="/logs" onClick={onClick}>
              <Group sx={groupStyles}>
                <Avatar color="blue" size="sm" radius="xl">
                  <LogsLogo width={20} height={20} />
                </Avatar>
                <Text color="gray" weight="normal">
                  Logs
                </Text>
              </Group>
            </Link>
            {user?.role >= 2 && (
              <Link to="/multis" onClick={onClick}>
                <Group sx={groupStyles}>
                  <Avatar color="blue" size="sm" radius="xl">
                    <UsersLogo width={20} height={20} />
                  </Avatar>
                  <Text color="gray" weight="normal">
                    Multis
                  </Text>
                </Group>
              </Link>
            )}

            <Text mt="sm" size="xs" color="gray">
              The Library
            </Text>
            <Link to="/lore" onClick={onClick}>
              <Group sx={groupStyles}>
                <Avatar color="blue" size="sm" radius="xl">
                  <LoreLogo width={20} height={20} />
                </Avatar>
                <Text color="gray" weight="normal">
                  Pages
                </Text>
              </Group>
            </Link>
            <Link to="/quests" onClick={onClick}>
              <Group sx={groupStyles}>
                <Avatar color="blue" size="sm" radius="xl">
                  <QuestsLogo width={20} height={20} />
                </Avatar>
                <Text color="gray" weight="normal">
                  Quests
                </Text>
              </Group>
            </Link>

            <Text mt="sm" size="xs" color="gray">
              Smithy
            </Text>
            <Link to="/weapons" onClick={onClick}>
              <Group sx={groupStyles}>
                <Avatar color="blue" size="sm" radius="xl">
                  <WeaponsLogo width={20} height={20} />
                </Avatar>
                <Text color="gray" weight="normal">
                  Weapons
                </Text>
              </Group>
            </Link>
            <Link to="/armours" onClick={onClick}>
              <Group sx={groupStyles}>
                <Avatar color="blue" size="sm" radius="xl">
                  <ArmoursLogo width={20} height={20} />
                </Avatar>
                <Text color="gray" weight="normal">
                  Armours
                </Text>
              </Group>
            </Link>
            <Link to="/sets" onClick={onClick}>
              <Group sx={groupStyles}>
                <Avatar color="blue" size="sm" radius="xl">
                  <ArmourSetsLogo width={20} height={20} />
                </Avatar>
                <Text color="gray" weight="normal">
                  Armour Sets
                </Text>
              </Group>
            </Link>

            {user?.role >= 3 && (
              <>
                <Text mt="sm" size="xs" color="gray">
                  Council Pages
                </Text>
                <Link to="/news" onClick={onClick}>
                  <Group sx={groupStyles}>
                    <Avatar color="yellow" size="sm" radius="xl">
                      <NewsLogo width={20} height={20} />
                    </Avatar>
                    <Text color="gray" weight="normal">
                      News
                    </Text>
                  </Group>
                </Link>
                <Link to="/users" onClick={onClick}>
                  <Group sx={groupStyles}>
                    <Avatar color="yellow" size="sm" radius="xl">
                      <UsersLogo width={20} height={20} />
                    </Avatar>
                    <Text color="gray" weight="normal">
                      Members
                    </Text>
                  </Group>
                </Link>
                <Link to="/invites" onClick={onClick}>
                  <Group sx={groupStyles}>
                    <Avatar color="yellow" size="sm" radius="xl">
                      <InvitesLogo width={20} height={20} />
                    </Avatar>
                    <Text color="gray" weight="normal">
                      Invites
                    </Text>
                  </Group>
                </Link>
                {user?.role >= 4 && (
                  <Link to="/bot" onClick={onClick}>
                    <Group sx={groupStyles}>
                      <Avatar color="yellow" size="sm" radius="xl">
                        <BotLogo width={20} height={20} />
                      </Avatar>
                      <Text color="gray" weight="normal">
                        Bot
                      </Text>
                    </Group>
                  </Link>
                )}
              </>
            )}
          </Box>
        </div>
      </Navbar.Section>
      <Navbar.Section>
        <UserProfile onClick={onClick} />
      </Navbar.Section>
    </Navbar>
  );
}

export default CustomNavbar;
