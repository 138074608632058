import {
  Title,
  Text,
  Group,
  Avatar,
  Card,
  Center,
  Loader,
  Alert,
} from "@mantine/core";

import { ExclamationTriangleIcon } from "@modulz/radix-icons";
import useAxios from "axios-hooks";
import { useParams, useNavigate } from "react-router-dom";
import { useCallback, useContext, useState } from "react";
import { ReactComponent as Logo } from "../assets/Invites.svg";
import Register from "../components/Invites/Register";
import UserContext from "../utils/userContext";

function Profile() {
  const { id } = useParams();
  const [{ loading, data, error: loadError }] = useAxios(`/api/public/invites/${id}`);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { setUser } = useContext(UserContext);

  const [, postItem] = useAxios(
    {
      url: "/api/public/invites/register",
      method: "POST",
    },
    {
      manual: true,
    }
  );

  const createUser = useCallback(
    async (values) => {
      setError(null);
      setSaving(true);
      try {
        values.key = id;
        const { data } = await postItem({ data: values });
        setUser(data);
        navigate("/");
      } catch (error: any) {
        setError(error.response?.data?.error || error.message);
      }
      setSaving(false);
    },
    [postItem, id, navigate, setUser]
  );

  return (
    <div>
      <Group>
        <Avatar color="red" size="md" radius="xl">
          <Logo width={24} height={24} />
        </Avatar>
        <Title order={1}>Invitation</Title>
      </Group>

      {(error || loadError?.message) && (
        <Alert
          mt="lg"
          icon={<ExclamationTriangleIcon />}
          title="Bummer!"
          color="red"
        >
          {error || loadError?.message}
        </Alert>
      )}

      {loading && (
        <Center>
          <Loader />
        </Center>
      )}

      {data && (
        <Card withBorder mt="xl">
          <Text>
            You have been given an invitation. Fill out the form to create your
            user.
          </Text>
          <Register item={data} saving={saving} onSave={createUser} />
        </Card>
      )}
    </div>
  );
}

export default Profile;
