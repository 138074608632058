import { useCallback, useContext, useEffect, useRef } from "react";
import {
  TextInput,
  Button,
  Select,
  Group,
  Checkbox,
  Card,
  SimpleGrid,
  Grid,
  Text,
  ActionIcon,
  Textarea,
} from "@mantine/core";
import { X } from "tabler-icons-react";

import { useForm } from "@mantine/hooks";
import UserContext from "../../utils/userContext";
import { RichTextEditor } from "@mantine/rte";
import { roleOptions } from "../../utils/userTools";
import FileZone from "../Forms/FileZone";

import { loreTypes } from "../../utils/loreTools";

interface FormProps {
  item: any;
  onSave: (item: any) => void;
  onDelete: (item: any) => void;
  onClose: (item: any) => void;
  onImageUpload: (file: File) => Promise<string>;
}
function Form({ item, onSave, onDelete, onClose, onImageUpload }: FormProps) {
  const userState = useContext(UserContext);
  const role = userState.user?.role || 0;
  const editorRef = useRef<any>();

  const form = useForm({
    initialValues: {
      name: item.name || "",
      text: item.text || "",
      role: item.role?.toString() || Math.min(role || 2, 2).toString(),
      type: item.type || "",
      monospace: item.monospace !== undefined ? item.monospace : false,
      tabular: item.tabular !== undefined ? item.tabular : false,
      files: item.files || [],
    },
  });

  const onDeleteClick = useCallback(() => {
    if (window.confirm("Are you sure you want to delete this item?")) {
      onDelete(item);
    }
  }, [item, onDelete]);

  const onDropFile = useCallback(
    (droppedFiles) => {
      const files = form.values.files;
      const newFiles = files.concat(
        droppedFiles.map((f: any) => ({
          name: f.name,
          upload: f,
        }))
      );
      form.setFieldValue("files", newFiles);
    },
    [form]
  );

  const onRemoveFile = useCallback(
    (name) => {
      const files = form.values.files;
      const index = files.findIndex((f: any) => f.name === name);
      if (index >= 0) {
        const file = files[index];
        let newFiles = [...files];

        if (file.upload) {
          // We can just remove it, hasn't been uploaded
          newFiles = newFiles.filter((f) => f.name !== file.name);
        } else {
          const newFile = {
            ...file,
          };
          newFile.delete = true;
          newFiles[index] = newFile;
        }

        form.setFieldValue("files", newFiles);
      }
    },
    [form]
  );

  useEffect(() => {
    if (editorRef.current !== undefined) {
      editorRef.current.focus();
    }
  }, []);

  return (
    <>
      <Card withBorder mt="lg">
        <form onSubmit={form.onSubmit(onSave)}>
          <TextInput
            required
            label="Name"
            placeholder="Name"
            {...form.getInputProps("name")}
          />

          <SimpleGrid
            cols={2}
            mt="lg"
            breakpoints={[{ maxWidth: "sm", cols: 1 }]}
          >
            <Select
              required
              label="Type"
              placeholder="Select a type"
              {...form.getInputProps("type")}
              data={loreTypes}
            />
            <Select
              required
              label="Permission"
              placeholder="Select a role"
              {...form.getInputProps("role")}
              data={roleOptions.slice(0, role + 1)}
            />
          </SimpleGrid>

          <SimpleGrid
            cols={2}
            mt="xl"
            breakpoints={[{ maxWidth: "sm", cols: 1 }]}
          >
            <Checkbox
              label="Monospace Text"
              {...form.getInputProps("monospace", { type: "checkbox" })}
            />
            <Checkbox
              label="Tabular Data"
              {...form.getInputProps("tabular", { type: "checkbox" })}
            />
          </SimpleGrid>

          <Group mt="xl">
            <Button type="submit">Save</Button>
            <Button variant="outline" onClick={onClose}>
              Cancel
            </Button>
            {item._id && (
              <Button
                variant="outline"
                ml="xl"
                color="red"
                onClick={onDeleteClick}
              >
                Delete
              </Button>
            )}
          </Group>
        </form>
      </Card>

      <Grid mt="lg">
        <Grid.Col sm={7}>
          <Card withBorder style={{ minHeight: 100 }}>
            <Text size="xs">Files</Text>
            {!form.values.files?.length && <Text>No files</Text>}
            {form.values.files?.map((f: any) => (
              <Group key={f.key} position="left" mt="md">
                <ActionIcon onClick={() => onRemoveFile(f.name)}>
                  <X width={22} height={22} />
                </ActionIcon>
                <Text color={f.delete ? "red" : undefined}>{f.name}</Text>
              </Group>
            ))}
          </Card>
        </Grid.Col>
        <Grid.Col sm={5}>
          <FileZone
            onDropFile={onDropFile}
            text="Attach file(s)"
            maxFileSize={50}
          />
        </Grid.Col>
      </Grid>

      {form.values["tabular"] && (
        <Card withBorder mt="lg">
          <Textarea
            ref={editorRef}
            autosize={true}
            minRows={10}
            label="Text"
            placeholder="Text in tabular form"
            {...form.getInputProps("text")}
          />
        </Card>
      )}

      {!form.values["tabular"] && (
        <RichTextEditor
          mt="lg"
          ref={editorRef}
          styles={{
            root: {
              fontFamily: form.values.monospace ? "monospace" : undefined,
              p: {
                marginBottom: 4,
              },
            },
          }}
          controls={[
            ["bold", "italic", "underline"],
            ["unorderedList", "orderedList", "image"],
          ]}
          onImageUpload={onImageUpload}
          value={form.values["text"]}
          onChange={(e) => form.setFieldValue("text", e)}
        />
      )}
    </>
  );
}

export default Form;
