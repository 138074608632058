import { useContext } from "react";
import { Routes, Route } from "react-router-dom";
import LoginForm from "./pages/Login";
import Quests from "./pages/Quests";
import Armours from "./pages/Armours";
import Weapons from "./pages/Weapons";
import SetBuilder from "./pages/SetBuilder";
import Sets from "./pages/Sets";
import Lore from "./pages/Lore";
import LorePage from "./pages/LorePage";
import Logs from "./pages/Logs";
import LogsPage from "./pages/LogsPage";
import Planner from "./pages/Planner";
import PlannerPage from "./pages/PlannerPage";

import Users from "./pages/Users";
import Armour from "./pages/ArmourPage";
import Weapon from "./pages/WeaponPage";
import Dashboard from "./pages/Dashboard";
import Profile from "./pages/Profile";
import Bot from "./pages/Bot";
import Invites from "./pages/Invites";
import Invitation from "./pages/Invitation";
import News from "./pages/News";
import NewsPage from "./pages/NewsPage";
import Multis from "./pages/Multis";

import Mapper from "./pages/Mapper";

import Error404 from "./pages/Error404";

import UserContext from "./utils/userContext";

function RoutesTable() {
  const userState = useContext(UserContext);

  return (
    <Routes>
      <Route path="/login" element={<LoginForm />}></Route>

      <Route path="/map" element={<Mapper />}></Route>

      <Route path="/invitation/:id" element={<Invitation />}></Route>
      <Route path="/" element={<Dashboard />}></Route>
      <Route path="/armours/:id" element={<Armour />}></Route>
      <Route path="/armours" element={<Armours />}></Route>
      <Route path="/weapons/:id" element={<Weapon />}></Route>
      <Route path="/weapons" element={<Weapons />}></Route>
      <Route path="/quests" element={<Quests />}></Route>
      <Route path="/lore/:id" element={<LorePage />}></Route>
      <Route path="/lore" element={<Lore />}></Route>
      <Route path="/logs/:id" element={<LogsPage />}></Route>
      <Route path="/logs" element={<Logs />}></Route>
      <Route path="/planner/:id/:guideId" element={<PlannerPage />}></Route>
      <Route path="/planner/:id" element={<PlannerPage />}></Route>
      <Route path="/planner" element={<Planner />}></Route>
      <Route path="/news/:id" element={<NewsPage />}></Route>

      {userState.user && <Route path="/profile" element={<Profile />}></Route>}

      {userState.user?.role >= 2 && (
        <>
          <Route path="/setbuilder/:id" element={<SetBuilder />}></Route>
          <Route path="/setbuilder" element={<SetBuilder />}></Route>
          <Route path="/sets" element={<Sets />}></Route>
          <Route path="/multis" element={<Multis />}></Route>
        </>
      )}

      {userState.user?.role >= 3 && (
        <>
          <Route path="/news" element={<News />}></Route>
          <Route path="/users" element={<Users />}></Route>
          <Route path="/invites" element={<Invites />}></Route>
        </>
      )}

      {userState.user?.role >= 4 && (
        <>
          <Route path="/bot" element={<Bot />}></Route>
        </>
      )}

      <Route path="*" element={<Error404 />}></Route>
    </Routes>
  );
}

export default RoutesTable;
