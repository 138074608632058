import { Text, Button, Grid, Group, SimpleGrid, Anchor } from "@mantine/core";
import { Link } from "react-router-dom";
import { elements } from "../../utils/armourTools";
import { renderBadge } from "../../utils/userTools";

interface FormProps {
  item: any;
  onClose?: (item: any) => void;
  onEdit?: (item: any) => void;
  hideButtons?: boolean;
}

const getType = function (i: string) {
  return i.charAt(0).toUpperCase() + i.slice(1);
};

function DetailsForm({ item, onClose, onEdit, hideButtons }: FormProps) {
  return (
    <div>
      <SimpleGrid cols={3} mt="xl" breakpoints={[{ maxWidth: "xs", cols: 2 }]}>
        <Group direction="column" spacing="xs">
          <Text size="xs">Type</Text>
          <Text>{getType(item.type)}</Text>
        </Group>
        <Group direction="column" spacing="xs">
          <Text size="xs">Save Level</Text>
          <Text>
            {item.saveLevel || item.saveLevel === 0
              ? item.saveLevel
              : "Unknown"}
          </Text>
        </Group>
        <Group direction="column" spacing="xs">
          <Text size="xs">Created by</Text>
          <Text>{item.creator?.playerName}</Text>
        </Group>
      </SimpleGrid>

      <SimpleGrid cols={3} mt="xl" breakpoints={[{ maxWidth: "xs", cols: 2 }]}>
        <Group direction="column" spacing="xs">
          <Text size="xs">URL</Text>
          <Anchor component={Link} to={`/armours/${item._id}`}>
            Link
          </Anchor>
        </Group>
        <Group direction="column" spacing="xs">
          <Text size="xs">Permission</Text>
          {renderBadge(item.role)}
        </Group>
      </SimpleGrid>

      {item.notes && (
        <Group mt="xl" direction="column" spacing="xs">
          <Text size="xs">Notes</Text>
          <Text>{item.notes}</Text>
        </Group>
      )}

      <Group mt="xl" direction="column" spacing="xs">
        <Text size="xs">Location</Text>
        <Text>{item.location}</Text>
      </Group>

      <Grid mt="lg">
        {elements.map((e) => {
          return (
            <Grid.Col key={e} span={2}>
              <Group mt="xs" direction="column" spacing="xs">
                <Text size="xs">{e}</Text>
                <Text>{item.values[e]}</Text>
              </Group>
            </Grid.Col>
          );
        })}
        <Grid.Col span={2}>
          <Group mt="xs" direction="column" spacing="xs">
            <Text size="xs">total</Text>
            <Text>{item.values.total}</Text>
          </Group>
        </Grid.Col>
      </Grid>

      {!hideButtons && (
        <Group mt="xl">
          <Button onClick={onClose}>Close</Button>
          {onEdit && (
            <Button variant="outline" onClick={() => onEdit(item)}>
              Edit
            </Button>
          )}
        </Group>
      )}
    </div>
  );
}

export default DetailsForm;
