import { useContext } from "react";
import {
  TextInput,
  Button,
  Select,
  Group,
  Checkbox,
  SimpleGrid,
} from "@mantine/core";
import { useForm } from "@mantine/hooks";
import UserContext from "../../utils/userContext";
import { roleOptions } from "../../utils/userTools";
import { loreTypes } from "../../utils/loreTools";

interface FormProps {
  item: any;
  onSave: (item: any) => void;
  onClose: (item: any) => void;
}

function Form({ item, onSave, onClose }: FormProps) {
  const userState = useContext(UserContext);
  const role = userState.user?.role || 0;

  const form = useForm({
    initialValues: {
      name: "",
      text: "",
      role: item.role?.toString() || Math.min(role || 2, 2).toString(),
      type: "",
      monospace: item.monospace !== undefined ? item.monospace : false,
      tabular: item.tabular !== undefined ? item.tabular : false,
    },
  });

  return (
    <form onSubmit={form.onSubmit(onSave)}>
      <TextInput
        required
        label="Name"
        placeholder="Name"
        {...form.getInputProps("name")}
      />

      <SimpleGrid cols={2} mt="lg" breakpoints={[{ maxWidth: "sm", cols: 1 }]}>
        <Select
          required
          label="Type"
          placeholder="Select a type"
          {...form.getInputProps("type")}
          data={loreTypes}
        />
        <Select
          required
          label="Permission"
          placeholder="Select a role"
          {...form.getInputProps("role")}
          data={roleOptions.slice(0, role + 1)}
        />
      </SimpleGrid>

      <SimpleGrid cols={2} mt="xl" breakpoints={[{ maxWidth: "sm", cols: 1 }]}>
        <Checkbox
          label="Monospace Text"
          {...form.getInputProps("monospace", { type: "checkbox" })}
        />
        <Checkbox
          label="Tabular Data"
          {...form.getInputProps("tabular", { type: "checkbox" })}
        />
      </SimpleGrid>

      <Group mt="xl">
        <Button type="submit">Continue</Button>
        <Button variant="outline" onClick={onClose}>
          Cancel
        </Button>
      </Group>
    </form>
  );
}

export default Form;
