import { useCallback, useContext, useMemo } from "react";
import {
  TextInput,
  Button,
  Select,
  Grid,
  NumberInput,
  Group,
} from "@mantine/core";

import { useForm } from "@mantine/hooks";
import { armourTypes, elements } from "../../utils/armourTools";
import UserContext from "../../utils/userContext";
import { roleOptions } from "../../utils/userTools";

interface FormProps {
  item: any;
  onSave: (item: any) => void;
  onConfigure: (item: any) => void;
  onDelete: (item: any) => void;
  onClose: (item: any) => void;
}

const types = armourTypes.map((i) => ({
  value: i,
  label: i.charAt(0).toUpperCase() + i.slice(1),
}));

function convertFormValues(values: any) {
  const armourValues: any = {};

  let total = 0;
  for (const element of elements) {
    const value = parseInt(values[`values.${element}`]);
    if (value > 0) {
      armourValues[element] = value;
      total += value;
    }
    delete values[`values.${element}`];
  }
  values.values = armourValues;
  values.values.total = total;

  return values;
}

function DetailsForm({
  item,
  onSave,
  onConfigure,
  onDelete,
  onClose,
}: FormProps) {
  const userState = useContext(UserContext);

  const role = userState.user?.role || 0;
  const initial = useMemo(() => {
    const result: any = {
      name: item.name || "",
      type: item.type || "",
      location: item.location || "",
      saveLevel: item.saveLevel,
      notes: item.notes || "",
      role: item.role?.toString() || Math.min(role || 2, 2).toString(),
    };
    for (const element of elements) {
      result[`values.${element}`] = item.values[element] || 0;
    }
    return result;
  }, [item, role]);

  const form = useForm({
    initialValues: initial,
  });

  const onConfigureClick = useCallback(() => {
    const result = convertFormValues(form.values);
    const newItem = {
      ...item,
      ...result,
    };
    onConfigure(newItem);
  }, [item, form, onConfigure]);

  const onSubmit = useCallback(
    (values: typeof form["values"]) => {
      const result = convertFormValues(values);
      onSave(result);
    },
    [onSave]
  );

  const onDeleteClick = useCallback(() => {
    if (window.confirm("Are you sure you want to delete this item?")) {
      onDelete(item);
    }
  }, [item, onDelete]);

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <TextInput
        required
        label="Name"
        placeholder="Armour name"
        {...form.getInputProps("name")}
      />

      <TextInput
        mt="lg"
        label="Notes"
        placeholder="Optional notes about the armour"
        {...form.getInputProps("notes")}
      />

      <Grid mt="lg">
        <Grid.Col span={6} xs={4}>
          <Select
            required
            label="Armour Type"
            placeholder="Select armour type"
            data={types}
            {...form.getInputProps("type")}
          />
        </Grid.Col>
        <Grid.Col span={6} xs={4}>
          <Select
            required
            label="Permission"
            placeholder="Select a role"
            {...form.getInputProps("role")}
            data={roleOptions.slice(0, userState.user.role + 1)}
          />
        </Grid.Col>
        <Grid.Col span={6} xs={4}>
          <NumberInput
            required={true}
            label="Save level"
            {...form.getInputProps("saveLevel")}
          />
        </Grid.Col>
      </Grid>

      <TextInput
        mt="lg"
        label="Location"
        placeholder="eg: Ice Mage, Ravens"
        {...form.getInputProps("location")}
      />

      <Grid mt="lg">
        {elements.map((e) => {
          return (
            <Grid.Col key={e} span={6} md={2} xs={4}>
              <NumberInput
                label={e}
                placeholder="0"
                min={0}
                max={10}
                {...form.getInputProps(`values.${e}`)}
              />
            </Grid.Col>
          );
        })}
        <Grid.Col span={4}>
          <Button size="sm" mt="xl" onClick={onConfigureClick} variant="light">
            Configure
          </Button>
        </Grid.Col>
      </Grid>

      <Group mt="xl">
        <Button type="submit">Save</Button>
        <Button variant="outline" onClick={onClose}>
          Cancel
        </Button>
        {item._id && (
          <Button variant="outline" ml="xl" color="red" onClick={onDeleteClick}>
            Delete
          </Button>
        )}
      </Group>
    </form>
  );
}

export default DetailsForm;
