import { useCallback, useContext } from "react";
import {
  TextInput,
  Button,
  Select,
  Group,
  Grid,
} from "@mantine/core";

import { useForm } from "@mantine/hooks";
import UserContext from "../../utils/userContext";
import { roleOptions } from "../../utils/userTools";

interface FormProps {
  item: any;
  onSave: (item: any, file?: any) => void;
  onDelete: (item: any) => void;
  onClose: (item: any) => void;
}

function Form({ item, onSave, onDelete, onClose }: FormProps) {
  const userState = useContext(UserContext);
  const role = userState.user?.role || 0;

  const form = useForm({
    initialValues: {
      playerName: item.playerName || "",
      role: item.role?.toString() || Math.min(role || 2, 2).toString(),
    },
  });
  const onDeleteClick = useCallback(() => {
    if (window.confirm("Are you sure you want to delete this item?")) {
      onDelete(item);
    }
  }, [item, onDelete]);

  return (
    <>
      <form onSubmit={form.onSubmit(onSave)}>
        <Grid mt="md">
          <Grid.Col xs={6}>
            <TextInput
              required
              label="Player Name"
              placeholder="Enter a name"
              {...form.getInputProps("playerName")}
            />
          </Grid.Col>
          <Grid.Col xs={6}>
            <Select
              required
              label="Role"
              placeholder="Select a role"
              {...form.getInputProps("role")}
              data={roleOptions.slice(0, role + 1)}
            />
          </Grid.Col>
        </Grid>

        <Group mt="xl">
          <Button type="submit">Save</Button>
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          {item._id && (
            <Button
              variant="outline"
              ml="xl"
              color="red"
              onClick={onDeleteClick}
            >
              Delete
            </Button>
          )}
        </Group>
      </form>
    </>
  );
}

export default Form;
