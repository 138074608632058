import { useState, useCallback, useContext, useEffect } from "react";
import {
  Title,
  Card,
  Group,
  Center,
  Button,
  Alert,
  Avatar,
  Loader,
  Modal,
} from "@mantine/core";
import useAxios from "axios-hooks";
import { Link, useParams, useNavigate } from "react-router-dom";

import { ExclamationTriangleIcon } from "@modulz/radix-icons";

import EditForm from "../components/News/Form";
import Details from "../components/News/Details";

import { ReactComponent as Logo } from "../assets/News.svg";

import UserContext from "../utils/userContext";

function NewsPage() {
  const params = useParams();
  const navigate = useNavigate();

  const userState = useContext(UserContext);
  const [error, setError] = useState(null);
  const [item, setItem] = useState<any>(null);
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(true);

  const role = userState.user?.role || 0;

  const id = (params.id as string) || "none";

  //
  // Axios

  const [, getItem] = useAxios(
    {
      method: "GET",
    },
    {
      manual: true,
    }
  );

  const [, postItem] = useAxios(
    {
      url: "/api/supertemplar/news",
      method: "POST",
    },
    {
      manual: true,
    }
  );

  const [, deleteItem] = useAxios(
    {
      method: "DELETE",
    },
    {
      manual: true,
    }
  );

  //
  // Callbacks

  const doGetItem = useCallback(
    async (id: string) => {
      try {
        setLoading(true);
        const { data } = await getItem({
          url: `/api/public/news/${id}`,
        });
        setItem(data);
        if (data.text === "") {
          setEditMode(true);
        }
        setLoading(false);
      } catch (error: any) {
        setError(error.response?.data?.error || error.message);
      }
    },
    [getItem]
  );

  const closeEditModal = useCallback(() => {
    setEditMode(false);
  }, []);

  const onDeleteItem = useCallback(
    async (item) => {
      setError(null);
      setLoading(true);

      try {
        await deleteItem({ url: `/api/supertemplar/news/${item._id}` });
        navigate("/news");
      } catch (error: any) {
        setError(error.response?.data?.error || error.message);
        setLoading(false);
      }
    },
    [deleteItem, navigate]
  );

  const onSaveItem = useCallback(
    async (item) => {
      setError(null);
      setLoading(true);

      try {
        item._id = id;
        const { data } = await postItem({ data: item });
        setItem(data);
      } catch (error: any) {
        setError(error.response?.data?.error || error.message);
      }

      setEditMode(false);
      setLoading(false);
    },
    [id, postItem]
  );

  //
  // Effects

  useEffect(() => {
    doGetItem(id);
  }, [id, doGetItem]);

  if (loading) {
    return (
      <div>
        <Card>
          <Center>
            <Loader />
          </Center>
        </Card>
      </div>
    );
  }

  return (
    <div>
      <Group position="apart">
        <Group>
          <Avatar color="blue" size="lg" radius="xl">
            <Logo width={32} height={32} />
          </Avatar>
          <Title order={1}>{item.name}</Title>
        </Group>
        <Group>
          <Button component={Link} variant="outline" to="/news">
            Back
          </Button>
          {role >= 1 && <Button onClick={() => setEditMode(true)}>Edit</Button>}
        </Group>
      </Group>

      {error && (
        <Alert
          mt="lg"
          icon={<ExclamationTriangleIcon />}
          title="Bummer!"
          color="red"
        >
          {error}
        </Alert>
      )}

      <Card withBorder mt="lg">
        {item && <Details url="news" item={item} hideButtons={true} />}
      </Card>

      <Modal
        size="lg"
        padding="xl"
        opened={editMode}
        onClose={closeEditModal}
        title="Armour Details"
      >
        {editMode && (
          <EditForm
            item={item}
            onSave={onSaveItem}
            onDelete={onDeleteItem}
            onClose={closeEditModal}
          />
        )}
      </Modal>
    </div>
  );
}

export default NewsPage;
