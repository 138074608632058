import { Title, Text, Group, Avatar, Card } from "@mantine/core";
import { EyeClosedIcon } from "@modulz/radix-icons";

function NoPermission() {
  return (
    <div>
      <Group>
        <Avatar color="red" size="lg" radius="xl">
          <EyeClosedIcon width={32} height={32} />
        </Avatar>
        <Title order={1}>What?</Title>
      </Group>

      <Card mt="xl">
        <Text>You cannot view this page. You need to level up first!</Text>
      </Card>
    </div>
  );
}

export default NoPermission;
