import React from "react";

interface UserContextState {
  user: any,
  setUser: (user: any) => void,
  updateUser: (user: any) => void
}

const UserContext = React.createContext<UserContextState>({
  user: null,
  setUser: (user: any) => {},
  updateUser: (user: any) => {},
});

export default UserContext;