import {
  Card,
  Center,
  Loader,
  Title,
  Text,
  Anchor,
  Group,
  Avatar,
} from "@mantine/core";
import useAxios from "axios-hooks";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import ResponsiveTable from "../Layout/ResponsiveTable";

interface Props {
  url: string;
  title: string;
  Logo: any;
  filters?: any
}

function LatestItems(props: Props) {
  const { url, title, Logo } = props;

  const args = {
    sort: {
      created: -1,
    },
    page: 0,
    pageSize: 5,
    filters: {},
    items: [],
    project: {
      _id: 1,
      name: 1,
      creator: 1,
    },
  };
  
  args.filters = props.filters || args.filters;

  const [{ data, loading }, search] = useAxios(
    {
      url: `/api/public/${url}/search`,
      method: "POST",
      data: args,
    },
    {
      manual: true,
    }
  );

  useEffect(() => {
    search();
  }, [search]);

  return (
    <Card withBorder mt="lg">
      <Group>
        <Avatar color="blue" radius="xl">
          <Logo width={28} height={28} />
        </Avatar>
        <Title order={5}>{title}</Title>
      </Group>

      <ResponsiveTable verticalSpacing="xs" mt="md" striped>
        <thead>
          <tr>
            <th>Name</th>
            <th className="min">Created by</th>
          </tr>
        </thead>
        <tbody>
          {data?.items.map((i: any) => (
            <tr key={i._id}>
              <td>
                <Anchor component={Link} to={`/${url}/${i._id}`}>
                  {i.name}
                </Anchor>
              </td>
              <td>{i.creator?.playerName}</td>
            </tr>
          ))}
        </tbody>
      </ResponsiveTable>
      {loading && (
        <Center>
          <Loader />
        </Center>
      )}
      {data?.items.length === 0 && <Text p={10}>No items found.</Text>}
    </Card>
  );
}

export default LatestItems;
