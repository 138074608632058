export const armourTypes = [
  "armour",
  "belt",
  "bracer",
  "boot",
  "cloak",
  "decoration",
  "glove",
  "greave",
  "helmet",
  "mask",
  "misc",
  "pants",
  "pendant",
  "ring",
  "robe",
  "shirt",
  "shield",
];

export const armourWeights: { [field: string]: number } = {
  armour: 20,
  belt: 2.5,
  bracer: 3,
  boot: 2,
  cloak: 3,
  decoration: 0,
  glove: 2,
  greave: 3,
  helmet: 4,
  mask: 2,
  misc: 1,
  pants: 2,
  pendant: 1.5,
  ring: 1,
  robe: 3,
  shirt: 2.5,
  shield: 10,
};

export const bodySlots = [
  "armour",
  "shield",
  "helmet",
  "bracer",
  "cloak",
  "greave",
  "robe",
  "belt",
  "shirt",
  "boot",
  "glove",
  "mask",
  "pants",
  "pendant",
  "misc",
  "ring1",
  "ring2",
];

export const elements = [
  "blunt",
  "sharp",
  "pierce",
  "acid",
  "cold",
  "electricity",
  "fire",
  "magic",
  "poison",
  "psionic",
];

export function toScore(value: string): number {
  switch (value) {
    case "exceptional":
      return 10;
    case "excellent":
      return 9;
    case "very good":
      return 8;
    case "good":
      return 7;
    case "pretty good":
      return 6;
    case "okay":
      return 5;
    case "fair":
      return 4;
    case "poor":
      return 3;
    case "pretty poor":
      return 2;
    case "very poor":
      return 1;
  }
  return 0;
}

export function judgeRows(raws: string[], item: any = {}) {
  // Merge the rows if we have multiliners
  const rows: string[] = [];
  let current: string | null = null;
  for (const raw of raws) {
    if (raw.startsWith("The ")) {
      current = raw;
    } else {
      current = `${current} ${raw}`;
    }
    if (current?.endsWith(".") || current?.indexOf(" saves ") > 0) {
      rows.push(current);
      current = null;
    }
  }

  let type = null;
  let name = null;
  const results: any = {};
  let total = 0;
  let saveLevel: null | number = null;

  for (let i = 0; i < rows.length; i++) {
    const pre = rows[i];
    if (pre.startsWith("The Ultimately Gnomishly Insulated ")) {
      throw Error("Please do not add UGI items");
    }
    const row = rows[i];
    let matches = row.match(/^(.+) is (.+) at protecting from (.+) attacks\.$/);

    // Check if judge value
    if (matches?.length === 4) {
      const element = matches[3];
      if (elements.indexOf(element) === -1) {
        throw new Error(`Unknown element '${element}'`);
      }
      const score = toScore(matches[2]);
      results[element] = score;
      total += score;
      continue;
    }
    // Check if material
    matches = row.match(/^(.+) is made of (.+)/);
    if (matches && matches.length > 0) {
      continue;
    }
    // Check if immune
    matches = row.match(
      /((.+) is immune to (.+)|is not immune to anything\.$)$/
    );
    if (matches && matches.length > 0) {
      continue;
    }
    // Check if other
    matches = row.match(
      /(at being quiet\.|at resisting damage\.|at absorbing damage\.|at being flexible\.)$/
    );
    if (matches && matches.length > 0) {
      continue;
    }

    // Check if save levels
    matches = row.match(/saves about level (\d+)$/);
    if (matches && matches.length > 0) {
      saveLevel = parseInt(matches[1]);
      continue;
    }
    matches = row.match(/saves always$/);
    if (matches && matches.length > 0) {
      saveLevel = 0;
      continue;
    }

    // Check if type
    matches = row.match(/is an (\w+)\.$/);
    if (matches && matches.length > 0) {
      name = matches[1].replace(/^The /, "");
      type = matches[1];
      continue;
    }
    matches = row.match(/^(.*) is a (\w+)\.$/);
    if (matches && matches.length > 0) {
      name = matches[1].replace(/^The /, "");
      type = matches[2];
      continue;
    }

    if (!type) {
      matches = row.match(/^(.*) is a (\w+) and (\w+)\.$/);
      if (matches && matches.length > 0) {
        name = matches[1].replace(/^The /, "");
        type = matches[2];
        continue;
      }
    }

    throw new Error(`Unknown line: ${row}`);
  }

  if (!type) {
    throw new Error("Type is missing, please include all judge values.");
  }

  if (!name) {
    throw new Error("Name is missing, please include all judge values.");
  }

  if (!saveLevel && saveLevel !== 0) {
    throw new Error("Save Level is missing, please include all judge values.");
  }

  if (type && armourTypes.indexOf(type) === -1) {
    throw new Error(`Unknown armour type '${type}'`);
  }

  results.total = total;

  const result = {
    ...item,
    name: item.name || name,
    type: item.type || type,
    judges: raws,
    values: results,
    saveLevel,
  };

  return result;
}
