import { useState, useCallback, useContext, useEffect } from "react";
import {
  Title,
  Card,
  Group,
  Center,
  Button,
  Alert,
  Avatar,
  Loader,
} from "@mantine/core";
import useAxios from "axios-hooks";
import { Link, useParams, useNavigate } from "react-router-dom";

import { ExclamationTriangleIcon } from "@modulz/radix-icons";

import EditForm from "../components/Lore/Edit";
import Details from "../components/Lore/Details";

import { ReactComponent as LoreLogo } from "../assets/Lore.svg";

import UserContext from "../utils/userContext";

import axios from "../utils/axios";

function LorePage() {
  const params = useParams();
  const navigate = useNavigate();

  const userState = useContext(UserContext);
  const [error, setError] = useState(null);
  const [item, setItem] = useState<any>(null);
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(true);

  const role = userState.user?.role || 0;

  const id = (params.id as string) || "none";

  //
  // Axios

  const [, getItem] = useAxios(
    {
      method: "GET",
    },
    {
      manual: true,
    }
  );

  const [, postItem] = useAxios(
    {
      url: "/api/guest/lore",
      method: "POST",
    },
    {
      manual: true,
    }
  );

  const [, deleteItem] = useAxios(
    {
      method: "DELETE",
    },
    {
      manual: true,
    }
  );

  //
  // Callbacks

  const doGetItem = useCallback(
    async (id: string) => {
      try {
        setLoading(true);
        const { data } = await getItem({
          url: `/api/public/lore/${id}`,
        });
        setItem(data);
        if (data.text === "") {
          setEditMode(true);
        }
        setLoading(false);
      } catch (error: any) {
        setError(error.response?.data?.error || error.message);
      }
    },
    [getItem]
  );

  const closeEditModal = useCallback(() => {
    setEditMode(false);
  }, []);

  const deleteLore = useCallback(
    async (item) => {
      setError(null);
      setLoading(true);

      try {
        await deleteItem({ url: `/api/guest/lore/${item._id}` });
        navigate("/lore");
      } catch (error: any) {
        setError(error.response?.data?.error || error.message);
        setLoading(false);
      }
    },
    [deleteItem, navigate]
  );

  const onUploadProgress = useCallback((progressEvent) => {
    if (progressEvent.type === "progress") {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      console.log("percentCompleted: ", percentCompleted);
    }
  }, []);

  const saveLore = useCallback(
    async (item) => {
      setError(null);
      setLoading(true);

      try {
        item._id = id;

        // Upload files?
        let files = item.files;

        let index = 0;
        for (const file of item.files) {
          if (file.delete) {
            console.log("Delete", file);

            const { data: result } = await axios.delete(
              `/api/guest/lore/upload/${id}`,
              {
                data: {
                  key: file.key,
                },
              }
            );

            files = files.filter((f: any) => f.key !== file.key);
            console.log("result: ", result);
          } else if (file.upload) {
            console.log("Upload", file);

            let formData = new FormData();
            formData.append("file", file.upload);
            const { data: result } = await axios.post(
              `/api/guest/lore/upload/${id}`,
              formData,
              {
                headers: {
                  "Content-Type": file.type,
                },
                onUploadProgress: onUploadProgress,
              }
            );
            console.log("Done!", result);

            const newFile = {
              name: file.name,
              key: result.key,
            };

            files = [...files];
            files[index] = newFile;
          }
          index++;
        }

        item.files = files;

        // if(item.text?.indexOf("\t") >= 0) {
        //   item.text = item.text.replace(/\t/g, "&nsbp;");
        // }

        const { data } = await postItem({ data: item });
        setItem(data);
      } catch (error: any) {
        setError(error.response?.data?.error || error.message);
      }

      setEditMode(false);
      setLoading(false);
    },
    [id, postItem, onUploadProgress]
  );

  const onImageUpload = useCallback(
    async (file: any): Promise<string> => {
      let formData = new FormData();
      formData.append("file", file);
      const { data: result } = await axios.post(
        `/api/guest/lore/upload/${id}`,
        formData,
        {
          headers: {
            "Content-Type": file.type,
          },
        }
      );
      console.log("Done!", result);

      // Update item
      return result.url;
    },
    [id]
  );

  //
  // Effects

  useEffect(() => {
    doGetItem(id);
  }, [id, doGetItem]);

  if (loading) {
    return (
      <div>
        <Card>
          <Center>
            <Loader />
          </Center>
        </Card>
      </div>
    );
  }

  return (
    <div>
      <Group position="apart">
        <Group>
          <Avatar color="blue" size="lg" radius="xl">
            <LoreLogo width={32} height={32} />
          </Avatar>
          <Title order={1}>{item.name}</Title>
        </Group>
        <Group>
          <Button component={Link} variant="outline" to="/lore">
            Back
          </Button>
          {role >= 1 && <Button onClick={() => setEditMode(true)}>Edit</Button>}
        </Group>
      </Group>

      {error && (
        <Alert
          mt="lg"
          icon={<ExclamationTriangleIcon />}
          title="Bummer!"
          color="red"
        >
          {error}
        </Alert>
      )}

      {item && !editMode && (
        <Details url="lore" item={item} hideButtons={true} />
      )}
      {item && editMode && (
        <EditForm
          item={item}
          onSave={saveLore}
          onDelete={deleteLore}
          onClose={closeEditModal}
          onImageUpload={onImageUpload}
        />
      )}
    </div>
  );
}

export default LorePage;
