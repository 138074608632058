import { Text, Button, Group } from "@mantine/core";
import { renderBadge } from "../../utils/userTools";
import { RichTextEditor } from "@mantine/rte";
import withItemLoader from "../withItemLoader";

interface FormProps {
  item: any;
  onClose: (item: any) => void;
  onEdit?: (item: any) => void;
}

function DetailsForm({ item, onClose, onEdit }: FormProps) {
  return (
    <div>
      <Text size="xs">Location</Text>
      <Text>{item.location || "Unknown"}</Text>

      <Group grow mt="xl">
        <Group direction="column" spacing="xs">
          <Text size="xs">Reward</Text>
          <Text>{(item.reward || []).join(", ")}</Text>
        </Group>

        <Group direction="column" spacing="xs">
          <Text size="xs">Minimum Level</Text>
          <Text>{item.level || item.level === 0 ? item.level : "Unknwon"}</Text>
        </Group>

        <Group direction="column" spacing="xs">
          <Text size="xs">Permission</Text>
          {renderBadge(item.role)}
        </Group>
      </Group>

      <Text mt="xl" mb="xs" size="xs">
        Solution
      </Text>
      <RichTextEditor
        controls={[
          ["bold", "italic", "underline"],
          ["unorderedList", "orderedList"],
        ]}
        value={item.solution}
        readOnly
        onChange={(e) => null}
      />

      <Group mt="xl">
        <Button onClick={onClose}>Close</Button>
        {onEdit && (
          <Button variant="outline" onClick={() => onEdit(item)}>
            Edit
          </Button>
        )}
      </Group>
    </div>
  );
}

export default withItemLoader(DetailsForm);