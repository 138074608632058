import { useCallback } from "react";
import { Group } from "@mantine/core";

import { ArrowDownIcon, ArrowUpIcon } from "@modulz/radix-icons";

interface FormProps {
  current: any;
  onChange: (item: any) => void;
  field: string;
  andThen?: string;
  children: any;
}

function TH({ onChange, current, field, children, andThen }: FormProps) {
  const isSelected = Object.keys(current)[0] === field;
  let isSelectedAsc = false;
  let isSelectedDesc = false;
  if(isSelected) {
    isSelectedAsc = current[field] === 1;
    isSelectedDesc = current[field] === -1;  
  }

  const onSortChange = useCallback(() => {
    let direction = 1;
    if (isSelectedAsc) direction = -1;
    let newSort = {
      [field]: direction,
    };
    if (andThen) {
      newSort = {
        ...newSort,
        [andThen]: direction,
      };
    }
    onChange(newSort);
  }, [field, onChange, isSelectedAsc, andThen]);

  return (
    <th className="pointer" onClick={onSortChange}>
      <Group>
        {isSelectedAsc && <ArrowUpIcon />}
        {isSelectedDesc && <ArrowDownIcon />}
        <span>{children}</span>
      </Group>
    </th>
  );
}

export default TH;
