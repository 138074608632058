import { useCallback, useContext } from "react";
import {
  TextInput,
  Button,
  Select,
  Group,
  Grid,
  Checkbox,
  Text,
} from "@mantine/core";
import { RichTextEditor } from "@mantine/rte";

import { useForm } from "@mantine/hooks";
import UserContext from "../../utils/userContext";
import { roleOptions } from "../../utils/userTools";

interface FormProps {
  item: any;
  onSave: (item: any, file?: any) => void;
  onDelete: (item: any) => void;
  onClose: (item: any) => void;
}

function Form({ item, onSave, onDelete, onClose }: FormProps) {
  const userState = useContext(UserContext);
  const role = userState.user?.role || 0;

  const form = useForm({
    initialValues: {
      name: item.name || "",
      role: item.role?.toString() || Math.min(role || 2, 2).toString(),
      text: item.text || "",
      sticky: item.sticky || false,
    },
  });
  const onDeleteClick = useCallback(() => {
    if (window.confirm("Are you sure you want to delete this item?")) {
      onDelete(item);
    }
  }, [item, onDelete]);

  return (
    <>
      <form onSubmit={form.onSubmit(onSave)}>
        <TextInput
          required
          label="Title"
          placeholder="Enter a title"
          {...form.getInputProps("name")}
        />

        <Grid mt="md">
          <Grid.Col xs={6}>
            <br />
            <Checkbox
              mt="xs"
              label="Sticky"
              {...form.getInputProps("sticky", { type: "checkbox" })}
            />
          </Grid.Col>
          <Grid.Col xs={6}>
            <Select
              required
              label="Role"
              placeholder="Select a role"
              {...form.getInputProps("role")}
              data={roleOptions.slice(0, role + 1)}
            />
          </Grid.Col>
        </Grid>

        <Text mt="lg" size="sm" mb="xs">
          Text
        </Text>
        <RichTextEditor
          styles={{
            root: {
              p: {
                marginBottom: 4,
              },
            },
          }}
          controls={[
            ["bold", "italic", "underline"],
            ["unorderedList", "orderedList"],
            ["link"],
          ]}
          value={form.values["text"]}
          onChange={(e) => form.setFieldValue("text", e)}
        />

        <Group mt="xl">
          <Button type="submit">Save</Button>
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          {item._id && (
            <Button
              variant="outline"
              ml="xl"
              color="red"
              onClick={onDeleteClick}
            >
              Delete
            </Button>
          )}
        </Group>
      </form>
    </>
  );
}

export default Form;
