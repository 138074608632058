import { useState, useCallback, useContext, useEffect } from "react";
import {
  Title,
  Card,
  Group,
  Modal,
  Center,
  Button,
  Alert,
  Avatar,
  Loader,
  Text,
  MantineTheme,
  CSSObject,
} from "@mantine/core";
import useAxios from "axios-hooks";
import { Link, useParams, useNavigate } from "react-router-dom";

import { ExclamationTriangleIcon } from "@modulz/radix-icons";

import Form from "../components/Logs/Form";
import Details from "../components/Logs/Details";

import { ReactComponent as LogsLogo } from "../assets/Logs.svg";

import UserContext from "../utils/userContext";

const logStyles = (theme: MantineTheme): CSSObject => ({
  backgroundColor: "#000",
  color: "#FFF",
  fontFamily: "Courier, monospace",
  fontSize: 14,
//  fontWeight: "bold",
  // whiteSpace: "pre"
});

function LorePage() {
  const params = useParams();
  const navigate = useNavigate();

  const userState = useContext(UserContext);
  const [error, setError] = useState(null);
  const [item, setItem] = useState<any>(null);
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(true);

  const role = userState.user?.role || 0;

  const id = (params.id as string) || "none";

  //
  // Axios

  const [, getItem] = useAxios(
    {
      method: "GET",
    },
    {
      manual: true,
    }
  );

  const [, postItem] = useAxios(
    {
      url: "/api/guest/logs",
      method: "POST",
    },
    {
      manual: true,
    }
  );

  const [, deleteItem] = useAxios(
    {
      method: "DELETE",
    },
    {
      manual: true,
    }
  );

  //
  // Callbacks

  const doGetItem = useCallback(
    async (id: string) => {
      try {
        setLoading(true);
        const { data } = await getItem({
          url: `/api/public/logs/${id}`,
        });
        if(data.log) {
          data.log = data.log.replace(/#000000/g, "#21d5b4")
          data.log = data.log.replace(/Courier New/g, "Courier")  
        }
        setItem(data);
      } catch (error: any) {
        setError(error.response?.data?.error || error.message);
      }
      setLoading(false);
    },
    [getItem]
  );
  

  const closeEditModal = useCallback(() => {
    setEditMode(false);
  }, []);

  const deleteLog = useCallback(
    async (item) => {
      setError(null);
      setLoading(true);

      try {
        await deleteItem({ url: `/api/guest/logs/${item._id}` });
        navigate("/logs");
      } catch (error: any) {
        setError(error.response?.data?.error || error.message);
        setLoading(false);
      }
    },
    [deleteItem, navigate]
  );

  const saveLog = useCallback(
    async (item) => {
      setError(null);
      setLoading(true);

      try {
        // Need to add id
        item._id = id;
        delete item.file;
        await postItem({ data: item });
        doGetItem(id);
      } catch (error: any) {
        setError(error.response?.data?.error || error.message);
      }

      setEditMode(false);
      setLoading(false);
    },
    [id, postItem, doGetItem]
  );

  //
  // Effects

  useEffect(() => {
    doGetItem(id);
  }, [id, doGetItem]);

  if (error) {
    return (
      <div>
        <Card>
          <Alert
            mt="lg"
            icon={<ExclamationTriangleIcon />}
            title="Bummer!"
            color="red"
          >
            {error}
          </Alert>
        </Card>
      </div>
    );
  }

  if (loading) {
    return (
      <div>
        <Card>
          <Center>
            <Loader />
          </Center>
        </Card>
      </div>
    );
  }

  return (
    <div>
      <Group position="apart">
        <Group>
          <Avatar color="blue" size="lg" radius="xl">
            <LogsLogo width={32} height={32} />
          </Avatar>
          <Title order={1}>{item.name}</Title>
        </Group>
        <Group>
          <Button component={Link} variant="outline" to="/logs">
            Back to Logs
          </Button>
          {role >= 1 && <Button onClick={() => setEditMode(true)}>Edit</Button>}
        </Group>
      </Group>

      <Card withBorder mt="lg">
        {item && <Details item={item} hideButtons={true} />}
      </Card>

      <Card withBorder mt="lg" sx={logStyles}>
        {!item.log && <Text>No log found</Text>}
        <div dangerouslySetInnerHTML={{ __html: item.log }} />
      </Card>

      <Modal
        size="lg"
        padding="xl"
        opened={editMode}
        onClose={closeEditModal}
        title="Edit Log"
      >
        {editMode && (
          <Form
            item={item}
            hideUpload={true}
            onSave={saveLog}
            onDelete={deleteLog}
            onClose={closeEditModal}
          />
        )}
      </Modal>
    </div>
  );
}

export default LorePage;
