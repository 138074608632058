import {
  Title,
  Card,
  Group,
  Loader,
  Center,
  Button,
  Alert,
  Avatar,
  Anchor,
} from "@mantine/core";
import useAxios from "axios-hooks";
import { Link } from "react-router-dom";
import { ExclamationTriangleIcon } from "@modulz/radix-icons";
import { ReactComponent as ArmourSets } from "../assets/ArmourSets.svg";
import { renderBadge } from "../utils/userTools";
import ResponsiveTable from "../components/Layout/ResponsiveTable";

function Sets() {
  const [{ data, loading, error: loadError }] = useAxios("/api/templar/sets");

  return (
    <div>
      <Group position="apart">
        <Group>
          <Avatar color="blue" size="lg" radius="xl">
            <ArmourSets width={32} height={32} />
          </Avatar>
          <Title order={1}>Armour Sets</Title>
        </Group>
        <Group>
          <Button component={Link} to="/setbuilder">
            Create
          </Button>
        </Group>
      </Group>

      {loadError && (
        <Alert
          mt="lg"
          icon={<ExclamationTriangleIcon />}
          title="Bummer!"
          color="red"
        >
          {loadError?.response?.data?.error || loadError?.message}
        </Alert>
      )}

      <Card withBorder mt="lg">
        {loading ? (
          <Center>
            <Loader />
          </Center>
        ) : (
          <ResponsiveTable verticalSpacing="xs" striped>
            <thead>
              <tr>
                <th>Name</th>
                <th>Creator</th>
                <th>Permission</th>
              </tr>
            </thead>
            <tbody>
              {data?.items.length === 0 && (
                <tr>
                  <td colSpan={3}>No sets</td>
                </tr>
              )}
              {data?.items.map((i: any) => (
                <tr key={i._id}>
                  <td>
                    <Anchor component={Link} to={`/setbuilder/${i._id}`}>
                      {i.name || "no name"}
                    </Anchor>
                  </td>
                  <td>{i.creator?.playerName}</td>
                  <td>{renderBadge(i.role)}</td>
                </tr>
              ))}
            </tbody>
          </ResponsiveTable>
        )}
      </Card>
    </div>
  );
}

export default Sets;
