import { Text, Button, Group, Grid, Box, ActionIcon } from "@mantine/core";
import { useClipboard } from "@mantine/hooks";
import { Copy } from "tabler-icons-react";
import { renderBadge } from "../../utils/userTools";
import moment from "moment";
import { useCallback, useEffect } from "react";
import { showNotification } from '@mantine/notifications';

interface FormProps {
  item: any;
  onClose?: (item: any) => void;
  onEdit?: (item: any) => void;
}

function DetailsForm({ item, onClose, onEdit }: FormProps) {
  const clipboard = useClipboard({ timeout: 500 });

  const url =
    document.location.hostname !== "localhost"
      ? `http://rod.drugges.se/invitation/${item._id}`
      : `http://localhost:3000/invitation/${item._id}`;

  const copyClick = useCallback(() => {
    clipboard.copy(url);
  }, [url, clipboard]);

  useEffect(() => {
    if (clipboard.copied) {
      showNotification({
        title: "Copied",
        message: "The URL has been copied.",
      });
    }
  }, [clipboard.copied]);

  return (
    <div>
      <Grid mt="lg">
        <Grid.Col xs={6}>
          <Group direction="column" spacing="xs">
            <Text size="xs">Player Name</Text>
            <Text>{item.playerName}</Text>
          </Group>
        </Grid.Col>
        <Grid.Col xs={6}>
          <Group direction="column" spacing="xs">
            <Text size="xs">Role</Text>
            {renderBadge(item.role)}
          </Group>
        </Grid.Col>
        <Grid.Col xs={6}>
          <Group direction="column" spacing="xs">
            <Text size="xs">Created by</Text>
            <Text>{item.creator?.playerName}</Text>
          </Group>
        </Grid.Col>
        <Grid.Col xs={6}>
          <Group direction="column" spacing="xs">
            <Text size="xs">Created</Text>
            <Text>{moment(item.created).format("D MMM YYYY")}</Text>
          </Group>
        </Grid.Col>
      </Grid>

      <Group direction="column" spacing="xs" mt="lg">
        <Text size="xs">URL</Text>
        <Box>
          <Group>
            <Text>{url}</Text>
            <ActionIcon onClick={copyClick}>
              <Copy size={24} />
            </ActionIcon>
          </Group>
        </Box>
      </Group>

      <Group mt="xl">
        <Button onClick={onClose}>Close</Button>
        {onEdit && (
          <Button variant="outline" onClick={() => onEdit(item)}>
            Edit
          </Button>
        )}
      </Group>
    </div>
  );
}

export default DetailsForm;
