export const weaponTypes = [
  "fighting.offensive.melee.blunt",
  "fighting.offensive.melee.unarmed",
  "fighting.offensive.melee.sharp",  
];

export const limbs = ["One handed", "Two handed", "Both"];

export const elements = [
  "blunt",
  "sharp",
  "pierce",
  "acid",
  "cold",
  "electricity",
  "fire",
  "magic",
  "poison",
  "psionic",
];

export function toScore(value: string): number {
  switch (value) {
    case "exceptional":
      return 10;
    case "excellent":
      return 9;
    case "very good":
      return 8;
    case "good":
      return 7;
    case "pretty good":
      return 6;
    case "okay":
      return 5;
    case "fair":
      return 4;
    case "poor":
      return 3;
    case "pretty poor":
      return 2;
    case "very poor":
      return 1;
  }
  return 0;
}

export function judgeRows(raws: string[], item: any = {}) {
  let type = null;
  let name = null;
  let saveLevel: null | number = null;

  for (let i = 0; i < raws.length; i++) {
    const row = raws[i];

    // Check if save levels

    let matches = row.match(/at doing damage\.$/);
    if (matches && matches.length > 0) {
      continue;
    }

    matches = row.match(/(\w+) at resisting damage\.$/);
    if (matches && matches.length > 0) {
      continue;
    }

    matches = row.match(/(\w+) at absorbing damage\.$/);
    if (matches && matches.length > 0) {
      continue;
    }

    matches = row.match(/saves about level (\d+)$/);
    if (matches && matches.length > 0) {
      saveLevel = parseInt(matches[1]);
      continue;
    }

    matches = row.match(/saves always$/);
    if (matches && matches.length > 0) {
      saveLevel = 0;
      continue;
    }

    // Check if type
    // The Sword of Justice uses the fighting.offensive.melee.sharp skill.
    matches = row.match(/^The (.*) uses the (.*) skill\.$/);
    if (matches && matches.length > 0) {
      name = matches[1];
      type = matches[2];
      continue;
    }

    throw new Error(`Unknown line: ${row}`);
  }

  if (!type) {
    throw new Error("Type is missing, please include all judge values.");
  }

  if (!name) {
    throw new Error("Name is missing, please include all judge values.");
  }

  if (saveLevel === null) {
    throw new Error("Save Level is missing, please include all judge values.");
  }

  if (!type) {
    throw new Error(`Unknown weapon type '${type}'`);
  }

  const result = {
    ...item,
    name: item.name || name,
    type: item.type || type,
    judges: raws.filter(r => r),
    saveLevel,
  };

  return result;
}
