import { useCallback, useContext } from "react";
import {
  TextInput,
  Button,
  Select,
  NumberInput,
  Group,
  SimpleGrid,
} from "@mantine/core";

import { useForm } from "@mantine/form";
import { weaponTypes, limbs } from "../../utils/weaponTools";
import UserContext from "../../utils/userContext";
import { roleOptions } from "../../utils/userTools";

interface FormProps {
  item: any;
  onSave: (item: any) => void;
  onConfigure: (item: any) => void;
  onDelete: (item: any) => void;
  onClose: (item: any) => void;
}

const weaponTypeOptions = weaponTypes.map((i) => {
  const split = i.split(".");
  return {
    value: i,
    label: split[split.length - 1],
  };
});


function DetailsForm({
  item,
  onSave,
  onConfigure,
  onDelete,
  onClose,
}: FormProps) {
  const userState = useContext(UserContext);

  const role = userState.user?.role || 0;

  const initial = {
    name: item.name || "",
    type: item.type || "",
    location: item.location || "",
    saveLevel: item.saveLevel,
    notes: item.notes || "",
    limbs: item.limbs,
    role: item.role?.toString() || Math.min(role || 2, 2).toString(),
  };

  const form = useForm({
    initialValues: initial,
    validate: (values) => ({
      limbs: !values.limbs ? "Please select number of limbs" : null,
    }),
  });

  const onConfigureClick = useCallback(() => {
    const result = form.values;
    const newItem = {
      ...item,
      ...result,
    };
    onConfigure(newItem);
  }, [item, form, onConfigure]);

  const onSubmit = useCallback(
    (values: typeof form["values"]) => {
      const newItem = {
        ...item,
        ...values,
      };
      onSave(newItem);
    },
    [item, onSave]
  );

  const onDeleteClick = useCallback(() => {
    if (window.confirm("Are you sure you want to delete this item?")) {
      onDelete(item);
    }
  }, [item, onDelete]);

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <TextInput
        required
        label="Name"
        placeholder="Weapon name"
        {...form.getInputProps("name")}
      />

      <TextInput
        mt="lg"
        label="Notes"
        placeholder="Optional notes about the weapon"
        {...form.getInputProps("notes")}
      />

      <SimpleGrid cols={2} mt="lg" breakpoints={[{ maxWidth: "sm", cols: 1 }]}>
        <Select
          required
          label="Weapon Type"
          placeholder="Select weapon type"
          data={weaponTypeOptions}
          {...form.getInputProps("type")}
        />
        <Select
          required
          label="Limbs"
          placeholder="Select number of hands"
          data={limbs}
          {...form.getInputProps("limbs")}
        />
        <NumberInput
          required={true}
          label="Save level"
          {...form.getInputProps("saveLevel")}
        />
        <Select
          required
          label="Permission"
          placeholder="Select a role"
          {...form.getInputProps("role")}
          data={roleOptions.slice(0, userState.user.role + 1)}
        />
      </SimpleGrid>

      <TextInput
        mt="lg"
        label="Location"
        placeholder="eg: Ice Mage, Ravens"
        {...form.getInputProps("location")}
      />

      <Group mt="xl">
        <Button type="submit">Save</Button>
        <Button variant="outline" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={onConfigureClick} variant="light">
          Configure
        </Button>
        {item._id && (
          <Button variant="outline" ml="xl" color="red" onClick={onDeleteClick}>
            Delete
          </Button>
        )}
      </Group>
    </form>
  );
}

export default DetailsForm;
