import { Text, Button, Group, Grid } from "@mantine/core";
import { renderBadge } from "../../utils/userTools";
import moment from "moment";

interface FormProps {
  item: any;
  hideButtons: boolean;
  onClose?: (item: any) => void;
  onEdit?: (item: any) => void;
}

function DetailsForm({ item, onClose, onEdit, hideButtons }: FormProps) {
  return (
    <div>
      <Group direction="column" spacing="xs">
        <Text size="xs">Description</Text>
        <Text>{item.description}</Text>
      </Group>
      <Grid mt="lg">
        <Grid.Col xs={3}>
          <Group direction="column" spacing="xs">
            <Text size="xs">Type</Text>
            <Text>{item.type}</Text>
          </Group>
        </Grid.Col>
        <Grid.Col xs={3}>
          <Group direction="column" spacing="xs">
            <Text size="xs">Permission</Text>
            {renderBadge(item.role)}
          </Group>
        </Grid.Col>
        <Grid.Col xs={3}>
          <Group direction="column" spacing="xs">
            <Text size="xs">Created by</Text>
            <Text>{item.creator?.playerName}</Text>
          </Group>
        </Grid.Col>
        <Grid.Col xs={3}>
          <Group direction="column" spacing="xs">
            <Text size="xs">Created</Text>
            <Text>{moment(item.created).format("D MMM YYYY")}</Text>
          </Group>
        </Grid.Col>
      </Grid>

      {!hideButtons && (
        <Group mt="xl">
          <Button onClick={onClose}>Close</Button>
          {onEdit && (
            <Button variant="outline" onClick={() => onEdit(item)}>
              Edit
            </Button>
          )}
        </Group>
      )}
    </div>
  );
}

export default DetailsForm;
