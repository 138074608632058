import { useForm } from "@mantine/hooks";
import {
  TextInput,
  Button,
  Card,
  PasswordInput,
  Title,
  Container,
  Alert,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { useCallback, useContext, useState } from "react";
import { ExclamationTriangleIcon } from "@modulz/radix-icons";
import useAxios from "axios-hooks";
import UserContext from "../utils/userContext";

function LoginForm() {
  const { setUser } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const [, login] = useAxios(
    {
      url: "/api/public/users/login",
      method: "POST",
    },
    {
      manual: true,
    }
  );

  const form = useForm({
    initialValues: {
      playerName: "",
      password: "",
    },
  });

  const onSubmit = useCallback(
    async (values) => {
      setError(null);
      setLoading(true);

      try {
        const { data: result } = await login({ data: values });
        if (!result) {
          throw Error("Login failed");
        }
        setUser(result);
        if (document.location.pathname === "/login") {
          navigate("/");
        }
      } catch (err: any) {
        setError(err.response?.data?.error || err.message);
      }

      setLoading(false);
    },
    [login, setUser, navigate]
  );

  return (
    <Container size="xs">
      <Card title="Login" p={40}>
        <Title order={4}>Login</Title>
        <form onSubmit={form.onSubmit(onSubmit)}>
          <TextInput
            mt="lg"
            required
            label="Player Name"
            {...form.getInputProps("playerName")}
          />

          <PasswordInput
            mt="lg"
            required
            label="Password"
            {...form.getInputProps("password")}
          />

          {error && (
            <Alert
              mt="lg"
              icon={<ExclamationTriangleIcon />}
              title="Bummer!"
              color="red"
            >
              {error}
            </Alert>
          )}

          <Button loading={loading} mt="xl" type="submit">
            Login
          </Button>
        </form>
      </Card>
    </Container>
  );
}

export default LoginForm;
