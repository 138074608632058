import { useContext, useCallback } from "react";
import {
  Group,
  ActionIcon,
  Anchor,
  MantineTheme,
  CSSObject,
  Divider,
  Box,
} from "@mantine/core";
import { ExitIcon } from "@modulz/radix-icons";
import { Link, useNavigate } from "react-router-dom";

import UserContext from "../../utils/userContext";
import { renderBadge } from "../../utils/userTools";
import { Action } from "../../types";

const groupStyles = (theme: MantineTheme): CSSObject => ({
  textAlign: "left",
  borderRadius: 10,
  padding: 10,
  width: "100%",
});

interface CustomProps {
  onClick: Action<boolean>;
}

function UserProfile({ onClick }: CustomProps) {
  const { user, setUser } = useContext(UserContext);
  const navigate = useNavigate();

  const onLogoutClick = useCallback(() => {
    setUser(null);
    onClick(false);
    navigate("/");
  }, [setUser, onClick, navigate]);

  const closeMenu = useCallback(() => {
    onClick(false);
  }, [onClick]);

  if (user) {
    return (
      <>
        <Divider mt="xl" />
        <Group sx={groupStyles} grow>
          <Anchor component={Link} to="/profile" onClick={closeMenu}>
            {user.playerName}
          </Anchor>
          <ActionIcon onClick={onLogoutClick} title="Logout">
            <ExitIcon width={18} height={18} />
          </ActionIcon>
        </Group>

        <Box pl={10}>{renderBadge(user.role)}</Box>
      </>
    );
  } else {
    return null;
  }
}

export default UserProfile;
