import { Badge } from "@mantine/core";

export const roles = ["Everyone", "Guest", "Templar", "Super Templar", "God"];
const roleColors = ["green", "yellow", "blue", "red", "dark"];

export const roleOptions = [
  {
    label: "Everyone",
    value: "0",
  },
  {
    label: "Guest",
    value: "1",
  },
  {
    label: "Templar",
    value: "2",
  },
  {
    label: "Super Templar",
    value: "3",
  },
  {
    label: "God",
    value: "4",
  },
];

export function getRoleOptions(role: number, me: boolean) {
  const base = [...roleOptions].slice(0, role + 1);
  if(me) {
    base.unshift({
      label: "Me only",
      value: "10",
    });
  }
  return base;
}

export function renderBadge(role: number) {
  if(role === 10) {
    return <Badge color="black">You only</Badge>;
  }
  return <Badge color={roleColors[role]}>{roles[role]}</Badge>;
}
