import { useState, useCallback } from "react";
import {
  Title,
  Card,
  Group,
  Loader,
  ActionIcon,
  Modal,
  Center,
  Button,
  LoadingOverlay,
  Alert,
  Avatar,
} from "@mantine/core";
import useAxios from "axios-hooks";
import { Pencil2Icon, ExclamationTriangleIcon, CheckIcon } from "@modulz/radix-icons";
import Form from "../components/Users/Form";
import { ReactComponent as UsersLogo } from "../assets/Users.svg";
import { renderBadge } from "../utils/userTools";
import ResponsiveTable from "../components/Layout/ResponsiveTable";

function Armours() {
  const [{ data, loading, error: loadError }, reloadItems] = useAxios(
    "/api/supertemplar/users"
  );
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);

  const [, postItem] = useAxios(
    {
      url: "/api/supertemplar/users",
      method: "POST",
    },
    {
      manual: true,
    }
  );

  const [, deleteItem] = useAxios(
    {
      method: "DELETE",
    },
    {
      manual: true,
    }
  );

  const [modal, setModal] = useState<any>(null);

  const editUser = useCallback(
    (id) => {
      if (id === null) {
        setModal({});
      } else {
        const item = data.items.find((i: any) => i._id === id);
        setModal({ ...item });
      }
    },
    [data]
  );

  const closeModal = useCallback(() => {
    setModal(null);
  }, []);

  const deleteUser = useCallback(async () => {
    if (!window.confirm("Are you sure you want to delete this user?")) {
      return;
    }

    if (modal) {
      setError(null);
      setSaving(true);
      setModal(null);

      try {
        await deleteItem({ url: `/api/supertemplar/users/${modal._id}` });
      } catch (error: any) {
        setError(error.message);
      }
      await reloadItems();
      setSaving(false);
    }
  }, [deleteItem, reloadItems, modal]);

  const saveUser = useCallback(
    async (item) => {
      setError(null);
      setSaving(true);
      setModal(null);

      try {
        const newItem = {
          ...modal,
          ...item,
        };
        await postItem({ data: newItem });
      } catch (error: any) {
        setError(error.message);
      }

      await reloadItems();
      setSaving(false);
    },
    [postItem, reloadItems, modal]
  );

  return (
    <div>
      <LoadingOverlay visible={saving} />
      <Group position="apart">
        <Group>
          <Avatar color="blue" size="lg" radius="xl">
            <UsersLogo width={32} height={32} />
          </Avatar>
          <Title order={1}>Users</Title>
        </Group>
        <Group>
          <Button onClick={() => editUser(null)}>Create</Button>
        </Group>
      </Group>

      {(error || loadError) && (
        <Alert
          mt="lg"
          icon={<ExclamationTriangleIcon />}
          title="Bummer!"
          color="red"
        >
          {error || loadError?.response?.data?.error || loadError?.message}
        </Alert>
      )}

      <Card withBorder mt="lg">
        {loading ? (
          <Center>
            <Loader />
          </Center>
        ) : (
          <ResponsiveTable verticalSpacing="xs" striped>
            <thead>
              <tr>
                <th className="min">Active</th>
                <th>Player</th>
                <th>Name</th>
                <th>Role</th>
                <th className="min"></th>
              </tr>
            </thead>
            <tbody>
              {data?.items.map((i: any) => (
                <tr key={i._id}>
                  <td>
                    <Center>{i.active && <CheckIcon />}</Center>
                  </td>
                  <td>{i.playerName}</td>
                  <td>
                    {i.firstName} {i.lastName}
                  </td>
                  <td>{renderBadge(i.role)}</td>
                  <td>
                    <ActionIcon onClick={() => editUser(i._id)}>
                      <Pencil2Icon />
                    </ActionIcon>
                  </td>
                </tr>
              ))}
            </tbody>
          </ResponsiveTable>
        )}
      </Card>
      <Modal
        size="lg"
        padding="xl"
        opened={!!modal}
        onClose={closeModal}
        title="User"
      >
        {modal && <Form item={modal} onSave={saveUser} onDelete={deleteUser} />}
      </Modal>
    </div>
  );
}

export default Armours;
