import { useCallback, useContext } from "react";
import {
  TextInput,
  Button,
  Select,
  Group,
  JsonInput,
  Grid,
} from "@mantine/core";
import { useForm } from "@mantine/hooks";
import UserContext from "../../utils/userContext";
import { roleOptions } from "../../utils/userTools";

import withItemLoader from "../withItemLoader";

interface FormProps {
  item: any;
  onSave: (item: any) => void;
  onDelete: (item: any) => void;
  onClose: (item: any) => void;
}

function Form({ item, onSave, onDelete, onClose }: FormProps) {
  const userState = useContext(UserContext);
  const role = userState.user?.role || 0;

  const form = useForm({
    initialValues: {
      name: item.name || "",
      tree: item.tree || "",
      role: item.role?.toString() || Math.min(role || 2, 2).toString(),
    },
  });

  const onSubmit = useCallback(
    (values: typeof form["values"]) => {
      onSave(values);
    },
    [onSave]
  );

  const onDeleteClick = useCallback(() => {
    if (window.confirm("Are you sure you want to delete this item?")) {
      onDelete(item);
    }
  }, [item, onDelete]);

  return (
    <>
      <form onSubmit={form.onSubmit(onSubmit)}>
        <Grid mt="lg">
          <Grid.Col span={12} xs={8}>
            <TextInput
              required
              label="Name"
              placeholder="Guild name"
              {...form.getInputProps("name")}
            />
          </Grid.Col>
          <Grid.Col span={6} xs={4}>
            <Select
              required
              label="Permission"
              placeholder="Select a role"
              {...form.getInputProps("role")}
              data={roleOptions.slice(0, role + 1)}
            />
          </Grid.Col>
        </Grid>

        <JsonInput
          mt="lg"
          label="Skill Tree"
          placeholder="Enter skill tree data"
          validationError="Invalid json"
          formatOnBlur
          autosize
          minRows={4}
          {...form.getInputProps("tree")}
        />

        <Group mt="xl">
          <Button onClick={form.onSubmit(onSave)}>Save</Button>
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          {item._id && (
            <Button
              variant="outline"
              ml="xl"
              color="red"
              onClick={onDeleteClick}
            >
              Delete
            </Button>
          )}
        </Group>
      </form>
    </>
  );
}

export default withItemLoader(Form);
