import { Table, createStyles } from "@mantine/core";

// [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
// },

const useStyles = createStyles((theme) => ({
  container: {
    // Media query with value from theme
    overflowX: "auto",
  },
}));

function ResponsiveTable(props: any) {
  const { classes } = useStyles();
  const { children, ...rest } = props;
  return (
    <div className={classes.container}>
      <Table {...rest}>{children}</Table>
    </div>
  );
}

export default ResponsiveTable;
