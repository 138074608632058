import {
  HeaderProps,
  Burger,
  Header,
  MediaQuery,
  Text,
  Group,
  useMantineTheme,
  MantineTheme,
  CSSObject,
} from "@mantine/core";

import { Action } from "../../types";

import TemplarLogo from "../../assets/TemplarLogo.png";

interface CustomHeaderProps extends Omit<HeaderProps, "children"> {
  opened: boolean;
  setOpened: Action<boolean>;
}

const headerStyles = (theme: MantineTheme): CSSObject => ({
  borderBottom: `1px solid ${
    theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.gray[2]
  }`,
  color: theme.white,
  backgroundColor:
    theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.colors.blue[8],
  borderBottomColor: theme.black,
});

function CustomHeader(props: CustomHeaderProps) {
  const { opened, setOpened, ...rest } = props;
  const theme = useMantineTheme();

  return (
    <Header {...rest} p="md" sx={headerStyles}>
      <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
        <MediaQuery largerThan="sm" styles={{ display: "none" }}>
          <Burger
            opened={opened}
            onClick={() => setOpened((o) => !o)}
            size="sm"
            color={theme.white}
            mr="xl"
          />
        </MediaQuery>
        <Group>
          <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
            <img src={TemplarLogo} height="70" alt="Logo" />
          </MediaQuery>
          <Text size="xl" weight="bold">
            DRAGON TEMPLARS
          </Text>
        </Group>
      </div>
    </Header>
  );
}

export default CustomHeader;
