import { useCallback, useContext } from "react";
import { TextInput, Button, Select, Group, Textarea } from "@mantine/core";
import { useForm } from "@mantine/hooks";
import UserContext from "../../utils/userContext";
import { roleOptions } from "../../utils/userTools";

interface FormProps {
  item: any;
  onSave: (item: any) => void;
  onDelete: (item: any) => void;
  onClose: (item: any) => void;
}


function Form({ item, onSave, onDelete, onClose }: FormProps) {
  const userState = useContext(UserContext);
  const role = userState.user?.role || 0;

  const form = useForm({
    initialValues: {
      name: item.name || "",
      description: item.description || "",
      role: item.role?.toString() || Math.min(role || 2, 2).toString(),
    },
  });

  const onDeleteClick = useCallback(() => {
    if (window.confirm("Are you sure you want to delete this item?")) {
      onDelete(item);
    }
  }, [item, onDelete]);

  return (
    <form onSubmit={form.onSubmit(onSave)}>
      <TextInput
        required
        label="Name"
        placeholder="Armour name"
        {...form.getInputProps("name")}
      />

      <Select
        mt="lg"
        required
        label="Permission"
        placeholder="Select a role"
        {...form.getInputProps("role")}
        data={roleOptions.slice(0, role + 1)}
      />

      <Textarea
        mt="lg"
        label="Description"
        placeholder="Information about save level etc"
        {...form.getInputProps("description")}
      />

      <Group mt="xl">
        <Button type="submit">Save</Button>
        <Button variant="outline" onClick={onClose}>Cancel</Button>
        {item._id && (
          <Button variant="outline" ml="md" color="red" onClick={onDeleteClick}>
            Delete
          </Button>
        )}
      </Group>
    </form>
  );
}

export default Form;
