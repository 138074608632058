import {
  Title,
  Text,
  Group,
  Avatar,
  Card,
  Grid,
  Center,
  Loader,
  Alert,
} from "@mantine/core";
import { useNotifications } from "@mantine/notifications";

import { ExclamationTriangleIcon } from "@modulz/radix-icons";
import useAxios from "axios-hooks";
import { useCallback, useContext, useState } from "react";
import { ReactComponent as UsersLogo } from "../assets/Users.svg";
import Form from "../components/Profile/Form";
import UserContext from "../utils/userContext";

function Profile() {
  const [{ loading, data }] = useAxios("/api/guest/users/me/stats");
  const userState = useContext(UserContext);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);
  const notifications = useNotifications();

  const [, postItem] = useAxios(
    {
      url: "/api/guest/users/me",
      method: "POST",
    },
    {
      manual: true,
    }
  );

  const saveUser = useCallback(
    async (values) => {
      setError(null);
      setSaving(true);

      try {
        const { data } = await postItem({ data: values });
        userState.updateUser(data);
        notifications.showNotification({
          message: "User saved!",
        });
      } catch (error: any) {
        setError(error.response?.data?.error || error.message);
      }

      setSaving(false);
    },
    [postItem, userState, notifications]
  );

  return (
    <div>
      <Group>
        <Avatar color="red" size="md" radius="xl">
          <UsersLogo width={24} height={24} />
        </Avatar>
        <Title order={1}>My Profile</Title>
      </Group>

      {error && (
        <Alert
          mt="lg"
          icon={<ExclamationTriangleIcon />}
          title="Bummer!"
          color="red"
        >
          {error}
        </Alert>
      )}

      <Card withBorder mt="xl">
        <Form item={userState.user} saving={saving} onSave={saveUser} />
      </Card>

      {loading && (
        <Center>
          <Loader />
        </Center>
      )}

      {data && (
        <Grid>
          <Grid.Col sm={4}>
            <Card withBorder mt="lg">
              <Text>You have created {data.armourCount} armours.</Text>
            </Card>
          </Grid.Col>
          <Grid.Col sm={4}>
            <Card withBorder mt="lg">
              <Text>You have created {data.questCount} quests.</Text>
            </Card>
          </Grid.Col>
          <Grid.Col sm={4}>
            <Card withBorder mt="lg">
              <Text>You have created {data.loreCount} lore pages.</Text>
            </Card>
          </Grid.Col>
        </Grid>
      )}
    </div>
  );
}

export default Profile;
