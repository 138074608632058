import { useCallback, useContext } from "react";
import { TextInput, Button, Group, Grid, Select } from "@mantine/core";

import { useForm } from "@mantine/hooks";
import { roleOptions } from "../../utils/userTools";
import UserContext from "../../utils/userContext";

interface FormProps {
  item: any;
  onSave: (item: any, file?: any) => void;
  onDelete: (item: any) => void;
  onClose: (item: any) => void;
}

function Form({ item, onSave, onDelete, onClose }: FormProps) {
  const userState = useContext(UserContext);
  const role = userState.user?.role || 0;

  const form = useForm({
    initialValues: {
      name1: item.names[0] || "",
      name2: item.names[1] || "",
      name3: item.names[2] || "",
      name4: item.names[3] || "",
      notes: item.notes || "",
      role: item.role?.toString() || "2",
    },
  });
  const onDeleteClick = useCallback(() => {
    if (window.confirm("Are you sure you want to delete this item?")) {
      onDelete(item);
    }
  }, [item, onDelete]);

  const onSaveClick = useCallback(
    (values) => {
      item.names = [];
      item.role = 2;
      if (values.name1) {
        item.names.push(values.name1.toLowerCase());
      }
      if (values.name2) {
        item.names.push(values.name2.toLowerCase());
      }
      if (values.name3) {
        item.names.push(values.name3.toLowerCase());
      }
      if (values.name4) {
        item.names.push(values.name4.toLowerCase());
      }
      item.notes = values.notes;
      item.role = values.role;
      onSave(item);
    },    
    [item, onSave]
  );

  return (
    <>
      <form onSubmit={form.onSubmit(onSaveClick)}>
        <Grid mt="md">
          <Grid.Col xs={6}>
            <TextInput
              required
              label="Player Name 1"
              placeholder="Enter a name"
              {...form.getInputProps("name1")}
            />
          </Grid.Col>
          <Grid.Col xs={6}>
            <TextInput
              label="Player Name 2"
              placeholder="Enter a name"
              {...form.getInputProps("name2")}
            />
          </Grid.Col>
          <Grid.Col xs={6}>
            <TextInput
              label="Player Name 3"
              placeholder="Enter a name"
              {...form.getInputProps("name3")}
            />
          </Grid.Col>
          <Grid.Col xs={6}>
            <TextInput
              label="Player Name 4"
              placeholder="Enter a name"
              {...form.getInputProps("name4")}
            />
          </Grid.Col>
          <Grid.Col xs={6}>
            <Select
              required
              label="Role"
              placeholder="Select a role"
              {...form.getInputProps("role")}
              data={roleOptions.slice(0, role + 1)}
            />
          </Grid.Col>
        </Grid>

        <TextInput
          mt="lg"
          label="Notes"
          placeholder="Notes about the player"
          {...form.getInputProps("notes")}
        />

        <Group mt="xl">
          <Button type="submit">Save</Button>
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          {item._id && (
            <Button
              variant="outline"
              ml="xl"
              color="red"
              onClick={onDeleteClick}
            >
              Delete
            </Button>
          )}
        </Group>
      </form>
    </>
  );
}

export default Form;
