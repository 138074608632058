import { Button, Group, Text, Box, Checkbox, Divider } from "@mantine/core";
import { useState } from "react";

interface FormProps {
  item: any;
  armours: any;
  onClose: (item: any) => void;
}

function Export({ item, onClose, armours }: FormProps) {
  const [showAlchi, setShowAlchi] = useState(false);
  const [showLocation, setShowLocation] = useState(false);
  const [compact, setCompact] = useState(false);

  const keys = Object.keys(item.values);

  const rows = keys.map((key) => {
    const armour = armours.items.find((a: any) => item.values[key] === a._id);
    if (!armour) return null;
    const alchi = item.alchemistEnchants[key];

    const location = showLocation && armour ? armour.location : null;

    if (compact) {
      return (
        <Box component="span">
          {armour.name}
          {showAlchi && alchi ? `: ${alchi}` : ""},{" "}
          {location && <Text size="xs">{location}</Text>}
        </Box>
      );
    } else {
      return (
        <Box key={key} mt="xs">
          <Text size="xs">
            {armour.name}
            {showAlchi && alchi ? `: ${alchi}` : ""}
          </Text>
          {location && <Text size="xs">{location}</Text>}
        </Box>
      );
    }
  });

  return (
    <>
      <Group>
        <Checkbox
          label="Show Alchi Enchants"
          checked={showAlchi}
          onChange={(e) => setShowAlchi(e.target.checked)}
        />
        <Checkbox
          label="Show Location"
          checked={showLocation}
          onChange={(e) => setShowLocation(e.target.checked)}
        />
        <Checkbox
          label="Compact"
          checked={compact}
          onChange={(e) => setCompact(e.target.checked)}
        />
      </Group>
      <Divider mt="md" mb="mb" />

      {rows}
      <Box mt="xl">
        <Button variant="outline" onClick={onClose}>
          Close
        </Button>
      </Box>
    </>
  );
}

export default Export;
