import {
  Card,
  Title,
  Text,
  Anchor,
  Group,
  Avatar,
  Box,
  SimpleGrid,
} from "@mantine/core";
import { ReactComponent as NewbieLogo } from "../../assets/Newbie.svg";
import { Link } from "react-router-dom";

function NewbieHelp() {
  return (
    <Card withBorder mt="lg">
      <Group>
        <Avatar color="blue" radius="xl">
          <NewbieLogo width={28} height={28} />
        </Avatar>
        <Title order={5}>Newbie Help Pages</Title>
      </Group>
      <Text mt="md">
        Here are some useful links to use if you are new to the game or need to
        refresh your memory.
      </Text>

      <SimpleGrid cols={2} mt="sm" breakpoints={[{ maxWidth: "sm", cols: 1 }]}>
        <Box mt="md">
          <Anchor component={Link} to="/lore/622b8c35579858ab54590121">
            Mud and Hud Settings
          </Anchor>
          <Text>Useful settings to improve your MUD experience.</Text>
        </Box>

        <Box mt="md">
          <Anchor component={Link} to="/planner">
            Advancement Planner
          </Anchor>
          <Text>Skills planner to help you plan your advancement.</Text>
        </Box>

        <Box mt="md">
          <Anchor component={Link} to="/lore/63209d9afc375519341304e9">
            Mudlet Maps
          </Anchor>
          <Text>
            Download and learn how use the mapper module in Mudlet.
          </Text>
        </Box>

        <Box mt="md">
          <Anchor component={Link} to="/lore/624aa285dbdfe64d1c3ffa32">
            CMUD and ZMUD Maps
          </Anchor>
          <Text>
            Download and learn how use the mapper module in CMUD or ZMUD.
          </Text>
        </Box>
      </SimpleGrid>
    </Card>
  );
}

export default NewbieHelp;
