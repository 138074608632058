import { useState, useContext, useCallback, useEffect } from "react";
import {
  Title,
  Card,
  Group,
  Modal,
  Center,
  Avatar,
  Button,
  Alert,
  LoadingOverlay,
  Anchor,
  Text,
} from "@mantine/core";
import { useNotifications } from "@mantine/notifications";

import useAxios from "axios-hooks";
import { ExclamationTriangleIcon } from "@modulz/radix-icons";
import { ReactComponent as LogsLogo } from "../assets/Logs.svg";

import Form from "../components/Logs/Form";
import ResponsiveTable from "../components/Layout/ResponsiveTable";
import ChipsFilter from "../components/Forms/ChipsFilter";
import TH from "../components/Layout/TH";

import UserContext from "../utils/userContext";

import { logTypes } from "../utils/logTools";
import { Link } from "react-router-dom";
import axios from "../utils/axios";
import { renderBadge } from "../utils/userTools";

const logOptions = logTypes.map((o) => ({ value: o, label: o }));

const defaultArgs = {
  sort: {
    created: -1,
  },
  page: 0,
  filters: {},
  items: [],
};

let lastArgs: any = null;

const project = {
  _id: 1,
  name: 1,
  type: 1,
  role: 1,
  creator: 1,
  description: 1,
};

function Lore() {
  const userState = useContext(UserContext);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);
  const [editModal, setEditModal] = useState<any>(null);
  const notifications = useNotifications();

  const [items, setItems] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [args, setArgs] = useState<SearchArgs>(defaultArgs);
  const [hasMore, setHasMore] = useState(false);

  const role = userState.user?.role || 0;

  //
  // Axios

  const [, searchItems] = useAxios(
    {
      url: "/api/public/logs/search",
      method: "POST",
    },
    {
      manual: true,
    }
  );

  const [, postItem] = useAxios(
    {
      url: "/api/guest/logs",
      method: "POST",
    },
    {
      manual: true,
    }
  );

  const [, deleteItem] = useAxios(
    {
      method: "DELETE",
    },
    {
      manual: true,
    }
  );

  //
  // Callbacks

  const showCreateModal = useCallback(() => {
    if (role === 0) {
      notifications.showNotification({
        title: "Permission Denied",
        message: "You need to be logged in to add upload logs.",
      });
    } else {
      setEditModal({});
    }
  }, [role, notifications]);

  const closeEditModal = useCallback(() => {
    setEditModal(null);
  }, []);

  const deleteLore = useCallback(
    async (item) => {
      setError(null);
      setSaving(true);
      setEditModal(null);

      try {
        await deleteItem({ url: `/api/guest/logs/${item._id}` });
        const newItems = items.filter((i) => i._id !== item._id);
        setItems(newItems);
      } catch (error: any) {
        setError(error.response?.data?.error || error.message);
      }
      setSaving(false);
    },
    [deleteItem, items]
  );

  const onUploadProgress = useCallback((progressEvent) => {
    if (progressEvent.type === "progress") {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      console.log("percentCompleted: ", percentCompleted);
    }
  }, []);

  const saveLore = useCallback(
    async (item, file) => {
      setError(null);
      setSaving(true);
      setEditModal(null);

      try {
        // Need to add id
        if (editModal._id) item._id = editModal._id;

        const { data } = await postItem({ data: item });

        if (file) {
          let formData = new FormData();
          formData.append("file", file);
          await axios.post(`/api/guest/logs/upload/${data._id}`, formData, {
            headers: {
              "Content-Type": file.type,
            },
            maxContentLength: 100,
            maxBodyLength: 100,  
            // maxContentLength: Infinity,
            // maxBodyLength: Infinity,  
            onUploadProgress: onUploadProgress,
          });
        }

        if (item._id) {
          // Update old item
          const oldIndex = items.findIndex((i) => i._id === item._id);
          if (oldIndex >= 0) {
            const newItems = [...items];
            newItems[oldIndex] = data;
            setItems(newItems);
          }
        } else {
          const newItems = [...items];
          newItems.unshift(data);
          setItems(newItems);
        }
      } catch (error: any) {
        setError(error.response?.data?.error || error.message);
      }

      setSaving(false);
    },
    [editModal, postItem, items, onUploadProgress]
  );

  //
  // Searching

  const doSearchItems = useCallback(async () => {
    try {
      setLoading(true);
      const { data: result } = await searchItems({
        data: {
          filters: args.filters,
          page: args.page,
          pageSize: 20,
          sort: args.sort,
          project,
        },
      });
      setItems(args.page === 0 ? result.items : items.concat(result.items));
      setHasMore(result.hasMore);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      setError(error.response?.data?.error || error.message);
    }
  }, [args, items, searchItems]);

  const onFiltersChange = useCallback(
    (value: any) => {
      const newFilters = {
        ...args.filters,
        ...value,
      };
      if (newFilters.type !== undefined && !newFilters.type) {
        delete newFilters.type;
      }
      setArgs({
        ...args,
        page: 0,
        filters: newFilters,
      });
    },
    [args]
  );

  const loadMore = useCallback(() => {
    const newPage = args.page + 1;
    setArgs({
      ...args,
      page: newPage,
    });
  }, [args]);

  const onSortChange = useCallback(
    (value) => {
      setArgs({
        ...args,
        page: 0,
        sort: value,
      });
    },
    [args]
  );

  //
  // Effects

  useEffect(() => {
    lastArgs = null;
  }, []);

  useEffect(() => {
    if (lastArgs !== args) {
      lastArgs = args;
      doSearchItems();
    }
  }, [doSearchItems, args]);

  return (
    <>
      <LoadingOverlay visible={saving} />

      <Group position="apart">
        <Group>
          <Avatar color="blue" size="lg" radius="xl">
            <LogsLogo width={32} height={32} />
          </Avatar>
          <Title order={1}>Logs</Title>
        </Group>
        <Group>
          <Button onClick={showCreateModal}>Upload</Button>
        </Group>
      </Group>

      {error && (
        <Alert
          mt="lg"
          icon={<ExclamationTriangleIcon />}
          title="Bummer!"
          color="red"
        >
          {error}
        </Alert>
      )}

      <Card withBorder mt="lg">
        <ChipsFilter
          onChange={onFiltersChange}
          value={args.filters.type}
          options={logOptions}
        />
      </Card>

      <Card withBorder mt="lg">
        <ResponsiveTable verticalSpacing="xs" striped>
          <thead>
            <tr>
              <TH onChange={onSortChange} current={args.sort} field="name">
                Name
              </TH>
              <TH
                onChange={onSortChange}
                current={args.sort}
                field="type"
                andThen="name"
              >
                Type
              </TH>
              <th className="min">Created by</th>
              <th className="min">Permission</th>
            </tr>
          </thead>
          <tbody>
            {!loading && items?.length === 0 && (
              <tr>
                <td colSpan={4}>No items</td>
              </tr>
            )}
            {items?.map((i: any, index: number) => (
              <tr key={i._id}>
                <td>
                  <Anchor component={Link} to={`/logs/${i._id}`}>
                    {i.name}
                  </Anchor>
                  <Text size="sm">{i.description || "No description"}</Text>
                </td>
                <td>{i.type}</td>
                <td>{i.creator?.playerName}</td>
                <td>{renderBadge(i.role)}</td>
              </tr>
            ))}
          </tbody>
        </ResponsiveTable>
        <Center mt="xl">
          {hasMore && (
            <Button loading={loading} variant="outline" onClick={loadMore}>
              Load More
            </Button>
          )}
        </Center>
      </Card>
      <Modal
        size="xl"
        padding="xl"
        opened={!!editModal}
        onClose={closeEditModal}
        title="Upload Log"
      >
        {editModal && (
          <Form
            item={editModal}
            hideUpload={false}
            onSave={saveLore}
            onDelete={deleteLore}
            onClose={closeEditModal}
          />
        )}
      </Modal>
    </>
  );
}

export default Lore;
