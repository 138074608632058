import { useCallback, useState } from "react";
import { Alert, Button, Group, Text, Textarea } from "@mantine/core";
import { useForm } from "@mantine/hooks";
import { ExclamationTriangleIcon } from "@modulz/radix-icons";
import { Action } from "../../types";

import { judgeRows } from "../../utils/weaponTools";

interface FormProps {
  item: any;
  onSave: Action<null | object>;
}

function JudgeForm({ item, onSave }: FormProps) {
  const [error, setError] = useState<string | null>(null);

  const form = useForm({
    initialValues: {
      judge: item.judges?.join("\n") || "",
    },
  });

  const onSubmit = useCallback(
    (values: typeof form["values"]) => {
      try {
        setError(null);
        const raws = values.judge.split("\n");
        const result = judgeRows(raws, item);
        onSave(result);
      } catch (error: any) {
        console.log("error: ", error);
        setError(error.message);
      }
    },
    [onSave, item]
  );

  return (
    <>
      <Text mb="lg">
        Copy and paste the weapon's judge values to set the weapon values.{" "}
        <br />
        <b>Note:</b> Do not add enchanted items.
      </Text>
      <form onSubmit={form.onSubmit(onSubmit)}>
        <Textarea
          required={true}
          minRows={8}
          label="Judge Values"
          placeholder="All judge values"
          {...form.getInputProps("judge")}
        />

        {error && (
          <Alert
            mt="lg"
            icon={<ExclamationTriangleIcon />}
            title="Bummer!"
            color="red"
          >
            {error}
          </Alert>
        )}

        <Group mt="lg">
          <Button type="submit">Continue</Button>
        </Group>
      </form>
    </>
  );
}

export default JudgeForm;
