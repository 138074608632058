import {
  TextInput,
  Button,
  Group,
  PasswordInput,
  Text,
  Grid,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { renderBadge } from "../../utils/userTools";

interface FormProps {
  item: any;
  onSave: (item: any) => void;
  saving: boolean;
}

function Form({ item, onSave, saving }: FormProps) {
  const form = useForm({
    initialValues: {
      firstName: "",
      lastName: "",
      password: "",
      passwordAgain: "",
    },
    validate: (values) => ({
      password: values.password !== values.passwordAgain ? "Passwords missmatch" : null,
      passwordAgain: values.password !== values.passwordAgain ? "Passwords missmatch" : null,
    }),
  });

  return (
    <form onSubmit={form.onSubmit(onSave)}>
      <Grid mt="lg">
        <Grid.Col xs={6}>
          <Group direction="column" spacing="xs">
            <Text size="xs">Player Name</Text>
            <Text>{item.playerName}</Text>
          </Group>
        </Grid.Col>
        <Grid.Col xs={6}>
          <Group direction="column" spacing="xs">
            <Text size="xs">Role</Text>
            {renderBadge(item.role)}
          </Group>
        </Grid.Col>
      </Grid>

      <Grid mt="lg">
        <Grid.Col xs={6}>
          <TextInput
            required
            label="First Name"
            {...form.getInputProps("firstName")}
          />
        </Grid.Col>
        <Grid.Col xs={6}>
          <TextInput
            required
            label="Last Name"
            {...form.getInputProps("lastName")}
          />
        </Grid.Col>
        <Grid.Col xs={6}>
          <PasswordInput
            required={true}
            label="Password"
            {...form.getInputProps("password")}
          />
        </Grid.Col>
        <Grid.Col xs={6}>
          <PasswordInput
            required={true}
            label="Repeat Password"
            {...form.getInputProps("passwordAgain")}
          />
        </Grid.Col>
      </Grid>

      <Group mt="xl">
        <Button disabled={saving} type="submit">
          Save
        </Button>
      </Group>
    </form>
  );
}

export default Form;
