import { useState, useContext, useCallback, useEffect } from "react";
import {
  Title,
  Card,
  Group,
  Modal,
  Center,
  Avatar,
  Button,
  Alert,
  LoadingOverlay,
} from "@mantine/core";
import { useNotifications } from "@mantine/notifications";

import useAxios from "axios-hooks";
import { ExclamationTriangleIcon } from "@modulz/radix-icons";
import { ReactComponent as Logo } from "../assets/Invites.svg";

import Form from "../components/Invites/Form";
import Details from "../components/Invites/Details";

import ResponsiveTable from "../components/Layout/ResponsiveTable";

import UserContext from "../utils/userContext";
import { renderBadge } from "../utils/userTools";

const defaultArgs = {
  sort: {
    name: 1,
  },
  page: 0,
  filters: {},
  items: [],
};

let lastArgs: any = null;

function Invites() {
  const userState = useContext(UserContext);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);
  const [editModal, setEditModal] = useState<any>(null);
  const [detailsModal, setDetailsModal] = useState<any>(null);

  const notifications = useNotifications();

  const [items, setItems] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [args, setArgs] = useState<SearchArgs>(defaultArgs);
  const [hasMore, setHasMore] = useState(false);

  const role = userState.user?.role || 0;

  //
  // Axios

  const [, searchItems] = useAxios(
    {
      url: "/api/supertemplar/invites/search",
      method: "POST",
    },
    {
      manual: true,
    }
  );

  const [, postItem] = useAxios(
    {
      url: "/api/supertemplar/invites",
      method: "POST",
    },
    {
      manual: true,
    }
  );

  const [, deleteItem] = useAxios(
    {
      method: "DELETE",
    },
    {
      manual: true,
    }
  );

  //
  // Callbacks

  const closeDetailsModal = useCallback(() => {
    setDetailsModal(null);
  }, []);

  const showCreateModal = useCallback(
    (item) => {
      setDetailsModal(null);
      if (role === 0) {
        notifications.showNotification({
          title: "Permission Denied",
          message: "You need to be logged in to invite people.",
        });
      } else {
        setEditModal(item.type === "click" ? {} : item);
      }
    },
    [role, notifications]
  );

  const closeEditModal = useCallback(() => {
    setEditModal(null);
  }, []);

  const deleteInvite = useCallback(
    async (item) => {
      setError(null);
      setSaving(true);
      setEditModal(null);

      try {
        await deleteItem({ url: `/api/supertemplar/invites/${item._id}` });
        const newItems = items.filter((i) => i._id !== item._id);
        setItems(newItems);
      } catch (error: any) {
        setError(error.response?.data?.error || error.message);
      }
      setSaving(false);
    },
    [deleteItem, items]
  );

  const saveInvite = useCallback(
    async (item, file) => {
      setError(null);
      setSaving(true);
      setEditModal(null);

      try {
        // Need to add id
        if (editModal._id) item._id = editModal._id;

        const { data } = await postItem({ data: item });
        const isNew = !item._id;

        if (!isNew) {
          // Update old item
          const oldIndex = items.findIndex((i) => i._id === item._id);
          if (oldIndex >= 0) {
            const newItems = [...items];
            newItems[oldIndex] = data;
            setItems(newItems);
          }
        } else {
          const newItems = [...items];
          newItems.unshift(data);
          setItems(newItems);
        }

        if (isNew) {
          // If new open the edit view
          setDetailsModal(data);
        }
      } catch (error: any) {
        setError(error.response?.data?.error || error.message);
      }

      setSaving(false);
    },
    [editModal, postItem, items]
  );

  //
  // Searching

  const doSearchItems = useCallback(async () => {
    try {
      setLoading(true);
      const { data: result } = await searchItems({
        data: {
          filters: args.filters,
          page: args.page,
          pageSize: 20,
          sort: args.sort,
        },
      });
      setItems(args.page === 0 ? result.items : items.concat(result.items));
      setHasMore(result.hasMore);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      setError(error.response?.data?.error || error.message);
    }
  }, [args, items, searchItems]);

  const loadMore = useCallback(() => {
    const newPage = args.page + 1;
    setArgs({
      ...args,
      page: newPage,
    });
  }, [args]);

  //
  // Effects

  useEffect(() => {
    lastArgs = null;
  }, []);

  useEffect(() => {
    if (lastArgs !== args) {
      lastArgs = args;
      doSearchItems();
    }
  }, [doSearchItems, args]);

  return (
    <>
      <LoadingOverlay visible={saving} />

      <Group position="apart">
        <Group>
          <Avatar color="blue" size="lg" radius="xl">
            <Logo width={32} height={32} />
          </Avatar>
          <Title order={1}>Invites</Title>
        </Group>
        <Group>
          <Button onClick={showCreateModal}>Create</Button>
        </Group>
      </Group>

      {error && (
        <Alert
          mt="lg"
          icon={<ExclamationTriangleIcon />}
          title="Bummer!"
          color="red"
        >
          {error}
        </Alert>
      )}

      <Card withBorder mt="lg">
        <ResponsiveTable verticalSpacing="xs" striped>
          <thead>
            <tr>
              <th>Player Name</th>
              <th>Created by</th>
              <th>Permission</th>
            </tr>
          </thead>
          <tbody>
            {!loading && items?.length === 0 && (
              <tr>
                <td colSpan={3}>No items</td>
              </tr>
            )}
            {items?.map((i: any, index: number) => (
              <tr key={i._id}>
                <td>
                  <Button variant="subtle" onClick={() => setDetailsModal(i)}>
                    {i.playerName}
                  </Button>
                </td>
                <td>{i.creator?.playerName}</td>
                <td>{renderBadge(i.role)}</td>
              </tr>
            ))}
          </tbody>
        </ResponsiveTable>
        <Center mt="xl">
          {hasMore && (
            <Button loading={loading} variant="outline" onClick={loadMore}>
              Load More
            </Button>
          )}
        </Center>
      </Card>

      <Modal
        size="lg"
        padding="xl"
        opened={!!editModal || !!detailsModal}
        onClose={editModal ? closeEditModal : closeDetailsModal}
        title={"Invite"}
      >
        {editModal && (
          <Form
            item={editModal}
            onSave={saveInvite}
            onDelete={deleteInvite}
            onClose={closeEditModal}
          />
        )}
        {detailsModal && (
          <Details
            item={detailsModal}
            onClose={closeDetailsModal}
            onEdit={role > 0 ? showCreateModal : undefined}
          />
        )}
      </Modal>
    </>
  );
}

export default Invites;
