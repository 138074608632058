import { Group, Text, useMantineTheme, MantineTheme, Box } from "@mantine/core";

import { Upload, X, Icon as TablerIcon, FileText } from "tabler-icons-react";
import { Dropzone, DropzoneStatus } from "@mantine/dropzone";
import { useCallback } from "react";
import { useNotifications } from "@mantine/notifications";

function getIconColor(status: DropzoneStatus, theme: MantineTheme) {
  return status.accepted
    ? theme.colors[theme.primaryColor][theme.colorScheme === "dark" ? 4 : 6]
    : status.rejected
    ? theme.colors.red[theme.colorScheme === "dark" ? 4 : 6]
    : theme.colorScheme === "dark"
    ? theme.colors.dark[0]
    : theme.colors.gray[7];
}

function ImageUploadIcon({
  status,
  ...props
}: React.ComponentProps<TablerIcon> & { status: DropzoneStatus }) {
  if (status.accepted) {
    return <Upload {...props} />;
  }

  if (status.rejected) {
    return <X {...props} />;
  }

  return <FileText {...props} />;
}

const dropzoneChildren = (
  status: DropzoneStatus,
  theme: MantineTheme,
  text?: string
) => (
  <Group position="center" style={{ minHeight: 100, pointerEvents: "none" }}>
    <ImageUploadIcon
      status={status}
      style={{ color: getIconColor(status, theme) }}
      size={70}
    />

    <div>
      <Text size="xl" inline>
        {text || "Drag log file here or click to select file"}
      </Text>
    </div>
  </Group>
);

const fileChildren = (file: any, theme: MantineTheme) => (
  <Group
    position="left"
    style={{ minHeight: 100, pointerEvents: "none", padding: 16 }}
  >
    <FileText style={{ color: theme.colors.gray[7] }} size={70} />

    <div>
      <Text size="xl" inline>
        {file.name}
      </Text>
      <Text size="sm" color="dimmed" inline mt={7}>
        The file should not exceed 5 MB
      </Text>
    </div>
  </Group>
);

interface FormProps {
  file?: any;
  mt?: string;
  text?: string;
  accept?: string[];
  onDropFile: (files: any[]) => void;
  maxFileSize?: number
}

function FileZone(props: FormProps) {
  const theme = useMantineTheme();
  const { onDropFile, file, mt, text, accept, maxFileSize } = props;
  const notifications = useNotifications();

  const onReject = useCallback(() => {
    notifications.showNotification({
      title: "Invalid File",
      message: "The file is either too large or the wrong format.",
    });
  }, [notifications]);

  const size = maxFileSize || 3;

  return (
    <Box mt={mt}>
      {file && fileChildren(file, theme)}
      {!file && (
        <Dropzone
          multiple={false}
          accept={accept}
          onReject={onReject}
          onDrop={onDropFile}
          maxSize={size * 1024 ** 2}
          styles={{ root: { padding: 0 } }}
        >
          {(status) => dropzoneChildren(status, theme, text)}
        </Dropzone>
      )}
    </Box>
  );
}

export default FileZone;
