import { memo, useCallback } from "react";
import { Chip, Chips, TextInput } from "@mantine/core";
import { Search } from "tabler-icons-react";

type FilterOptions = {
  [field: string]: any;
};

//
// Component

type FilterProps = {
  options?: FilterOptions;
  value?: string;
  name?: string;
  onChange: (filters: any) => void;
  multiple?: boolean;
};

function ChipsFilter(props: FilterProps) {
  const { onChange, value, options } = props;

  const name = props.name || "type";

  const onKeyPress = useCallback(
    (e) => {
      if (e.code === "Enter") {
        onChange({
          search: e.target.value,
        });
      }
    },
    [onChange]
  );

  let val: string | string[] = "";
  if (value) {
    val = value;
  }

  // if (multiple && val && val.length > 0) {
  //   val = val[0];
  // }

  const onChipsChange = useCallback(
    (v) => {
      onChange({
        [name]: v, //multiple ? [v] : v,
      });
    },
    [onChange, name]
  );

  return (
    <>
      {options && (
        <Chips mb="lg" value={val} onChange={onChipsChange}>
          <Chip value="">ALL</Chip>
          {options?.map((o: any) => (
            <Chip key={o.value} value={o.value}>
              {o.label}
            </Chip>
          ))}
        </Chips>
      )}
      <TextInput
        onKeyPress={onKeyPress}
        placeholder="Search by name"
        icon={<Search size={14} />}
      />
    </>
  );
}

const areEqual = (prevProps: any, nextProps: any) =>
  prevProps.value === nextProps.value &&
  prevProps.options === nextProps.options &&
  prevProps.onChange === nextProps.onChange;
export default memo(ChipsFilter, areEqual);
