import React, { useState, useContext, useCallback, useEffect } from "react";
import {
  Title,
  Card,
  Group,
  Modal,
  Center,
  Avatar,
  Button,
  Alert,
  LoadingOverlay,
  Anchor,
} from "@mantine/core";
import { useNotifications } from "@mantine/notifications";

import useAxios from "axios-hooks";
import { ExclamationTriangleIcon } from "@modulz/radix-icons";
import { ReactComponent as Logo } from "../assets/Planner.svg";

import Form from "../components/Planner/Form";
import ResponsiveTable from "../components/Layout/ResponsiveTable";
import TH from "../components/Layout/TH";

import UserContext from "../utils/userContext";
import { renderBadge } from "../utils/userTools";

import { Link } from "react-router-dom";

const defaultArgs = {
  sort: {
    name: 1,
  },
  page: 0,
  filters: {},
  items: [],
};

let lastArgs: any = null;

const project = {
  _id: 1,
  name: 1,
  class: 1,
  role: 1,
  creator: 1,
};

function Planner() {
  const userState = useContext(UserContext);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);
  const [editModal, setEditModal] = useState<any>(null);
  const notifications = useNotifications();

  const [items, setItems] = useState<any[]>([]);
  const [guides, setGuides] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [args, setArgs] = useState<SearchArgs>(defaultArgs);
  const [hasMore, setHasMore] = useState(false);

  const role = userState.user?.role || 0;

  //
  // Axios

  const [, searchItems] = useAxios(
    {
      url: "/api/public/plans/search",
      method: "POST",
    },
    {
      manual: true,
    }
  );

  const [, searchGuides] = useAxios(
    {
      url: "/api/public/guides/search",
      method: "POST",
    },
    {
      manual: true,
    }
  );

  const [, postItem] = useAxios(
    {
      url: "/api/guest/plans",
      method: "POST",
    },
    {
      manual: true,
    }
  );

  const [, deleteItem] = useAxios(
    {
      method: "DELETE",
    },
    {
      manual: true,
    }
  );

  //
  // Callbacks

  const showCreateModal = useCallback(() => {
    if (role === 0) {
      notifications.showNotification({
        title: "Permission Denied",
        message: "You need to be logged in to add skill trees.",
      });
    } else {
      setEditModal({});
    }
  }, [role, notifications]);

  const closeEditModal = useCallback(() => {
    setEditModal(null);
  }, []);

  const deletePlan = useCallback(
    async (item) => {
      setError(null);
      setSaving(true);
      setEditModal(null);

      try {
        await deleteItem({ url: `/api/guest/plans/${item._id}` });
        const newItems = items.filter((i) => i._id !== item._id);
        setItems(newItems);
      } catch (error: any) {
        setError(error.response?.data?.error || error.message);
      }
      setSaving(false);
    },
    [deleteItem, items]
  );

  const savePlan = useCallback(
    async (item, file) => {
      setError(null);
      setSaving(true);
      setEditModal(null);

      try {
        // Need to add id
        if (editModal._id) item._id = editModal._id;

        const { data } = await postItem({ data: item });

        if (item._id) {
          // Update old item
          const oldIndex = items.findIndex((i) => i._id === item._id);
          if (oldIndex >= 0) {
            const newItems = [...items];
            newItems[oldIndex] = data;
            setItems(newItems);
          }
        } else {
          const newItems = [...items];
          newItems.unshift(data);
          setItems(newItems);
        }
      } catch (error: any) {
        setError(error.response?.data?.error || error.message);
      }

      setSaving(false);
    },
    [editModal, postItem, items]
  );

  //
  // Searching

  const doSearchItems = useCallback(async () => {
    try {
      setLoading(true);
      const { data: result } = await searchItems({
        data: {
          filters: args.filters,
          page: args.page,
          pageSize: 50,
          sort: args.sort,
          project,
        },
      });
      setItems(args.page === 0 ? result.items : items.concat(result.items));
      setHasMore(result.hasMore);

      const { data: guides } = await searchGuides({
        data: {
          page: 0,
          pageSize: 5000,
        },
      });
      setGuides(guides.items);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      setError(error.response?.data?.error || error.message);
    }
  }, [args, items, searchItems, searchGuides]);

  const loadMore = useCallback(() => {
    const newPage = args.page + 1;
    setArgs({
      ...args,
      page: newPage,
    });
  }, [args]);

  const onSortChange = useCallback(
    (value) => {
      setArgs({
        ...args,
        page: 0,
        sort: value,
      });
    },
    [args]
  );

  //
  // Effects

  useEffect(() => {
    lastArgs = null;
  }, []);

  useEffect(() => {
    if (lastArgs !== args) {
      lastArgs = args;
      doSearchItems();
    }
  }, [doSearchItems, args]);

  return (
    <>
      <LoadingOverlay visible={saving} />

      <Group position="apart">
        <Group>
          <Avatar color="blue" size="lg" radius="xl">
            <Logo width={32} height={32} />
          </Avatar>
          <Title order={1}>Skill Planner</Title>
        </Group>
        <Group>
          <Button onClick={showCreateModal}>Add Skill Tree</Button>
        </Group>
      </Group>

      {error && (
        <Alert
          mt="lg"
          icon={<ExclamationTriangleIcon />}
          title="Bummer!"
          color="red"
        >
          {error}
        </Alert>
      )}

      <Card withBorder mt="lg">
        <ResponsiveTable verticalSpacing="xs" striped>
          <thead>
            <tr>
              <TH onChange={onSortChange} current={args.sort} field="name">
                Skill Tree
              </TH>
              <th>Created by</th>
              <th>Permission</th>
            </tr>
          </thead>
          <tbody>
            {!loading && items?.length === 0 && (
              <tr>
                <td colSpan={3}>No items</td>
              </tr>
            )}
            {items?.map((i: any, index: number) => (
              <React.Fragment key={index}>
                <tr key={i._id}>
                  <td>
                    <Anchor component={Link} to={`/planner/${i._id}`}>
                      {i.name}
                    </Anchor>
                  </td>
                  <td>{i.creator?.playerName}</td>
                  <td>{renderBadge(i.role)}</td>
                </tr>
                {guides?.filter(g => g._planId === i._id).map((g: any) => (
                  <tr key={g._id}>
                    <td>
                      | <Anchor
                        ml="md"
                        size="sm"
                        component={Link}
                        to={`/planner/${i._id}/${g._id}`}
                      >
                        {g.name}
                      </Anchor>
                    </td>
                    <td>{g.creator?.playerName}</td>
                    <td>{renderBadge(g.role)}</td>
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </ResponsiveTable>
        <Center mt="xl">
          {hasMore && (
            <Button loading={loading} variant="outline" onClick={loadMore}>
              Load More
            </Button>
          )}
        </Center>
      </Card>
      <Modal
        size="xl"
        padding="xl"
        opened={!!editModal}
        onClose={closeEditModal}
        title="Create Skill Tree"
      >
        {editModal && (
          <Form
            url="plans"
            item={editModal}
            onSave={savePlan}
            onDelete={deletePlan}
            onClose={closeEditModal}
          />
        )}
      </Modal>
    </>
  );
}

export default Planner;
