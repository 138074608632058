import { useState, useCallback, useContext, useEffect } from "react";
import {
  Title,
  Card,
  Group,
  Modal,
  Center,
  Button,
  Alert,
  Avatar,
  Loader,
} from "@mantine/core";
import useAxios from "axios-hooks";
import { Link, useParams, useNavigate } from "react-router-dom";

import { ExclamationTriangleIcon } from "@modulz/radix-icons";

import Form from "../components/Weapons/Modal";
import Details from "../components/Weapons/Details";

import { ReactComponent as WeaponsLogo } from "../assets/Weapons.svg";

import UserContext from "../utils/userContext";

function Weapons() {
  const params = useParams();
  const navigate = useNavigate();

  const userState = useContext(UserContext);
  const [error, setError] = useState(null);
  const [item, setItem] = useState<any>(null);
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(true);

  const role = userState.user?.role || 0;

  const id = (params.id as string) || "none";

  //
  // Axios

  const [, getItem] = useAxios(
    {
      method: "GET",
    },
    {
      manual: true,
    }
  );

  const [, postItem] = useAxios(
    {
      url: "/api/guest/weapons",
      method: "POST",
    },
    {
      manual: true,
    }
  );

  const [, deleteItem] = useAxios(
    {
      method: "DELETE",
    },
    {
      manual: true,
    }
  );

  //
  // Callbacks

  const doGetItem = useCallback(
    async (id: string) => {
      try {
        setLoading(true);
        const { data } = await getItem({
          url: `/api/public/weapons/${id}`,
        });
        setItem(data);
        setLoading(false);
      } catch (error: any) {
        setError(error.response?.data?.error || error.message);
      }
    },
    [getItem]
  );

  const closeEditModal = useCallback(() => {
    setEditMode(false);
  }, []);

  const deleteWeapon = useCallback(
    async (item) => {
      setError(null);
      setLoading(true);

      try {
        await deleteItem({ url: `/api/guest/weapons/${item._id}` });
        navigate("/weapons");
      } catch (error: any) {
        setError(error.response?.data?.error || error.message);
        setLoading(false);
      }
    },
    [deleteItem, navigate]
  );

  const saveWeapon = useCallback(
    async (item) => {
      setError(null);
      setLoading(true);

      try {
        // Need to add id
        item._id = id;
        const { data } = await postItem({ data: item });
        setItem(data);
      } catch (error: any) {
        setError(error.response?.data?.error || error.message);
      }

      setEditMode(false);
      setLoading(false);
    },
    [id, postItem]
  );

  //
  // Effects

  useEffect(() => {
    doGetItem(id);
  }, [id, doGetItem]);

  if (loading) {
    return (
      <div>
        <Card>
          <Center>
            <Loader />
          </Center>
        </Card>
      </div>
    );
  }

  return (
    <div>
      <Group position="apart">
        <Group>
          <Avatar color="blue" size="lg" radius="xl">
            <WeaponsLogo width={32} height={32} />
          </Avatar>
          <Title order={1}>{item.name}</Title>
        </Group>
        <Group>
          <Button component={Link} variant="outline" to="/weapons">
            Back
          </Button>
          {role >= 1 && (
            <Button onClick={() => setEditMode(true)}>Edit</Button>
          )}
        </Group>
      </Group>

      {error && (
        <Alert
          mt="lg"
          icon={<ExclamationTriangleIcon />}
          title="Bummer!"
          color="red"
        >
          {error}
        </Alert>
      )}

      <Card withBorder mt="lg">
        {
          item && (
            <Details item={item} hideButtons={true} />
          )
        }
      </Card>

      <Modal
        size="lg"
        padding="xl"
        opened={editMode}
        onClose={closeEditModal}
        title="Weapon Details"
      >
        {editMode && (
          <Form
            item={item}
            onSave={saveWeapon}
            onDelete={deleteWeapon}
            onClose={closeEditModal}
          />
        )}
      </Modal>

    </div>
  );
}

export default Weapons;
