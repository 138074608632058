import {
  Card,
  Title,
  Text,
  Anchor,
  Group,
  Avatar,
  Grid,
  SimpleGrid,
} from "@mantine/core";
import { Link } from "react-router-dom";
import { useContext } from "react";
import UserContext from "../utils/userContext";
import { ReactComponent as TemplarLogo } from "../assets/TemplarLogo.svg";
import { ReactComponent as ArmoursLogo } from "../assets/Armours.svg";
import { ReactComponent as LogsLogo } from "../assets/Logs.svg";
import { ReactComponent as LoreLogo } from "../assets/Lore.svg";
import { ReactComponent as NewsLogo } from "../assets/News.svg";

import LatestItems from "../components/Dashboard/LatestItems";
import NewbieHelp from "../components/Dashboard/NewbieHelp";
import StickyNews from "../components/Dashboard/StickyNews";

function Dashboard() {
  const userState = useContext(UserContext);

  return (
    <>
      <Group>
        <Avatar color="blue" size="lg" radius="xl">
          <TemplarLogo width={32} height={32} />
        </Avatar>
        <Title order={1}>Bulletin Board</Title>
      </Group>
      <Grid mt="lg">
        <Grid.Col md={8}>
          {!userState.user && (
            <Card withBorder mt="lg">
              <Text>
                <Anchor component={Link} to="/login">
                  Login
                </Anchor>{" "}
                to get full access!
              </Text>
            </Card>
          )}

          <StickyNews user={userState.user} />
          <NewbieHelp />

          <SimpleGrid cols={2} breakpoints={[{ maxWidth: "md", cols: 1 }]}>
            <LatestItems url="logs" title="Latest Logs" Logo={LogsLogo} />
            <LatestItems url="lore" title="Latest Pages" Logo={LoreLogo} />
          </SimpleGrid>
        </Grid.Col>
        <Grid.Col md={4}>
          <LatestItems url="news" title="Latest News" Logo={NewsLogo} />
          <LatestItems
            url="armours"
            title="Latest Armours"
            Logo={ArmoursLogo}
          />
        </Grid.Col>
      </Grid>
    </>
  );
}

export default Dashboard;
