import {
  TextInput,
  Button,
  Group,
  PasswordInput,
  Text,
  SimpleGrid,
} from "@mantine/core";
import { useForm } from "@mantine/hooks";
import { renderBadge } from "../../utils/userTools";

interface FormProps {
  item: any;
  onSave: (item: any) => void;
  saving: boolean;
}

function Form({ item, onSave, saving }: FormProps) {
  const form = useForm({
    initialValues: {
      firstName: item.firstName || "",
      lastName: item.lastName || "",
      password: item.password || "",
    },
  });

  return (
    <form onSubmit={form.onSubmit(onSave)}>
      <SimpleGrid cols={2} mt="lg" breakpoints={[{ maxWidth: "sm", cols: 1 }]}>
        <Group direction="column" spacing="xs">
          <Text size="xs">Player Name</Text>
          <Text>{item.playerName}</Text>
        </Group>

        <Group direction="column" spacing="xs">
          <Text size="xs">Role</Text>
          <Text>{renderBadge(item.role)}</Text>
        </Group>

        <TextInput
          required
          label="First Name"
          {...form.getInputProps("firstName")}
        />
        <TextInput
          required
          label="Last Name"
          {...form.getInputProps("lastName")}
        />
        <PasswordInput
          required={!item._id}
          label="Password"
          {...form.getInputProps("password")}
        />
      </SimpleGrid>

      <Group mt="xl">
        <Button disabled={saving} type="submit">
          Save
        </Button>
      </Group>
    </form>
  );
}

export default Form;
