import {
  Text,
  Group,
  SimpleGrid,
  Card,
  useMantineTheme,
  Anchor,
} from "@mantine/core";
import { renderBadge } from "../../utils/userTools";
import { RichTextEditor } from "@mantine/rte";
import withItemLoader from "../withItemLoader";
import { FileText } from "tabler-icons-react";
import { useMemo } from "react";
import ResponsiveTable from "../Layout/ResponsiveTable";

interface FormProps {
  item: any;
  hideButtons: boolean;
  onClose: (item: any) => void;
  onEdit?: (item: any) => void;
}

function DetailsForm({ item, onClose, onEdit, hideButtons }: FormProps) {
  const theme = useMantineTheme();

  const tabular = item.tabular;

  const text = useMemo<any>(() => {
    if (tabular) {
      const rows = item.text.split("\n");

      const result = [];

      // \t or ,?
      const splitter = rows[0].indexOf("\t") >= 0 ? "\t" : ",";

      // thead
      const cols = rows[0]
        .replace(/<p>/g, "")
        .replace(/<\/p>/g, "")
        .replace(/<br>/g, "")
        .split(splitter)
        .filter((c: string) => !!c);

      result.push(
        <thead>
          <tr>
            {cols.map((c: string, i: number) => (
              <th key={i}>{c}</th>
            ))}
          </tr>
        </thead>
      );

      const trs = [];

      for (let i = 1; i < rows.length; i++) {
        const cols = rows[i]
          .replace(/<p>/g, "")
          .replace(/<\/p>/g, "")
          .replace(/<br>/g, "")
          .split(splitter)
          .filter((c: string) => !!c);

        if (cols.length === 0) continue;

        trs.push(
            <tr>
              {cols.map((c: string, i: number) => (
                <td key={i}>{c}</td>
              ))}
            </tr>
        );
      }
      result.push(<tbody>{trs}</tbody>);
      return result;
    } else {
      return item.text.replace(
        /\t/g,
        "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
      );
    }
  }, [item, tabular]);

  return (
    <>
      <Card withBorder mt="lg">
        <SimpleGrid
          cols={3}
          spacing="lg"
          breakpoints={[{ maxWidth: "md", cols: 1 }]}
        >
          <Group direction="column" spacing="xs">
            <Text size="xs">Type</Text>
            <Text>{item.type}</Text>
          </Group>

          <Group direction="column" spacing="xs">
            <Text size="xs">Created by</Text>
            <Text>{item.creator?.playerName}</Text>
          </Group>

          <Group direction="column" spacing="xs">
            <Text size="xs">Permission</Text>
            {renderBadge(item.role)}
          </Group>
        </SimpleGrid>
      </Card>

      {item.files?.length > 0 && (
        <Card withBorder mt="lg">
          <Text size="xs">Files</Text>
          {item.files.map((file: any) => (
            <Group key={file.key} position="left" mt="md">
              <FileText style={{ color: theme.colors.gray[7] }} size={32} />
              <Anchor href={file.url}>{file.name}</Anchor>
            </Group>
          ))}
        </Card>
      )}

      {tabular && (
        <Card withBorder mt="lg">
          <ResponsiveTable verticalSpacing="xs" striped>
            {text}
          </ResponsiveTable>
        </Card>
      )}

      {!tabular && (
        <Card withBorder mt="lg">
          <RichTextEditor
            styles={{
              root: {
                border: "none",
                fontFamily: item.monospace ? "monospace" : undefined,
                p: {
                  marginBottom: 4,
                },
              },
            }}
            value={text}
            readOnly
            onChange={(e) => null}
          />
        </Card>
      )}
    </>
  );
}

export default withItemLoader(DetailsForm);
