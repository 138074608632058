import { useCallback, useEffect, useState } from "react";
import {
  Title,
  Card,
  Group,
  Center,
  Avatar,
  Alert,
  Loader,
  Grid,
  Text,
  Button,
  TextInput,
} from "@mantine/core";

import useAxios from "axios-hooks";
import { ExclamationTriangleIcon } from "@modulz/radix-icons";
import { ReactComponent as BotLogo } from "../assets/Bot.svg";
import { useForm } from "@mantine/hooks";

function Bot() {
  const [data, setData] = useState<any>({});
  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  const form = useForm({
    initialValues: {
      cmd: "",
    },
  });

  //
  // Axios

  const [, getStatus] = useAxios(
    {
      url: "/api/god/bot/status",
      method: "GET",
    },
    { manual: true }
  );

  const [, resetStatus] = useAxios(
    {
      url: "/api/god/bot/status",
      method: "DELETE",
    },
    { manual: true }
  );

  const [, setStatus] = useAxios(
    {
      url: "/api/public/bot/status",
      method: "POST",
    },
    { manual: true }
  );

  const reload = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await getStatus();
      setData(data);
    } catch (error: any) {
      setError(error.response?.data?.error || error.message);
    }
    setLoading(false);
  }, [getStatus]);

  const onSetCommandClick = useCallback(
    async (cmd) => {
      try {
        setLoading(true);
        await setStatus({
          data: {
            command: cmd,
          },
        });
        reload();
      } catch (error: any) {
        setError(error.response?.data?.error || error.message);
        setLoading(false);
      }
    },
    [reload, setStatus]
  );

  const onResetClick = useCallback(async () => {
    try {
      setLoading(true);
      await resetStatus();
      reload();
    } catch (error: any) {
      setError(error.response?.data?.error || error.message);
      setLoading(false);
    }
  }, [resetStatus, reload]);

  useEffect(() => {
    reload();
  }, [reload]);

  return (
    <>
      <Group position="apart">
        <Group>
          <Avatar color="blue" size="lg" radius="xl">
            <BotLogo width={32} height={32} />
          </Avatar>
          <Title order={1}>Bot</Title>
        </Group>
        <Group>
          <Button variant="outline" color="red" onClick={onResetClick}>
            Reset
          </Button>
          <Button onClick={reload}>Reload</Button>
        </Group>
      </Group>

      {error && (
        <Alert
          mt="lg"
          icon={<ExclamationTriangleIcon />}
          title="Bummer!"
          color="red"
        >
          {error}
        </Alert>
      )}

      <Card withBorder mt="lg">
        {loading && (
          <Center>
            <Loader />
          </Center>
        )}
        {data && (
          <>
            <Grid>
              <Grid.Col span={6} xs={4}>
                <Text size="xs">Status:</Text>
                <Text>{data.status}</Text>
              </Grid.Col>
              <Grid.Col span={6} xs={4}>
                <Text size="xs">Time:</Text>
                <Text>{data.time}s ago</Text>
              </Grid.Col>
              <Grid.Col span={6} xs={4}>
                <Text size="xs">Round Time:</Text>
                <Text>{data.roundTime}s</Text>
              </Grid.Col>
              <Grid.Col span={6} xs={4}>
                <Text size="xs">Rounds:</Text>
                <Text>{data.rounds}</Text>
              </Grid.Col>
              <Grid.Col span={6} xs={4}>
                <Text size="xs">Kills:</Text>
                <Text>{data.kills}</Text>
              </Grid.Col>
              <Grid.Col span={6} xs={4}>
                <Text size="xs">Xp:</Text>
                <Text>{data.xp}</Text>
              </Grid.Col>
              <Grid.Col span={6} xs={4}>
                <Text size="xs">To Cap:</Text>
                <Text>{data.toCap}%</Text>
              </Grid.Col>
              <Grid.Col span={6} xs={4}>
                <Text size="xs">Alignment:</Text>
                <Text>{data.alignment}</Text>
              </Grid.Col>
              <Grid.Col span={6} xs={4}>
                <Text size="xs">Shards:</Text>
                <Text>{data.shards}</Text>
              </Grid.Col>
              <Grid.Col span={6} xs={4}>
                <Text size="xs">Cyans:</Text>
                <Text>{data.cyans}</Text>
              </Grid.Col>
              <Grid.Col span={6} xs={4}>
                <Text size="xs">Vapors:</Text>
                <Text>{data.vapors}</Text>
              </Grid.Col>
            </Grid>
            {data.tells && (
              <>
                <Text mt="lg" size="xs">
                  Last Tell:
                </Text>
                {data.tells.map((t: string, i: number) => (
                  <Text key={i}>{t}</Text>
                ))}
              </>
            )}
          </>
        )}
      </Card>

      {data && (
        <Card withBorder mt="lg">
          <Text size="xs">Pending Command:</Text>
          <Text>{data.command || "None"}</Text>

          <Group mt="lg">
            <Button onClick={() => onSetCommandClick("CMD:Stop")}>Stop</Button>
            <Button onClick={() => onSetCommandClick("CMD:Quit")}>Quit</Button>
            <Button variant="outline" onClick={() => onSetCommandClick("")}>
              Clear
            </Button>
          </Group>

          <TextInput
            mt="lg"
            label="Custom Command"
            placeholder="smile"
            {...form.getInputProps("cmd")}
          />
          <Button
            mt="md"
            onClick={() => onSetCommandClick(`DO:${form.values.cmd}`)}
          >
            Send
          </Button>
        </Card>
      )}
    </>
  );
}

export default Bot;
