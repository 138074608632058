import { Card, Center, Loader, Title, Group, Avatar, Box } from "@mantine/core";
import useAxios from "axios-hooks";
import { useEffect } from "react";
import { ReactComponent as NewsLogo } from "../../assets/News.svg";

const args = {
  sort: {
    created: -1,
  },
  page: 0,
  pageSize: 2,
  filters: {
    sticky: true,
  },
  items: [],
};

function LatestItems({ user }: any) {
  const [{ data, loading }, search] = useAxios(
    {
      url: `/api/public/news/search`,
      method: "POST",
      data: args,
    },
    {
      manual: true,
    }
  );

  useEffect(() => {
    search();
  }, [search]);

  if (loading) {
    return (
      <Card withBorder mt="lg">
        <Center>
          <Loader />
        </Center>
      </Card>
    );
  }

  let templarNews = null;
  if (data && user) {
    const newsItem = data.items.find((i: any) => i.role > 0);
    if (newsItem) {
      templarNews = (
        <Card withBorder mt="lg">
          <Group>
            <Avatar color="blue" radius="xl">
              <NewsLogo width={28} height={28} />
            </Avatar>
            <Title order={4}>{newsItem.name}</Title>
          </Group>

          <Box>
            <div dangerouslySetInnerHTML={{ __html: newsItem.text }} />
          </Box>
        </Card>
      );
    }
  }

  let publicNews = null;
  if (data) {
    const newsItem = data.items.find((i: any) => i.role === 0);
    if (newsItem) {
      publicNews = (
        <Card withBorder mt="lg">
          <Group>
            <Avatar color="blue" radius="xl">
              <NewsLogo width={28} height={28} />
            </Avatar>
            <Title order={4}>{newsItem.name}</Title>
          </Group>

          <Box>
            <div dangerouslySetInnerHTML={{ __html: newsItem.text }} />
          </Box>
        </Card>
      );
    }
  }

  return (
    <Box>
      {templarNews}
      {publicNews}
    </Box>
  );
}

export default LatestItems;
