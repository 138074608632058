import { useState } from "react";

import { AppShell, MantineTheme, CSSObject, Box } from "@mantine/core";

import CustomHeader from "./CustomHeader";
import CustomNavbar from "./CustomNavbar";

const styles = (theme: MantineTheme): CSSObject => ({
  ".mantine-AppShell-main": {
    backgroundColor: theme.colors.gray[2],
  },
  ".nowrap": {
    whiteSpace: "nowrap",
  },
  ".pointer": {
    cursor: "pointer"
  }
});

const pageStyles = (theme: MantineTheme) => ({
  paddingTop: 30,
  paddingLeft: 20,
  paddingRight: 40,
  // Media query with value from theme
  [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
    paddingTop: 10,
    paddingLeft: 10,
    paddingRight: 10,
  },
});

function Shell(props: any) {
  const [opened, setOpened] = useState(false);

  return (
    <AppShell
      navbarOffsetBreakpoint="sm"
      fixed
      sx={styles}
      header={
        <CustomHeader opened={opened} height={70} setOpened={setOpened} />
      }
      navbar={
        <CustomNavbar
          opened={opened}
          setOpened={setOpened}
          width={{ sm: 220 }}
        />
      }
    >
      <Box sx={pageStyles}>{props.children}</Box>
    </AppShell>
  );
}

export default Shell;
