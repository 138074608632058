import { useCallback, useMemo, useState, useEffect } from "react";
import {
  MantineProvider,
  MantineThemeOverride,
  CSSObject,
  useMantineTheme,
  MantineTheme,
  Loader,
  Center,
} from "@mantine/core";
import { NotificationsProvider } from "@mantine/notifications";
import { BrowserRouter } from "react-router-dom";
import useAxios from "axios-hooks";
import cookies from "js-cookie";

import Shell from "./components/Layout/Shell";
import Routes from "./Routes";
import UserContext from "./utils/userContext";

import axiosClient from "./utils/axios";

function setToken(token: string) {
  if (!token) {
    //    axios.defaults.withCredentials = false;
    delete axiosClient.defaults.headers.common["Token"];
  } else {
    //    axios.defaults.withCredentials = true;
    axiosClient.defaults.headers.common["Token"] = `${token}`;
  }
}

const themeOverride: MantineThemeOverride = {
  colorScheme: "light",
  loader: "bars",
};

// "&.sticky": {
//   overflowY: "scroll",
//   height: 600,
//   width: "100%",
//   display: "block",
//   ".sticky-top": {
//     th: {
//       position: "sticky",
//       top: 0,
//       backgroundColor: "#FFF",
//       zIndex: 10,
//     },
//   },
//   ".sticky-bottom": {
//     td: {
//       borderTopWidth: 1,
//       borderTopStyle: "solid",
//       position: "sticky",
//       bottom: 0,
//       backgroundColor: theme.colors.gray[1],
//       zIndex: 10,
//     },
//   },

const styles = (
  theme: MantineTheme
): Record<string, Record<string, CSSObject>> => ({
  Card: {
    root: {
      borderColor: theme.colors.gray[5],
      ".link-btn": {
        cursor: "pointer",
        color: "#666",
      },
      "a:not(.mantine-Anchor-root)": {
        color: "#1c7ed6",
        textDecoration: "none",
        ":hover": {
          textDecoration: "underline",
        }
      },
    },
  },
  Modal: {
    header: {
      fontWeight: "bold",
    },
  },
  Table: {
    root: {
      th: {
        whiteSpace: "nowrap",
        "&.min": {
          width: "1%",
        },
      },
    },
  },
  TextInput: {
    input: {
      fontSize: "1em",
    },
  }
});

export function App() {
  const theme = useMantineTheme();
  const [userInternal, setUserInternal] = useState<any>(null);
  const [loadingUser, setLoadingUser] = useState(true);
  const [, getMe] = useAxios(
    {
      url: "/api/public/users/me",
      method: "GET",
    },
    {
      manual: true,
    }
  );

  const setUser = useCallback((user: any) => {
    setToken(user ? user.token : null);
    if (!user) {
      cookies.remove("token");
    } else {
      cookies.set("token", user.token, { expires: 365 });
    }
    setUserInternal(user);
  }, []);

  const updateUser = useCallback(
    (update: any) => {
      const newUser = {
        ...userInternal,
        ...update,
      };
      setUserInternal(newUser);
    },
    [userInternal]
  );

  const loadUser = useCallback(
    async (token) => {
      try {
        const { data: user } = await getMe({
          headers: {
            token: token,
          },
        });
        if (user) setUser(user);
      } catch (er: any) {
        console.log("Failed to load user: ", er.message);
      }
      setLoadingUser(false);
    },
    [getMe, setLoadingUser, setUser]
  );

  useEffect(() => {
    const token = cookies.get("token");
    if (token) {
      loadUser(token);
    } else {
      setLoadingUser(false);
    }
  }, [loadUser]);

  const userState = useMemo(() => {
    return {
      user: userInternal,
      setUser,
      updateUser,
    };
  }, [userInternal, setUser, updateUser]);

  return (
    <UserContext.Provider value={userState}>
      <BrowserRouter>
        <MantineProvider
          withNormalizeCSS
          withGlobalStyles
          theme={themeOverride}
          styles={styles(theme)}
        >
          <NotificationsProvider position="top-right">
            <Shell>
              {loadingUser ? (
                <Center>
                  <Loader />
                </Center>
              ) : (
                <Routes />
              )}
            </Shell>
          </NotificationsProvider>
        </MantineProvider>
      </BrowserRouter>
    </UserContext.Provider>
  );
}

export default App;
