import { useState, useCallback } from "react";
import JudgeForm from "./JudgeForm";
import Form from "./Form";

interface FormProps {
  item: any;
  onSave: (item: any) => void;
  onDelete: (item: any) => void;
  onClose: (item: any) => void;
}

function Modal({ item, onSave, onDelete, onClose }: FormProps) {

  const [tempItem, setTempItem] = useState(item);
  const [step, setStep] = useState(item._id ? 1 : 0);

  const onSaveJudges = useCallback((item) => {
    setTempItem(item);
    setStep(1);
  }, []);

  const onConfigure = useCallback((item) => {
    setTempItem(item);
    setStep(0);
  }, []);

  const onSaveDetails = useCallback((item) => {
    onSave(item);
  }, [onSave]);

  if (step === 0) {
    return <JudgeForm item={tempItem} onSave={onSaveJudges} />;
  } else {
    return <Form item={tempItem} onSave={onSaveDetails} onConfigure={onConfigure} onDelete={onDelete} onClose={onClose} />;
  }
}

export default Modal;
