import { useState, useCallback, useMemo, useEffect, useContext } from "react";
import {
  Card,
  Group,
  Loader,
  Center,
  Alert,
  Avatar,
  Select,
  Text,
  LoadingOverlay,
  Button,
  Title,
  Modal,
  ActionIcon,
  Checkbox,
  NumberInput,
  Divider,
} from "@mantine/core";
import { showNotification } from "@mantine/notifications";

import { Link, useParams, useNavigate } from "react-router-dom";
import useAxios from "axios-hooks";
import {
  ExclamationTriangleIcon,
  Pencil1Icon,
  EyeOpenIcon,
} from "@modulz/radix-icons";

import ArmourDetails from "../components/Armours/Details";
import ResponsiveTable from "../components/Layout/ResponsiveTable";

import { ReactComponent as ArmourSets } from "../assets/ArmourSets.svg";
import {
  elements,
  armourTypes,
  bodySlots,
  armourWeights,
} from "../utils/armourTools";

import Form from "../components/Sets/Form";
import ImportForm from "../components/Sets/ImportForm";
import Export from "../components/Sets/Export";

import UserContext from "../utils/userContext";

const defaultAlchiPower = 6;

const alchemistEnchantOptions = elements.filter(
  (e) => ["blunt", "sharp", "pierce"].indexOf(e) === -1
);
const ugiEnchantOptions = ["blunt", "sharp", "pierce"];

function _getValue(
  set: any,
  data: any,
  slot: string,
  element: string,
  alchiPower: number
) {
  const current = set.values[slot];
  if (current) {
    const item = data.items.find((i: any) => i._id === current);

    if (item) {
      const ugiElement = set.ugiEnchants[slot];
      const alchiElement = set.alchemistEnchants[slot];

      let number = item.values[element] || 0;

      const isUgi = ugiElement && ugiElement === element;
      const isAlchi = alchiElement && alchiElement === element;

      let color = undefined;
      let bold = isUgi || isAlchi;

      if (isUgi) {
        color = "red";
        number = 10;
      } else if (isAlchi) {
        color = "green";
        number += alchiPower;
      }
      number = Math.min(number, 10);

      const ac = number * armourWeights[item.type];

      if (number > 0) {
        return (
          <>
            <Text color={color} weight={bold ? "bold" : undefined}>
              {number}
            </Text>
            <Text color="gray" size="xs">
              {ac}
            </Text>
          </>
        );
      }
    }
  }
  return null;
}

function _getTotalValue(
  set: any,
  data: any,
  element: string,
  alchiPower: number
) {
  let total = 0;

  for (const slot of bodySlots) {
    const current = set.values[slot];
    if (current) {
      const item = data.items.find((i: any) => i._id === current);
      if (!item) continue;
      const ugiElement = set.ugiEnchants[slot];
      const alchiElement = set.alchemistEnchants[slot];

      let armourValue = item.values[element] || 0;

      if (ugiElement && ugiElement === element) {
        armourValue = 10;
      } else if (alchiElement && alchiElement === element) {
        armourValue += alchiPower;
      }

      armourValue = Math.min(10, armourValue);

      total += armourWeights[item.type] * armourValue;
    }
  }
  return total;
}

const defaultArgs: any = {
  sort: {
    name: 1,
  },
  page: 0,
  pageSize: 500,
  filters: {},
  items: [],
};

function SetBuilder() {
  const userState = useContext(UserContext);
  const navigate = useNavigate();
  const role = userState.user?.role || 0;
  const params = useParams();

  const [sortByProtection, setSortByProtection] = useState(false);
  const [error, setError] = useState(null);
  const [saving, setSaving] = useState(false);
  const [alchiPower, setAlchiPower] = useState<any>(defaultAlchiPower);
  const [showModal, setShowModal] = useState(false);
  const [showExportModal, setExportModal] = useState<boolean>(false);
  const [detailsModal, setDetailsModal] = useState<any>(null);
  const [showImportForm, setShowImportForm] = useState(false);

  const [set, setSet] = useState<any>({
    name: "",
    values: {},
    alchemistEnchants: {},
    ugiEnchants: {},
  });

  //
  // useAxioses

  //  const [{ data, loading, error: loadError }] = useAxios("/api/public/armours");

  const [{ data, loading, error: loadError }, searchItems] = useAxios(
    {
      url: "/api/public/armours/search",
      method: "POST",
    },
    {
      manual: true,
    }
  );

  const [, getSet] = useAxios({}, { manual: true });

  const [, deleteItem] = useAxios(
    {
      method: "DELETE",
    },
    {
      manual: true,
    }
  );

  const [, postItem] = useAxios(
    {
      url: "/api/templar/sets",
      method: "POST",
    },
    {
      manual: true,
    }
  );

  //
  // Effects

  useEffect(() => {
    const loadSet = async (_id: string) => {
      setSaving(true);
      try {
        const { data: loadedSet } = await getSet({
          url: `/api/templar/sets/${_id}`,
        });
        setSet(loadedSet);
      } catch (error: any) {
        setError(error.response?.data?.error || error.message);
      }
      setSaving(false);
    };
    if (params.id) {
      loadSet(params.id);
    }
  }, [params.id, getSet]);

  useEffect(() => {
    const args = {
      ...defaultArgs,
    };
    if (sortByProtection) {
      args.sort = {
        "values.total": -1,
        name: 1,
      };
    }
    searchItems({ data: args });
  }, [searchItems, sortByProtection]);

  //
  // Callbacks

  const onSortChange = useCallback((e) => {
    setSortByProtection(e.target.checked);
  }, []);

  const showDetailsModal = useCallback(
    (id) => {
      const item = data.items.find((i: any) => i._id === id);
      setDetailsModal({ ...item });
    },
    [data]
  );

  const saveSet = useCallback(async () => {
    if (role >= 2) {
      if (!set.name || set.role === undefined) {
        setShowModal(true);
        return;
      }

      setSaving(true);
      setError(null);

      try {
        const { data: newSet } = await postItem({ data: set });
        setSet(newSet);
        showNotification({
          message: "Set saved!",
        });
      } catch (error: any) {
        setError(error.response?.data?.error || error.message);
      }
      setSaving(false);
    } else {
      showNotification({
        title: "Permission Denied",
        message: "You need to be logged in to save sets.",
      });
    }
  }, [postItem, set, role]);

  // User wants to close the details modal
  const closeModals = useCallback(() => {
    setShowModal(false);
    setDetailsModal(null);
    setShowImportForm(false);
  }, []);

  // Used clicked edit details button
  const showEditForm = useCallback(() => {
    setShowModal(true);
  }, [setShowModal]);

  // User clicked delete in the details form
  const deleteSet = useCallback(async () => {
    setError(null);
    setSaving(true);
    setShowModal(false);

    try {
      await deleteItem({ url: `/api/templar/sets/${set._id}` });
      navigate("/sets");
    } catch (error: any) {
      setError(error.response?.data?.error || error.message);
      setSaving(false);
    }
  }, [set, navigate, deleteItem]);

  // User clicked save in the details form
  const updateSet = useCallback(
    async (values) => {
      const newItem = {
        ...set,
        ...values,
      };
      setShowModal(false);
      setError(null);

      // Save changes
      try {
        const { data: newSet } = await postItem({ data: newItem });
        setSet(newSet);
        showNotification({
          message: "Set saved!",
        });
      } catch (error: any) {
        setError(error.response?.data?.error || error.message);
      }
      setSaving(false);
    },
    [set, postItem]
  );

  const onSelectItem = useCallback(
    (value: string, slot: string) => {
      const newSet = {
        ...set,
      };
      newSet.values = {
        ...set.values,
        [slot]: value,
      };
      setSet(newSet);
    },
    [set]
  );

  const onSelectAlchiEnchant = useCallback(
    (value: string, slot: string) => {
      const newSet = {
        ...set,
      };
      newSet.alchemistEnchants = {
        ...set.alchemistEnchants,
        [slot]: value,
      };
      setSet(newSet);
    },
    [set]
  );

  const onSelectUgiEnchant = useCallback(
    (value: string, slot: string) => {
      const newSet = {
        ...set,
      };
      newSet.ugiEnchants = {
        ...set.ugiEnchants,
        [slot]: value,
      };
      setSet(newSet);
    },
    [set]
  );

  const onShowImportForm = useCallback(() => {
    setShowImportForm(true);
  }, []);

  const onShowExport = useCallback((value) => {
    setExportModal(value);
  }, []);

  //
  // Memo values

  // armour type options
  const options = useMemo(() => {
    if (!data) return null;
    const result: any = {};
    for (const armourType of armourTypes) {
      result[armourType] = data.items.filter((d: any) => d.type === armourType);
    }
    return result;
  }, [data]);

  //
  // Render

  const getValue = useCallback(
    (slot, element) => {
      return _getValue(set, data, slot, element, alchiPower);
    },
    [set, data, alchiPower]
  );

  const getTotalValue = useCallback(
    (element) => {
      if (!data) return 0;
      return _getTotalValue(set, data, element, alchiPower);
    },
    [set, data, alchiPower]
  );

  const totalRow = useMemo(() => {
    return elements.map((e: any) => {
      const value = getTotalValue(e);
      return (
        <th key={e}>
          <Text size="sm">{e.substring(0, 3)}</Text>
          <Text weight="normal" size="sm">
            {value}
          </Text>
        </th>
      );
    });
  }, [getTotalValue]);

  return (
    <div>
      <LoadingOverlay visible={saving} />
      <Group position="apart">
        <Group>
          <Avatar color="blue" size="lg" radius="xl">
            <ArmourSets width={32} height={32} />
          </Avatar>
          <Title order={1}>{set.name || "No Name"}</Title>
          {role >= 1 && (
            <Button
              onClick={showEditForm}
              disabled={saving}
              variant="light"
              leftIcon={<Pencil1Icon width={16} height={16} />}
            >
              Edit
            </Button>
          )}
        </Group>
        <Group>
          <Button variant="outline" onClick={onShowImportForm}>
            Import
          </Button>
          <Button mr="lg" variant="outline" onClick={() => onShowExport(true)}>
            Export
          </Button>
          <Button component={Link} to="/sets" variant="outline">
            Back
          </Button>
          <Button onClick={saveSet} disabled={saving}>
            Save
          </Button>
        </Group>
      </Group>

      {(error || loadError) && (
        <Alert
          mt="lg"
          icon={<ExclamationTriangleIcon />}
          title="Bummer!"
          color="red"
        >
          {error || loadError?.response?.data?.error || loadError?.message}
        </Alert>
      )}

      {set.description && (
        <Card withBorder mt="lg">
          <Text>{set.description}</Text>
        </Card>
      )}

      <Card withBorder mt="lg">
        {loading || !options ? (
          <Center>
            <Loader />
          </Center>
        ) : (
          <>
            <Group>
              <NumberInput
                size="xs"
                value={alchiPower}
                onChange={(val) => setAlchiPower(val)}
                placeholder="1 to 10"
                styles={{ wrapper: { width: 75 } }}
                min={1}
                max={10}
                required
              />
              <Text size="sm">Alchemist Enchant Amount</Text>
              <Checkbox
                ml="lg"
                label="Sort by protection"
                checked={sortByProtection}
                onChange={onSortChange}
              />
            </Group>
            <Divider mt="md" />
            <ResponsiveTable verticalSpacing="xs" striped>
              <thead>
                <tr>
                  <th>Part</th>
                  {totalRow}
                  <th>Tot</th>
                </tr>
              </thead>
              <tbody>
                {bodySlots.map((i: any) => {
                  let type = i;
                  if (type === "ring1" || type === "ring2") type = "ring";

                  const current = set.values[i];
                  let rowTotal = 0;
                  let rowTotalAc = 0;

                  if (current) {
                    const item = data.items.find(
                      (it: any) => it._id === current
                    );
                    if (item) {
                      const ugiElement = set.ugiEnchants[i];
                      const alchiElement = set.alchemistEnchants[i];

                      if (ugiElement || alchiElement) {
                        for (const element of elements) {
                          const isUgi = ugiElement && ugiElement === element;
                          const isAlchi =
                            alchiElement && alchiElement === element;

                          let armourValue = item.values[element] || 0;

                          if (isUgi) {
                            armourValue = 10;
                          } else if (isAlchi) {
                            armourValue += alchiPower;
                          }

                          armourValue = Math.min(10, armourValue);

                          rowTotal += armourValue;
                          rowTotalAc += armourValue * armourWeights[item.type];
                        }
                      } else {
                        rowTotal += item.values.total;
                        rowTotalAc +=
                          item.values.total * armourWeights[item.type];
                      }
                    }
                  }
                  return (
                    <tr key={i}>
                      <td>
                        <Group>
                          <Select
                            size="xs"
                            clearable
                            onChange={(value: string) => onSelectItem(value, i)}
                            style={{ width: 265 }}
                            placeholder={`Select ${i}`}
                            value={set.values[i]}
                            data={options[type].map((o: any) => ({
                              value: o._id,
                              label: `${o.name} (${o.values.total})`,
                            }))}
                          />
                          {set.values[i] && (
                            <ActionIcon
                              onClick={() => showDetailsModal(set.values[i])}
                            >
                              <EyeOpenIcon />
                            </ActionIcon>
                          )}
                        </Group>
                        <Group mt="sm">
                          <Select
                            size="xs"
                            clearable
                            onChange={(value: string) =>
                              onSelectUgiEnchant(value, i)
                            }
                            style={{ width: 150 }}
                            placeholder="UGI enchant"
                            value={set.ugiEnchants[i]}
                            data={ugiEnchantOptions.map((o: any) => ({
                              value: o,
                              label: o,
                            }))}
                          />
                          <Select
                            size="xs"
                            clearable
                            onChange={(value: string) =>
                              onSelectAlchiEnchant(value, i)
                            }
                            style={{ width: 150 }}
                            placeholder="Alchemist enchant"
                            value={set.alchemistEnchants[i]}
                            data={alchemistEnchantOptions.map((o: any) => ({
                              value: o,
                              label: o,
                            }))}
                          />
                        </Group>
                      </td>
                      {elements.map((e: any) => {
                        return <td key={e}>{getValue(i, e)}</td>;
                      })}
                      <td>
                        <Text>{rowTotal}</Text>
                        <Text color="gray" size="xs">
                          {rowTotalAc}
                        </Text>
                      </td>
                    </tr>
                  );
                })}
                <tr>
                  <td>
                    <b>Total AC:</b>
                  </td>
                  {totalRow}
                </tr>
              </tbody>
            </ResponsiveTable>
          </>
        )}
      </Card>
      <Modal
        size="lg"
        padding="xl"
        opened={showModal || !!detailsModal || showImportForm}
        onClose={closeModals}
        title={
          showImportForm
            ? "Import Armour Set"
            : detailsModal
            ? detailsModal.name
            : "Edit Armour Set"
        }
      >
        {showModal && (
          <Form
            item={set}
            onSave={updateSet}
            onDelete={deleteSet}
            onClose={closeModals}
          />
        )}
        {detailsModal && (
          <ArmourDetails item={detailsModal} onClose={closeModals} />
        )}
        {showImportForm && (
          <ImportForm
            onClose={closeModals}
            onSave={setSet}
            items={data.items}
          />
        )}
      </Modal>

      <Modal
        size="lg"
        padding="xl"
        opened={showExportModal}
        onClose={() => setExportModal(false)}
        title="Export"
      >
        {showExportModal && (
          <Export
            item={set}
            armours={data}
            onClose={() => setExportModal(false)}
          />
        )}
      </Modal>
    </div>
  );
}

export default SetBuilder;
