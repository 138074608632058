import { useState, useCallback, useContext, useEffect, useMemo } from "react";
import {
  Title,
  Card,
  Group,
  Modal,
  Center,
  Button,
  LoadingOverlay,
  Alert,
  Avatar,
} from "@mantine/core";
import { useNotifications } from "@mantine/notifications";

import useAxios from "axios-hooks";
import { ExclamationTriangleIcon } from "@modulz/radix-icons";

import Form from "../components/Weapons/Modal";
import ChipsFilter from "../components/Forms/ChipsFilter";
import Details from "../components/Weapons/Details";
import ResponsiveTable from "../components/Layout/ResponsiveTable";
import { ReactComponent as WeaponsLogo } from "../assets/Weapons.svg";
import TH from "../components/Layout/TH";

import UserContext from "../utils/userContext";
import { renderBadge } from "../utils/userTools";
import { weaponTypes } from "../utils/weaponTools";

const defaultArgs = {
  sort: {
    name: 1,
  },
  page: 0,
  filters: {},
  items: [],
};

let lastArgs: any = null;

const getType = function (i: string) {
  const split = i.split(".");
  return split[split.length - 1];
};


function Weapons() {
  const userState = useContext(UserContext);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);
  const [editModal, setEditModal] = useState<any>(null);
  const [detailsModal, setDetailsModal] = useState<any>(null);
  const notifications = useNotifications();

  const [items, setItems] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [args, setArgs] = useState<SearchArgs>(defaultArgs);
  const [hasMore, setHasMore] = useState(false);

  const role = userState.user?.role || 0;

  //
  // Axios

  const [, searchItems] = useAxios(
    {
      url: "/api/public/weapons/search",
      method: "POST",
    },
    {
      manual: true,
    }
  );

  const [, postItem] = useAxios(
    {
      url: "/api/guest/weapons",
      method: "POST",
    },
    {
      manual: true,
    }
  );

  const [, deleteItem] = useAxios(
    {
      method: "DELETE",
    },
    {
      manual: true,
    }
  );

  //
  // Callbacks

  const closeDetailsModal = useCallback(() => {
    setDetailsModal(null);
  }, []);

  const showEditModal = useCallback(
    (item: any) => {
      if (role === 0) {
        notifications.showNotification({
          title: "Permission Denied",
          message: "You need to be logged in to add weapon pieces.",
        });
      } else {
        setDetailsModal(null);
        setEditModal(item.type === "click" ? {} : item);
      }
    },
    [role, notifications]
  );

  const closeEditModal = useCallback(() => {
    setEditModal(null);
  }, []);

  const deleteWeapon = useCallback(
    async (item) => {
      if (items === null) {
        return;
      }
      setError(null);
      setSaving(true);
      setEditModal(null);

      try {
        await deleteItem({ url: `/api/guest/weapons/${item._id}` });
        const newItems = items.filter((i) => i._id !== item._id);
        setItems(newItems);
      } catch (error: any) {
        setError(error.response?.data?.error || error.message);
      }
      setSaving(false);
    },
    [deleteItem, items]
  );

  const saveWeapon = useCallback(
    async (item) => {
      setError(null);
      setSaving(true);
      setEditModal(null);

      try {
        // Need to add id
        if (editModal._id) item._id = editModal._id;

        const { data } = await postItem({ data: item });
        if (item._id) {
          // Update old item
          const oldIndex = items.findIndex((i) => i._id === item._id);
          if (oldIndex >= 0) {
            const newItems = [...items];
            newItems[oldIndex] = data;
            setItems(newItems);
          }
        } else {
          const newItems = [...items];
          newItems.unshift(data);
          setItems(newItems);
        }
      } catch (error: any) {
        setError(error.response?.data?.error || error.message);
      }

      setSaving(false);
    },
    [editModal, postItem, items]
  );

  //
  // Searching

  const doSearchItems = useCallback(async () => {
    try {
      setLoading(true);
      const { data: result } = await searchItems({
        data: {
          filters: args.filters,
          page: args.page,
          pageSize: 20,
          sort: args.sort,
        },
      });
      setItems(args.page === 0 ? result.items : items.concat(result.items));
      setHasMore(result.hasMore);
      setLoading(false);
    } catch (error: any) {
      setError(error.response?.data?.error || error.message);
    }
  }, [args, items, searchItems]);

  const onFiltersChange = useCallback(
    (value: any) => {
      const newFilters = {
        ...args.filters,
        ...value,
      };
      if(newFilters.type !== undefined && !newFilters.type) {
        delete newFilters.type;
      }
      setArgs({
        ...args,
        page: 0,
        filters: newFilters,
      });
    },
    [args]
  );

  const loadMore = useCallback(() => {
    const newPage = args.page + 1;
    setArgs({
      ...args,
      page: newPage,
    });
  }, [args]);

  const onSortChange = useCallback(
    (value) => {
      setArgs({
        ...args,
        page: 0,
        sort: value,
      });
    },
    [args]
  );

  //
  // Effects

  useEffect(() => {
    lastArgs = null;
  }, []);

  useEffect(() => {
    if (lastArgs !== args) {
      lastArgs = args;
      doSearchItems();
    }
  }, [doSearchItems, args]);

  const filterOptions = useMemo(() => {
    return weaponTypes.map((o) => ({ value: o, label: o }));
  }, []);

  return (
    <div>
      <LoadingOverlay visible={saving} />
      <Group position="apart">
        <Group>
          <Avatar color="blue" size="lg" radius="xl">
            <WeaponsLogo width={32} height={32} />
          </Avatar>
          <Title order={1}>Weapons</Title>
        </Group>
        <Group>
          <Button onClick={showEditModal}>Create</Button>
        </Group>
      </Group>

      {error && (
        <Alert
          mt="lg"
          icon={<ExclamationTriangleIcon />}
          title="Bummer!"
          color="red"
        >
          {error}
        </Alert>
      )}

      <Card withBorder mt="lg">
        <ChipsFilter
          onChange={onFiltersChange}
          value={args.filters.type}
          options={filterOptions}
        />
      </Card>

      <Card withBorder mt="lg">
        <ResponsiveTable verticalSpacing="xs" striped>
          <thead>
            <tr>
              <TH onChange={onSortChange} current={args.sort} field="name">
                Name
              </TH>
              <TH
                onChange={onSortChange}
                current={args.sort}
                field="type"
                andThen="name"
              >
                Type
              </TH>
              <TH
                onChange={onSortChange}
                current={args.sort}
                field="values.limbs"
                andThen="name"
              >
                Limbs
              </TH>
              <TH
                onChange={onSortChange}
                current={args.sort}
                field="saveLevel"
                andThen="name"
              >
                Level
              </TH>
              <th className="min"></th>
            </tr>
          </thead>
          <tbody>
            {!loading && items?.length === 0 && (
              <tr>
                <td colSpan={5}>No items</td>
              </tr>
            )}
            {items?.map((i: any) => (
              <tr key={i._id}>
                <td>
                  <Button variant="subtle" onClick={() => setDetailsModal(i)}>
                    {i.name}
                  </Button>
                </td>
                <td>{getType(i.type)}</td>
                <td>{i.limbs}</td>
                <td>{i.saveLevel}</td>
                <td>{i.role > 0 ? renderBadge(i.role) : null}</td>
              </tr>
            ))}
          </tbody>
        </ResponsiveTable>
        <Center mt="xl">
          {hasMore && (
            <Button loading={loading} variant="outline" onClick={loadMore}>
              Load More
            </Button>
          )}
        </Center>
      </Card>
      <Modal
        size="lg"
        padding="xl"
        opened={!!editModal || !!detailsModal}
        onClose={editModal ? closeEditModal : closeDetailsModal}
        title={detailsModal ? detailsModal.name : "Create Weapon"}
      >
        {editModal && (
          <Form
            item={editModal}
            onSave={saveWeapon}
            onDelete={deleteWeapon}
            onClose={closeEditModal}
          />
        )}
        {detailsModal && (
          <Details
            item={detailsModal}
            onClose={closeDetailsModal}
            onEdit={role > 0 ? showEditModal : undefined}
          />
        )}
      </Modal>
    </div>
  );
}

export default Weapons;
