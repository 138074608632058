
import axios from "axios";
import { configure } from "axios-hooks";

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

configure({ axios: axiosClient, cache: false });

export default axiosClient;