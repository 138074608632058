import { useCallback, useContext, useState } from "react";
import {
  TextInput,
  Button,
  Select,
  Group,
  Grid,
  Textarea,
} from "@mantine/core";

import { useForm } from "@mantine/hooks";
import UserContext from "../../utils/userContext";
import { roleOptions } from "../../utils/userTools";

import { logTypes } from "../../utils/logTools";
import FileZone from "../Forms/FileZone";

interface FormProps {
  item: any;
  hideUpload: boolean;
  onSave: (item: any, file?: any) => void;
  onDelete: (item: any) => void;
  onClose: (item: any) => void;
}

function Form({ item, onSave, onDelete, onClose, hideUpload }: FormProps) {
  const userState = useContext(UserContext);
  const role = userState.user?.role || 0;
  const [file, setFile] = useState<any>(null);

  const form = useForm({
    initialValues: {
      name: item?.name || "",
      role: item?.role?.toString() || Math.min(role || 2, 2).toString(),
      description: item?.description || "",
      logType: "cmud-html",
      type: item?.type || "Raids",
      file: null,
    },
  });
  const onDeleteClick = useCallback(() => {
    if (window.confirm("Are you sure you want to delete this item?")) {
      onDelete(item);
    }
  }, [item, onDelete]);

  const onSubmit = useCallback(
    (values: typeof form["values"]) => {
      onSave(values, file);
    },
    [onSave, file]
  );

  const onDropFile = useCallback((files) => {
    setFile(files[0]);
  }, []);

  return (
    <>
      <form onSubmit={form.onSubmit(onSubmit)}>
        <TextInput
          required
          label="Name"
          placeholder="Name"
          {...form.getInputProps("name")}
        />
        <Grid mt="md">
          <Grid.Col xs={6}>
            <Select
              required
              label="Type"
              placeholder="Select a type"
              {...form.getInputProps("type")}
              data={logTypes}
            />
          </Grid.Col>
          <Grid.Col xs={6}>
            <Select
              required
              label="Permission"
              placeholder="Select a role"
              {...form.getInputProps("role")}
              data={roleOptions.slice(0, role + 1)}
            />
          </Grid.Col>
        </Grid>
        <Textarea
          mt="lg"
          label="Description"
          placeholder="Description about the log"
          {...form.getInputProps("description")}
        />

        {!hideUpload && (
          <FileZone
            mt="xl"
            onDropFile={onDropFile}
            file={file}
            maxFileSize={5}
            accept={["text/plain", "text/html"]}
          />
        )}

        <Group mt="xl">
          <Button type="submit">Save</Button>
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          {item._id && (
            <Button
              variant="outline"
              ml="xl"
              color="red"
              onClick={onDeleteClick}
            >
              Delete
            </Button>
          )}
        </Group>
      </form>
    </>
  );
}

export default Form;
