import { useState, useContext, useCallback, useEffect } from "react";
import {
  Title,
  Card,
  Group,
  Modal,
  Center,
  Avatar,
  Button,
  Alert,
  LoadingOverlay,
  Checkbox,
} from "@mantine/core";
import { useNotifications } from "@mantine/notifications";

import useAxios from "axios-hooks";
import { ExclamationTriangleIcon } from "@modulz/radix-icons";
import { ReactComponent as QuestsLogo } from "../assets/Quests.svg";

import Form from "../components/Quests/Form";
import Details from "../components/Quests/Details";
import ChipsFilter from "../components/Forms/ChipsFilter";
import ResponsiveTable from "../components/Layout/ResponsiveTable";
import TH from "../components/Layout/TH";

import UserContext from "../utils/userContext";
import { renderBadge } from "../utils/userTools";

const rewardOptions = [
  "Xp",
  "Money",
  "Item",
  "Stat",
  "Other",
  "Nothing",
  "Unknown",
].map((o) => ({ label: o, value: o }));

const defaultArgs = {
  sort: {
    level: 1,
    name: 1,
  },
  page: 0,
  filters: {},
  items: [],
};

let lastArgs: any = null;

const project = {
  active: 1,
  name: 1,
  level: 1,
  reward: 1,
  location: 1,
  role: 1,
};

function Quests() {
  const userState = useContext(UserContext);
  const [saving, setSaving] = useState(false);
  const [savingSettings, setSavingSettings] = useState(false);
  const [error, setError] = useState(null);
  const [editModal, setEditModal] = useState<any>(null);
  const [detailsModal, setDetailsModal] = useState<any>(null);
  const notifications = useNotifications();

  const [items, setItems] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [args, setArgs] = useState<SearchArgs>(defaultArgs);
  const [hasMore, setHasMore] = useState(false);

  const role = userState.user?.role || 0;

  //
  // Axios

  const [, searchItems] = useAxios(
    {
      url: "/api/public/quests/search",
      method: "POST",
    },
    {
      manual: true,
    }
  );

  const [, postItem] = useAxios(
    {
      url: "/api/templar/quests",
      method: "POST",
    },
    {
      manual: true,
    }
  );

  const [, updateSettings] = useAxios(
    {
      url: "/api/guest/users/me/settings",
      method: "POST",
    },
    {
      manual: true,
    }
  );

  const [, deleteItem] = useAxios(
    {
      method: "DELETE",
    },
    {
      manual: true,
    }
  );

  //
  // Callbacks

  const closeDetailsModal = useCallback(() => {
    setDetailsModal(null);
  }, []);

  const showEditModal = useCallback(
    (item: any) => {
      if (role === 0) {
        notifications.showNotification({
          title: "Permission Denied",
          message: "You need to be logged in to add quests.",
        });
      } else if (role === 1) {
        notifications.showNotification({
          title: "Permission Denied",
          message: "You need to higher permission to add quests.",
        });
      } else {
        setDetailsModal(null);
        setEditModal(item || {});
      }
    },
    [role, notifications]
  );

  const closeEditModal = useCallback(() => {
    setEditModal(null);
  }, []);

  const deleteQuest = useCallback(
    async (item) => {
      setError(null);
      setSaving(true);
      setEditModal(null);

      try {
        await deleteItem({ url: `/api/templar/quests/${item._id}` });
        const newItems = items.filter((i) => i._id !== item._id);
        setItems(newItems);
      } catch (error: any) {
        setError(error.response?.data?.error || error.message);
      }
      setSaving(false);
    },
    [deleteItem, items]
  );

  const saveQuest = useCallback(
    async (item) => {
      setError(null);
      setSaving(true);
      setEditModal(null);

      try {
        // Need to add id
        if (editModal._id) item._id = editModal._id;

        if (item.solution)
          item.solution = item.solution.replace(/\n/g, "<br />");

        const { data } = await postItem({ data: item });
        if (item._id) {
          // Update old item
          const oldIndex = items.findIndex((i) => i._id === item._id);
          if (oldIndex >= 0) {
            const newItems = [...items];
            newItems[oldIndex] = data;
            setItems(newItems);
          }
        } else {
          const newItems = [...items];
          newItems.unshift(data);
          setItems(newItems);
        }
      } catch (error: any) {
        setError(error.response?.data?.error || error.message);
      }

      setSaving(false);
    },
    [editModal, postItem, items]
  );

  const isCompleted = useCallback(
    (quest) => {
      const settings = userState.user?.quests;
      if (settings) {
        return !!settings[quest._id];
      }
      return false;
    },
    [userState.user]
  );

  const changeCompleted = useCallback(
    async (questId, value) => {
      if (role === 0) {
        notifications.showNotification({
          title: "Permission Denied",
          message: "You need to be logged in to save quest progress.",
        });
        return;
      }

      setSavingSettings(true);
      const quests = userState.user?.quests || {};
      const newQuests = {
        ...quests,
        [questId]: value,
      };
      userState.updateUser({
        quests: newQuests,
      });
      await updateSettings({ data: { quests: newQuests } });
      setSavingSettings(false);
    },
    [userState, updateSettings, notifications, role]
  );

  const resetProgres = useCallback(async () => {
    setSavingSettings(true);
    userState.updateUser({
      quests: {},
    });
    await updateSettings({ data: { quests: {} } });
    setSavingSettings(false);
  }, [userState, updateSettings]);

  //
  // Searching

  const doSearchItems = useCallback(async () => {
    try {
      setLoading(true);
      const { data: result } = await searchItems({
        data: {
          filters: args.filters,
          page: args.page,
          pageSize: 20,
          sort: args.sort,
          project,
        },
      });
      setItems(args.page === 0 ? result.items : items.concat(result.items));
      setHasMore(result.hasMore);
      setLoading(false);
    } catch (error: any) {
      setError(error.response?.data?.error || error.message);
    }
  }, [args, items, searchItems]);

  const onFiltersChange = useCallback(
    (value: any) => {
      const newFilters = {
        ...args.filters,
        ...value,
      };
      if (newFilters.reward !== undefined && !newFilters.reward) {
        delete newFilters.reward;
      }
      setArgs({
        ...args,
        page: 0,
        filters: newFilters,
      });
    },
    [args]
  );

  const loadMore = useCallback(() => {
    const newPage = args.page + 1;
    setArgs({
      ...args,
      page: newPage,
    });
  }, [args]);

  const onSortChange = useCallback(
    (value) => {
      setArgs({
        ...args,
        page: 0,
        sort: value,
      });
    },
    [args]
  );

  //
  // Effects

  useEffect(() => {
    lastArgs = null;
  }, []);

  useEffect(() => {
    if (lastArgs !== args) {
      lastArgs = args;
      doSearchItems();
    }
  }, [doSearchItems, args]);

  const numDone = userState.user?.quests
    ? Object.keys(userState.user.quests).length
    : 0;

  return (
    <>
      <LoadingOverlay visible={saving} />

      <Group position="apart">
        <Group>
          <Avatar color="blue" size="lg" radius="xl">
            <QuestsLogo width={32} height={32} />
          </Avatar>
          <Title order={1}>Quests</Title>
        </Group>
        <Group>
          {userState.user && numDone > 0 && (
            <Button variant="outline" onClick={resetProgres}>
              Reset Done ({numDone})
            </Button>
          )}
          <Button onClick={showEditModal}>Create</Button>
        </Group>
      </Group>

      {error && (
        <Alert
          mt="lg"
          icon={<ExclamationTriangleIcon />}
          title="Bummer!"
          color="red"
        >
          {error}
        </Alert>
      )}

      <Card withBorder mt="lg">
        <ChipsFilter
          onChange={onFiltersChange}
          name="reward"
          value={args.filters.reward}
          options={rewardOptions}
        />
      </Card>

      <Card withBorder mt="lg">
        <ResponsiveTable verticalSpacing="xs" striped>
          <thead>
            <tr>
              <th className="min">Done</th>
              <TH onChange={onSortChange} current={args.sort} field="name">
                Name
              </TH>
              <TH
                onChange={onSortChange}
                current={args.sort}
                field="location"
                andThen="name"
              >
                Location
              </TH>
              <TH
                onChange={onSortChange}
                current={args.sort}
                field="level"
                andThen="name"
              >
                Level
              </TH>
              <th className="min">Reward</th>
              <th className="min">Permission</th>
            </tr>
          </thead>
          <tbody>
            {!loading && items?.length === 0 && (
              <tr>
                <td colSpan={6}>No items</td>
              </tr>
            )}
            {items?.map((i: any, index: number) => (
              <tr key={i._id}>
                <td>
                  <Center>
                    <Checkbox
                      disabled={savingSettings}
                      checked={isCompleted(i)}
                      onChange={(e) => changeCompleted(i._id, e.target.checked)}
                    />
                  </Center>
                </td>
                <td className="nowrap">
                  {index + 1}.
                  <Button variant="subtle" onClick={() => setDetailsModal(i)}>
                    {i.name}
                  </Button>
                </td>
                <td>{i.location}</td>
                <td>{i.level}</td>
                <td>{(i.reward || []).join(", ")}</td>
                <td>{renderBadge(i.role)}</td>
              </tr>
            ))}
          </tbody>
        </ResponsiveTable>
        <Center mt="xl">
          {hasMore && (
            <Button loading={loading} variant="outline" onClick={loadMore}>
              Load More
            </Button>
          )}
        </Center>
      </Card>
      <Modal
        sx={(theme) => ({
          [`@media (min-width: ${theme.breakpoints.sm}px)`]: {
            ".mantine-Modal-modal": {
              width: "80%",
            },
          },
        })}
        className="large"
        size="lg"
        padding="xl"
        opened={!!editModal || !!detailsModal}
        onClose={editModal ? closeEditModal : closeDetailsModal}
        title={
          detailsModal?.name || (editModal?.name ? "Edit Quest" : "New Quest")
        }
      >
        {editModal && (
          <Form
            url="quests"
            item={editModal}
            onSave={saveQuest}
            onDelete={deleteQuest}
            onClose={closeEditModal}
          />
        )}
        {detailsModal && (
          <Details
            url="quests"
            item={detailsModal}
            onClose={closeDetailsModal}
            onEdit={role > 1 ? showEditModal : undefined}
          />
        )}
      </Modal>
    </>
  );
}

export default Quests;
