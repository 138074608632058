import { Text, Button, Group, SimpleGrid, Anchor } from "@mantine/core";
import { Link } from "react-router-dom";
import { renderBadge } from "../../utils/userTools";

interface FormProps {
  item: any;
  onClose?: (item: any) => void;
  onEdit?: (item: any) => void;
  hideButtons?: boolean;
}

const getType = function (i: string) {
  const split = i.split(".");
  return split[split.length - 1];
};

function Details({ item, onClose, onEdit, hideButtons }: FormProps) {
  return (
    <div>
      <SimpleGrid
        cols={3}
        mt="xl"
        breakpoints={[
          { maxWidth: "sm", cols: 2 },
          { maxWidth: "xs", cols: 1 },
        ]}
      >
        <Group direction="column" spacing="xs">
          <Text size="xs">Type</Text>
          <Text>{getType(item.type)}</Text>
        </Group>
        <Group direction="column" spacing="xs">
          <Text size="xs">Limbs</Text>
          <Text>{item.limbs}</Text>
        </Group>
        <Group direction="column" spacing="xs">
          <Text size="xs">Save Level</Text>
          <Text>
            {item.saveLevel || item.saveLevel === 0
              ? item.saveLevel
              : "Unknown"}
          </Text>
        </Group>
        <Group direction="column" spacing="xs">
          <Text size="xs">Created by</Text>
          <Text>{item.creator?.playerName}</Text>
        </Group>
        <Group direction="column" spacing="xs">
          <Text size="xs">URL</Text>
          <Anchor component={Link} to={`/weapons/${item._id}`}>
            Link
          </Anchor>
        </Group>
        <Group direction="column" spacing="xs">
          <Text size="xs">Permission</Text>
          {renderBadge(item.role)}
        </Group>
      </SimpleGrid>

      {item.notes && (
        <Group mt="xl" direction="column" spacing="xs">
          <Text size="xs">Notes</Text>
          <Text>{item.notes}</Text>
        </Group>
      )}

      <Group mt="xl" direction="column" spacing="xs">
        <Text size="xs">Location</Text>
        <Text>{item.location}</Text>
      </Group>

      <Group mt="xl" direction="column" spacing="xs">
        <Text size="xs">Judges (Temporary)</Text>
        <Text>
          {item.judges.map((j: any, i: number) => (
            <Text size="sm" key={i}>{j}</Text>
          ))}
        </Text>
      </Group>

      {!hideButtons && (
        <Group mt="xl">
          <Button onClick={onClose}>Close</Button>
          {onEdit && (
            <Button variant="outline" onClick={() => onEdit(item)}>
              Edit
            </Button>
          )}
        </Group>
      )}
    </div>
  );
}

export default Details;
