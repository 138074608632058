import { useCallback, useState } from "react";
import { Alert, Button, Group, Text, Textarea } from "@mantine/core";
import { useForm } from "@mantine/hooks";
import { ExclamationTriangleIcon } from "@modulz/radix-icons";

import { elements, toScore } from "../../utils/armourTools";

function detectItems(raws: string[], items: any[]) {
  // Merge the rows if we have multiliners
  const rows: string[] = [];
  let current: string | null = null;
  for (const raw of raws) {
    if (raw.startsWith("The ")) {
      current = raw;
    } else {
      current = `${current} ${raw}`;
    }
    if (current?.endsWith(".")) {
      rows.push(current);
      current = null;
    }
  }

  // Find all names
  const typeFilterA = new RegExp(/(.*) is a (\w+)\.$/);
  const typeFilterAn = new RegExp(/(.*) is an (\w+)/);

  const rowTypes = rows.filter(
    (r) => typeFilterA.test(r) || typeFilterAn.test(r)
  );

  const result = [];
  const failures = [];

  for (const rowType of rowTypes) {
    const match = typeFilterA.test(rowType)
      ? rowType.match(typeFilterA)
      : rowType.match(typeFilterAn);

    if (match && match.length >= 3) {
      const rawName = match[1];
      let name = rawName;
      const type = match[2];
      let ugi = false;

      if (rawName.startsWith("The Ultimately Gnomishly Insulated ")) {
        name = rawName.replace(/^The Ultimately Gnomishly Insulated /, "");
        ugi = true;
      } else {
        name = rawName.replace(/^The /, "");
      }

      // Find item in database
      const armour = items.find((i) => i.name === name);

      try {
        if (!armour)
          throw new Error("Could not find item in database");

        if(armour.type !== type)
          throw new Error("Armour in database had other type: " + armour.type);

        // Check unique name
        if (rowTypes.filter((r) => r === rowType).length > 1) {
          throw new Error(
            "Cannot detect multipe items with the same name, sorry"
          );
        }

        // Parse the judgements on this item
        const itemRows = rows.filter((r) => r.startsWith(rawName));
        const itemJudgeMatches = itemRows
          .map((r) => r.match(/is (.+) at protecting from (.+) attacks\.$/))
          .filter((r) => r !== null && r.length === 3);

        const values: any = {};

        let foundUgiElement: string | null = null;
        let foundAlchiElement: string | null = null;

        for (const itemJudgeMatch of itemJudgeMatches) {
          if (itemJudgeMatch !== null) {
            const element = itemJudgeMatch[2];

            if (elements.indexOf(element) === -1) {
              throw new Error(`Unknown element '${element}'`);
            }
            values[element] = toScore(itemJudgeMatch[1]);

            // Is it different?
            if (values[element] !== (armour.values[element] || 0)) {
              const isPhysical =
                ["sharp", "blunt", "pierce"].indexOf(element) >= 0;

              if (ugi && isPhysical) {
                if (foundUgiElement) {
                  throw new Error(
                    `Found multiple possible UGI values: ${element} and ${foundUgiElement}`
                  );
                }
                foundUgiElement = element;
                continue;
              } else if (!isPhysical) {
                // Alchi?
                if (foundAlchiElement === null) {
                  const diff = values[element] - armour.values[element];
                  if (diff > 0) {
                    foundAlchiElement = element;
                    continue;
                  }
                }
              }

              throw new Error(
                "Found missmatch with armour in database: " + element
              );
            }
          }
        }
        result.push({
          name,
          type,
          ugi: foundUgiElement,
          alchi: foundAlchiElement,
          armour,
        });
      } catch (er: any) {
        failures.push({
          name,
          error: er.message,
        });
      }
    }
  }

  return {
    success: result,
    failures,
  };
}

interface FormProps {
  items: any[];
  onSave: (item: any) => void;
  onClose: () => void;
}

function JudgeForm({ items, onSave, onClose }: FormProps) {
  const [error, setError] = useState<string | null>(null);

  const [result, setResult] = useState<any>(null);

  const form = useForm({
    initialValues: {
      judge: "",
    },
  });

  const onSubmit = useCallback(
    (values: typeof form["values"]) => {
      try {
        setError(null);
        const raws = values.judge.split("\n");
        const result = detectItems(raws, items);

        const set: any = {
          name: "",
          values: {},
          alchemistEnchants: {},
          ugiEnchants: {},
        };

        let rings = 0;
        for (const item of result.success) {
          let type = item.type;
          if (type === "ring") {
            if (rings < 2) {
              rings++;
              type = `ring${rings}`;
            }
          }
          if (set.values[type]) continue;

          set.values[type] = item.armour._id;
          if (item.ugi) {
            set.ugiEnchants[type] = item.ugi;
          }
          if (item.alchi) {
            set.alchemistEnchants[type] = item.alchi;
          }
        }

        onSave(set);

        if (result.failures.length > 0) {
          setResult(result);
        } else {
          onClose();
        }
      } catch (error: any) {
        setError(error.message);
      }
    },
    [items, onClose, onSave]
  );

  if (result?.failures.length > 0) {
    return (
      <>
        <Alert
          mt="lg"
          icon={<ExclamationTriangleIcon />}
          title="Failed to import"
          color="red"
        >
          {result.failures.map((f: any, i: number) => (
            <Text size="sm" key={i} mb="sm">
              <b>{f.name}</b>: {f.error}
            </Text>
          ))}
        </Alert>

        <Text mt="xl">
          Successfully imported {result.success.length} item(s).
        </Text>

        <Group mt="lg">
          <Button variant="outline" type="submit" onClick={onClose}>
            Close
          </Button>
        </Group>
      </>
    );
  } else {
    return (
      <>
        <Text mb="lg">
          Copy and paste all of your armour judge values and we will try and
          identify the pieces and echants automatically!
        </Text>
        <form onSubmit={form.onSubmit(onSubmit)}>
          <Textarea
            minRows={10}
            label="Judge Values"
            placeholder="Values"
            {...form.getInputProps("judge")}
          />

          {error && (
            <Alert
              mt="lg"
              icon={<ExclamationTriangleIcon />}
              title="Bummer!"
              color="red"
            >
              {error}
            </Alert>
          )}

          <Group mt="lg">
            <Button type="submit">Detect</Button>
            <Button variant="outline" type="submit" onClick={onClose}>
              Close
            </Button>
          </Group>
        </form>
      </>
    );
  }
}

export default JudgeForm;
