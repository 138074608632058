import { useState, useContext, useCallback, useEffect } from "react";
import {
  Title,
  Card,
  Group,
  Modal,
  Center,
  Avatar,
  Button,
  Alert,
  LoadingOverlay,
  Anchor,
} from "@mantine/core";
import { useNotifications } from "@mantine/notifications";

import useAxios from "axios-hooks";
import { ExclamationTriangleIcon } from "@modulz/radix-icons";
import { ReactComponent as LoreLogo } from "../assets/Lore.svg";

import CreateForm from "../components/Lore/Create";
import ResponsiveTable from "../components/Layout/ResponsiveTable";
import ChipsFilter from "../components/Forms/ChipsFilter";
import TH from "../components/Layout/TH";

import UserContext from "../utils/userContext";

import { renderBadge } from "../utils/userTools";
import { loreTypes } from "../utils/loreTools";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";

const loreOptions = loreTypes.map((o) => ({ value: o, label: o }));

const defaultArgs = {
  sort: {
    _id: -1,
  },
  page: 0,
  filters: {},
  items: [],
};

let lastArgs: any = null;

const project = {
  _id: 1,
  name: 1,
  type: 1,
  role: 1,
  creator: 1,
  created: 1
};

function Lore() {
  const userState = useContext(UserContext);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);
  const [editModal, setEditModal] = useState<any>(null);
  const notifications = useNotifications();

  const [items, setItems] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [args, setArgs] = useState<SearchArgs>(defaultArgs);
  const [hasMore, setHasMore] = useState(false);

  const navigate = useNavigate();

  const role = userState.user?.role || 0;

  //
  // Axios

  const [, searchItems] = useAxios(
    {
      url: "/api/public/lore/search",
      method: "POST",
    },
    {
      manual: true,
    }
  );

  const [, postItem] = useAxios(
    {
      url: "/api/guest/lore",
      method: "POST",
    },
    {
      manual: true,
    }
  );

  //
  // Callbacks

  const showEditModal = useCallback(
    (item: any) => {
      if (role === 0) {
        notifications.showNotification({
          title: "Permission Denied",
          message: "You need to be logged in to add edit lore pages.",
        });
      } else {
        setEditModal(item || {});
      }
    },
    [role, notifications]
  );

  const closeEditModal = useCallback(() => {
    setEditModal(null);
  }, []);

  const saveLore = useCallback(
    async (item) => {
      setError(null);
      setSaving(true);
      setEditModal(null);

      try {
        const { data } = await postItem({ data: item });
        navigate(`/lore/${data._id}`);
      } catch (error: any) {
        setError(error.response?.data?.error || error.message);
      }

      setSaving(false);
    },
    [postItem, navigate]
  );

  //
  // Searching

  const doSearchItems = useCallback(async () => {
    try {
      setLoading(true);
      const { data: result } = await searchItems({
        data: {
          filters: args.filters,
          page: args.page,
          pageSize: 20,
          sort: args.sort,
          project,
        },
      });
      setItems(args.page === 0 ? result.items : items.concat(result.items));
      setHasMore(result.hasMore);
      setLoading(false);
    } catch (error: any) {
      setError(error.response?.data?.error || error.message);
    }
  }, [args, items, searchItems]);

  const onFiltersChange = useCallback(
    (value: any) => {
      const newFilters = {
        ...args.filters,
        ...value,
      };
      if (newFilters.type !== undefined && !newFilters.type) {
        delete newFilters.type;
      }
      setArgs({
        ...args,
        page: 0,
        filters: newFilters,
      });
    },
    [args]
  );

  const loadMore = useCallback(() => {
    const newPage = args.page + 1;
    setArgs({
      ...args,
      page: newPage,
    });
  }, [args]);

  const onSortChange = useCallback(
    (value) => {
      setArgs({
        ...args,
        page: 0,
        sort: value,
      });
    },
    [args]
  );

  //
  // Effects

  useEffect(() => {
    lastArgs = null;
  }, []);

  useEffect(() => {
    if (lastArgs !== args) {
      lastArgs = args;
      doSearchItems();
    }
  }, [doSearchItems, args]);

  return (
    <>
      <LoadingOverlay visible={saving} />

      <Group position="apart">
        <Group>
          <Avatar color="blue" size="lg" radius="xl">
            <LoreLogo width={32} height={32} />
          </Avatar>
          <Title order={1}>Library</Title>
        </Group>
        <Group>
          <Button onClick={showEditModal}>Create</Button>
        </Group>
      </Group>

      {error && (
        <Alert
          mt="lg"
          icon={<ExclamationTriangleIcon />}
          title="Bummer!"
          color="red"
        >
          {error}
        </Alert>
      )}

      <Card withBorder mt="lg">
        <ChipsFilter
          onChange={onFiltersChange}
          value={args.filters.type}
          options={loreOptions}
        />
      </Card>

      <Card withBorder mt="lg">
        <ResponsiveTable verticalSpacing="xs" striped>
          <thead>
            <tr>
              <TH onChange={onSortChange} current={args.sort} field="name">
                Name
              </TH>
              <TH
                onChange={onSortChange}
                current={args.sort}
                field="type"
                andThen="name"
              >
                Type
              </TH>
              <th>Created by</th>
              <TH
                onChange={onSortChange}
                current={args.sort}
                field="_id"
              >
                Created
              </TH>
              <th className="min">Permission</th>
            </tr>
          </thead>
          <tbody>
            {!loading && items?.length === 0 && (
              <tr>
                <td colSpan={5}>No items</td>
              </tr>
            )}
            {items?.map((i: any, index: number) => (
              <tr key={i._id}>
                <td>
                  <Anchor component={Link} to={`/lore/${i._id}`}>
                    {i.name || "no name"}
                  </Anchor>
                </td>
                <td>{i.type}</td>
                <td>{i.creator?.playerName}</td>
                <td>{moment(i.created).format("D MMM YYYY")}</td>
                <td>{renderBadge(i.role)}</td>
              </tr>
            ))}
          </tbody>
        </ResponsiveTable>
        <Center mt="xl">
          {hasMore && (
            <Button loading={loading} variant="outline" onClick={loadMore}>
              Load More
            </Button>
          )}
        </Center>
      </Card>
      <Modal
        sx={(theme) => ({
          [`@media (min-width: ${theme.breakpoints.sm}px)`]: {
            ".mantine-Modal-modal": {
              width: "80%",
            },
          },
        })}
        padding="xl"
        opened={!!editModal}
        onClose={closeEditModal}
        title="Create Page"
      >
        {editModal && (
          <CreateForm
            item={editModal}
            onSave={saveLore}
            onClose={closeEditModal}
          />
        )}
      </Modal>
    </>
  );
}

export default Lore;
